@import "../../../node_modules/@anthem/uxd/assets/styles/themes/_baseline.theme.scss";
@import "../../../node_modules/@anthem/uxd/assets/styles/themes/_motif.theme.scss";
@import "../../../node_modules/@anthem/uxd/assets/styles/components/_main-navigation.scss";
@import "../../assets/styles/partials/variables";

@import "../../../node_modules/@anthem/uxd/assets/styles/core/variables";
@import "../../../node_modules/@anthem/uxd/assets/styles/core/mixins";
@import "../../../node_modules/@anthem/uxd/assets/styles/themes/motif/colors";

/* Import Icon Variables */
@import "../../../node_modules/@anthem/uxd/assets/fonts/icons/classic/variables";
@import "../../../node_modules/@anthem/uxd/assets/fonts/icons/motif/variables";

// Findcare redesign styles for overlays
@import "./partials/findcare/overlays.scss";

$container-sm: 750px !default;
$container-md: 970px !default;
$container-lg: 1170px !default;

a.anchor-underline {
  text-decoration: underline !important;
  font-size: 14px;
}

.anchor-icon {
  text-decoration: none;
}

.ant-form-item-error {
  font-size: 14px !important;
}

.ant-form-item-error:before {
  font-size: 18px !important;
}

#backButtonLink {
  padding-top: 10px;
  display: block;
  font-weight: 400;
}

.header-section-name {
  font-size: 1.25rem !important;
  color: #333 !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
}

.pop-over-cnt {
  p {
    font-size: 14px;
  }

  .glossary-term {
    font-weight: normal;
  }

  .popover.ant-tooltip-component {
    border: solid 1px #3a77e4 !important;
  }

  .popover.top {
    margin-left: 0px !important;
  }

  .popover-close,
  .popover-indicator {
    display: none !important;
  }

  .popover-content a {
    font-size: 14px !important;
    font-family: "Lato", sans-serif !important;
  }
}

.dropdown-fix {
  legend {
    margin-bottom: 5px;
  }
}

.sidebar-nav .sidebar-list .sidebar-item {
  background: transparent;
  font-size: 14px;
}

.close:focus {
  opacity: 1;
}

.sidebar-nav .siderail-header {
  font-size: 1.22rem;
}

.popover.top {
  margin-left: 0px;
}

a.ant-action-link span.fa {
  font-size: 25px;
}

.routing-col-tip {
  font-size: 14px;
}

p,
a,
label,
.form-item label,
legend {
  font-size: 14px;
}

.sidebar-item {
  background: transparent;
}

.section-header {
  .header-page-name {
    font-size: 0.89rem;
  }

  .header-section-name {
    font-size: 1.25rem;
  }
}

.tmp-form-overrides {
  .temp-input-style {
    height: 37px;
    border-radius: 0px;
    border: 1px solid #ccc;
  }
}

.modal-header {
  border-bottom: none !important;
}

small,
.small {
  font-size: 80% !important;
}

.ant-font-bold {
  font-weight: bold;
}

.motif-theme {
  div.ant-alert.ant-negative {
    border-left: none !important;
  }

  div.ant-alert {
    border: none;
    border-left: none;
    color: #666;
    padding: 0;
    display: flex;
    align-items: flex;
  }

  div.ant-alert.ant-negative .ant-alert-left,
  div.ant-alert.ant-negative .ant-alert-main {
    background: #c03;
  }

  div.ant-alert.ant-negative {
    border-left: 5px solid #c03 !important;
  }

  .ant-alert {
    .alert-icon {
      color: #ffffff !important;
      font-size: 1.5rem;
    }

    .ant-alert-main {
      padding: 0.85714286rem 10px 0.85714286rem 0.57142857rem;
      background: #f7f7f7 !important;
    }
  }

  .ant-alert.ant-negative .ant-alert-left {
    background-color: #c03 !important;
  }
}
.intent_search_info_alrt {
  background-color: white !important;
  border: 0.2px solid white !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}
.memberFailureAlert {
  div.ant-alert.ant-negative .ant-alert-left {
    background: none !important;
    border-left: none !important;
  }

  .ant-alert .ant-alert-main {
    background: none !important;
  }
}

.psDropdown .psLabel,
.psDropdown .psLabel:last-of-type,
.psDropdown .psLabel:last-of-type {
  padding: 0.75rem 0 0.75rem 0.75rem !important;
}

.x-ant-toggle-ctrl {
  .fa-caret-down {
    float: right;
    padding-left: 10px;
    padding-right: 5px;
    font-size: 22px;
    color: #4c6684;
    bottom: 0.15rem;
    position: relative;
  }
}

@media only screen and (max-width: $breakpoint-xs) {
  .pfToggleSwitch .ptLabel {
    max-width: 12.6rem !important;
  }

  .x-ant-toggle-ctrl {
    .fa-caret-down {
      font-size: 18px;
      bottom: 0rem;
    }
  }
}

@media only screen and (max-width: 370px) {
  .pfToggleSwitch .ptLabel {
    max-width: 9rem !important;
  }
}

.section-header {
  .header-page-name {
    font-size: 0.88889rem;
  }

  .header-section-name {
    font-size: 1.25rem;
  }
}

// temporary reseting of font sizes
//mobile
h1,
.ant-heading-one {
  font-size: 32px;
}

h2,
.ant-heading-two {
  font-size: 26px !important;
}

h3,
.ant-heading-three {
  font-size: 22px;
}

h4,
.ant-heading-four {
  font-size: 22px;
}

h5,
.ant-heading-five {
  font-size: 18px;
}

h6,
.ant-heading-six {
  font-size: 16px;
}

p,
span:not(.fa.fa-remove):not(.inheritFontSize):not(.alert-icon):not(.fa-caret-down),
div,
label {
  font-size: 14px;
}

button:not(.uxd-expansion-button),
.btn {
  font-size: 14px;
}

.btn.btn-primary {
  background: #00609a;

  &:hover {
    background: #31b0d5;
    border-color: #269abc;
  }

  &:focus {
    background: #31b0d5;
    border-color: #269abc;
  }
}

.pfSelect legend {
  font-size: 14px;
  font-weight: 400;
}

#searchWrapper .vgr-btn-float-right {
  float: right;
}

#searchWrapper .vgr-btn-float {
  float: left;
}

@at-root {
  @font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/Lato/Lato-Light.ttf") format("truetype");
  }

  /* lato-300italic - latin */
  @font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 300;
    src: url("../fonts/Lato/Lato-LightItalic.ttf") format("truetype");
  }

  /* lato-regular - latin */
  @font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Lato/Lato-Regular.ttf") format("truetype");
  }

  /* lato-italic - latin */
  @font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 400;
    src: url("../fonts/Lato/Lato-Italic.ttf") format("truetype");
  }

  /* Lato-700 - latin */
  @font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/Lato/Lato-Bold.ttf") format("truetype");
  }

  /* Lato-700italic - latin */
  @font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 700;
    src: url("../fonts/Lato/Lato-BoldItalic.ttf") format("truetype");
  }

  /* Lato-900 - latin */
  @font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/Lato/Lato-Black.ttf") format("truetype");
  }

  /* Lato-900italic - latin */
  @font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 900;
    src: url("../fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
  }

  /* Montserrat-300italic - latin */
  @font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 300;
    src: url("../fonts/Montserrat/Montserrat-LightItalic.ttf") format("truetype");
  }

  /* Montserrat-300 - latin */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
  }

  /* Montserrat-regular - latin */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    // src: url("/assets/fonts/montserrat-v12-latin-regular.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  }

  /* montserrat-italic - latin */
  @font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 400;
    src: url("../fonts/Montserrat/Montserrat-Italic.ttf") format("truetype");
  }

  /* Montserrat-700italic - latin */
  @font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 700;
    src: url("../fonts/Montserrat/Montserrat-BoldItalic.ttf") format("truetype");
  }

  /* Montserrat-700 - latin */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  }

  /* Montserrat-900 - latin */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
  }

  /* Montserrat-900italic - latin */
  @font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 900;
    src: url("../fonts/Montserrat/Montserrat-BlackItalic.ttf") format("truetype");
  }
}

div,
span {
  font-family: "Lato", sans-serif;
}

.modal-body .ant-alert .ant-alert-left {
  min-width: 55px;
  /* for IE using older alert style */
}

.button-link-motif {
  color: #0079c2;
  font-style: normal;
  font-weight: normal;
  background: transparent;
  border: none;
  text-decoration: underline;
  padding-left: none !important;

  &:hover {
    color: darken(#0079c2, 13%);
  }
}

.ant-auto-suggest {
  .psDropdown {
    position: relative;
    height: auto;
  }

  .psArrow {
    color: $ant-gray-blue;
  }
}

.ant-lg-select {
  .ant-auto-suggest {
    width: 100%;
  }
}

.ant-alert.ant-dismissable.on {
  top: 130px;
}

@font-face {
  font-family: "ant-classic-icons";
  src: url("../fonts/icons/classic/ant-classic-icons.eot");
  src:
    url("../fonts/icons/classic/ant-classic-icons.eot") format("embedded-opentype"),
    url("../fonts/icons/classic/ant-classic-icons.ttf") format("truetype"),
    url("../fonts/icons/classic/ant-classic-icons.woff") format("woff"),
    url("../fonts/icons/classic/ant-classic-icons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ant-motif-icons";
  src: url("../fonts/icons/motif/ant-motif-icons.eot");
  src:
    url("../fonts/icons/motif/ant-motif-icons.eot") format("embedded-opentype"),
    url("../fonts/icons/motif/ant-motif-icons.ttf") format("truetype"),
    url("../fonts/icons/motif/ant-motif-icons.woff") format("woff"),
    url("../fonts/icons/motif/ant-motif-icons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src:
    url("../fonts/open-sans/OpenSans-Light.woff") format("woff"),
    url("../fonts/open-sans/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Open Sans";
  src:
    url("../fonts/open-sans/OpenSans-Regular.woff") format("woff"),
    url("../fonts/open-sans/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Open Sans";
  src:
    url("../fonts/open-sans/OpenSans-Semibold.woff") format("woff"),
    url("../fonts/open-sans/OpenSans-Semibold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Open Sans";
  src:
    url("../fonts/open-sans/OpenSans-Bold.woff") format("woff"),
    url("../fonts/open-sans/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

.ant-alert {
  background: none;
  border: 0px;
}

small,
.small {
  font-size: 80%;
}

@media screen and (min-width: 480px) and (max-width: 640px) {
  .modal-content {
    width: auto;
  }
}

@media screen and (max-width: 480px) {
  .modal .modal-body {
    padding-bottom: 5rem;
  }

  .modal-footer {
    right: 0;
  }
}

.fad-page-wrapper {
  .sticky-back-container {
    position: sticky;
    top: 0;
    width: auto;
    z-index: 200;
    background: #fff;
    padding: 0.5rem 0;
  }

  .pt-20 {
    padding: 20px 0 0 0;
  }

  /* hiding dropdown content for base toggle */
  .base-toggle-collapsed {
    overflow: hidden;
  }

  // fix for auto suggest input content appearing behind button
  .ant-text-input.ant-search-input {
    padding-right: 3rem;
  }

  .align-vertical {
    display: inline-block;
    float: left;
  }

  .carousel-content-container ul .color-select-item-container.active {
    cursor: default;
    color: #fff;
  }

  //fixing message center menu red icon
  .badge {
    font-size: 12px;
  }

  div[data-uxd-collapse-item-cmp] .ant-collapse-body,
  div[data-fad-wcs-expand-collapse] .ant-collapse-body {
    overflow: hidden;
  }

  a.ant-arrow-next {
    border-bottom: none;

    &:after {
      @extend %ant-link-pseudo;
      content: $ant-classic-chevron-right-filled;
    }
  }

  .ant-content-callout .ant-callout-content h3 {
    color: #00609a;
  }

  /* dashboard alert */
  .pfSelect.borderless .btn {
    width: auto;
    border-color: transparent;
  }

  .ant-alert {
    width: 100%;
    //weird color overrides in billpay
    border: 0px;
    background: transparent;
  }

  .input-alert {
    .ant-alert {
      max-width: 100%;
    }
  }

  /* claims orange filter button */
  .filter-panel-btn {
    min-width: 200px;
    text-align: left;
  }

  /* fix for alt togglt on claims and benefits */
  .alt-toggle-cmp {
    .alt-toggle-header {
      .alt-toggle-body.closed {
        height: 0;
      }
    }
  }

  /* fix for auto suggest not pushing input content behind icon */
  .search-text-input {
    padding-right: 3rem;
  }

  .panel-group {
    .panel {
      border-radius: 0px;

      &.panel-default {
        border-color: transparent;
      }

      + .panel {
        margin-top: 0px;
      }
    }
  }

  /* search field fix to reduce input so button doesn't overlap - darrel */
  .fad-auto-suggest input {
    padding-right: 50px;
  }

  //fixes font size issue in mobile for certain accordions
  @media only screen and (max-width: $breakpoint-sm) {
    font-size: 0.875rem;
  }

  //fix welcome widget header
  .fad-page-header h1 {
    float: none;
  }

  //fix ant-toggle-ctrl underline issue
  .ant-toggle-ctrl {
    color: transparent;
  }

  //payment and billing update back button
  .btn-icon,
  .btn-icon:focus,
  .btn-icon:hover {
    background: transparent;
    border: none;
    padding: 0px;
  }

  .prLabel.email.active:before,
  .prLabel.mail.active:before,
  .prLabel.text.active:before,
  .prLabel.voice.active:before {
    background: none;
  }

  .prLabel.focus:before {
    box-shadow: none;
  }

  @media screen and (min-width: $breakpoint-sm) {
    .ant-responsive-collapse-heading a {
      display: none;
    }
  }
}

.text-uppercase {
  text-transform: uppercase;
}

/* additional fix when modal content imports page markup using nested .container wrapper - darrel */

.modal-body .container {
  width: auto;
}

html,
body {
  height: 100%;
  -ms-overflow-style: scrollbar;
}

.nav-link-container {
  width: 100%;
  background-color: $ant-motif-white;
}

.fad-page-container {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  background-color: $ant-motif-white;
}

.content-container {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
}

div#ng-view {
  top: 0px !important;
}

#isEmulate {
  position: fixed;
  width: 320px;
  background: #eee;
  border-right: 2px solid #fe5e3c;
  min-height: 600px;
  z-index: 1000;
  left: -320px;
  transition: left 0.5s;
}

#isEmulate.active {
  left: 0px;
}

.close-emulation {
  position: absolute;
  top: 10px;
  right: 10px;
  border-bottom: none;
}

#isEmulate .btn {
  font-size: 14px;
  padding: 5px 15px;
  min-width: auto;
  display: inline-block;
}

#isEmulate .btn + div.btn-wrapper {
  margin-left: 15px;
  display: inline-block;
}

#emulation-inner {
  padding: 15px;
  max-height: 100%;
  overflow-x: auto;
}

.height-alignment {
  height: 50px !important;
}

#emulation-inner h3 {
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  margin: 0px 0px 20px;
  clear: both;
}

#emulation-form {
  text-align: left;
  margin-bottom: 20px;
}

.emulate-actions {
  margin-top: 20px;
}

.vertical-tab {
  transform: rotate(-90deg);
  width: 100%;
  position: absolute;
  top: 170px;
  transform-origin: 100% 0;
}

.vertical-tab h2 {
  background-color: #fe5e3c;
  float: right;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
}

.vertical-tab a {
  padding: 10px;
  display: inline-block;
}

.vertical-tab a,
.vertical-tab .fa {
  color: white;
  border-bottom: none;
}

.vertical-tab .fa {
  margin-right: 5px;
}

#filterSlide {
  position: fixed;
  width: 320px;
  background: #fff;
  border-right: 2px solid #ccc;
  min-height: 600px;
  z-index: 1000;
  left: -320px;
  transition: left 0.5s;
  top: 0;
}

#filterSlide.active {
  left: 0;
  top: 128px;

  .filter-tab {
    top: 115px;

    @media screen and (max-width: $breakpoint-sm) {
      top: 500px;
    }
  }
}

#filterSlide .btn {
  font-size: 14px;
  padding: 5px 15px;
  min-width: auto;
  display: inline-block;
}

#filterSlide .btn + div.btn-wrapper {
  margin-left: 15px;
  display: inline-block;
}

#filter-slide-inner {
  height: calc(100vh - 120px);
  padding: 20px;
  padding-left: 30px;
  max-height: 100%;
  overflow-x: auto;
  //Margin top set for RCP
  // margin-top: 66px;
  // @media screen and (min-width: $breakpoint-md) {
  //   margin-top: 108px;
  // }
  // @media screen and (min-width: $breakpoint-lg) {
  //   margin-top: 126px;
  // }

  fieldset.ant-auto-suggest {
    .psDropdown.active {
      position: absolute;
      top: 44px;
    }
  }
}

.filter-tab {
  transform: rotate(-90deg);
  width: 100%;
  position: absolute;
  top: 240px;

  @media screen and (max-width: $breakpoint-sm) {
    top: 625px;
  }

  transform-origin: 100% 0;
}

.filter-tab h2 {
  background-color: $accent-color-salmon;
  float: right;
  border: 1px solid #ccc;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
}

.filter-tab a {
  padding: 10px;
  display: inline-block;
  font-size: 18px;
}

.filter-tab a,
.filter-tab .fa {
  color: $ant-motif-white !important;
  border-bottom: none;
}

.filter-tab .fa {
  margin-right: 5px;
}

.current-user p,
.current-user .btn {
  margin: 10px 0px;
}

#wcs-navigate,
#wcs-url {
  float: left;
}

.input-with-btn {
  float: left;
  font-size: 13px;
  width: 200px;
}

.input-btn {
  padding: 8px;
  float: left;
  vertical-align: middle;
  line-height: 1.7em;
  margin: 2px 0px 0px 5px;
}

.go-to-url,
.language,
.navigation {
  margin-top: 20px;
}

.navigation ul {
  margin-bottom: 40px;
}

.ant-anthem-alert.ant-dismissable {
  top: -1000px;
  margin-top: 65px;

  a.ant-dismiss-alert {
    display: none;
  }

  &.on {
    a.ant-dismiss-alert {
      display: block;
    }
  }
}

@media (min-width: 1024px) {
  .ant-anthem-alert.ant-dismissable {
    top: -1000px;
    margin-top: 85px;
  }
}

.fad-top-of-page-wrapper {
  .sk-spinner-wave div {
    background-color: $white;
  }
}

.ant-menu-lastfocus {
  display: none;
}

.ant-menu-visible {
  position: fixed;

  .ant-menu-lastfocus {
    display: block;
  }

  .ant-navigation-wrapper {
    padding-bottom: 50px;
  }
}

.modal-open {
  /*removed due to mobile auto scrolling to top
  position: fixed;*/
  overflow: hidden;
}

@media screen and (min-width: $breakpoint-sm) {
  .ant-menu-visible {
    position: relative;

    .ant-navigation-wrapper {
      padding-bottom: 0px;
    }
  }

  .modal-open {
    position: relative;
  }
}

.ant-curve-down,
.ant-curve-up {
  width: 100% !important;
  left: 0 !important;
}

.areyouSureModal {
  .modal-footer {
    .btn-primary {
      margin-bottom: 10px;
    }

    .btn-secondary {
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
}

.task-bar-support-center {
  width: 300px !important;
}

.iframe-mode {
  .fad-page-header {
    margin-top: 0px !important;
  }
}

.fad-page-wrapper {
  .fad-top-of-page-wrapper {
    box-shadow: none;

    &.ant-secure-nav-overrides {
      .ant-menu-button .ant-menu-toggle {
        padding: 0px;
        margin: 0px;
      }

      .ant-main-nav {
        &.menu-options {
          @media screen and (min-width: 801px) {
            margin-left: -10px;
          }

          @media screen and (min-width: 1024px) {
            margin-left: -20px;
          }
        }
      }

      .ant-logo-wrapper {
        padding: 0.8rem 0;
        margin: 0;

        @media screen and (min-width: 480px) {
          padding: 17px 0;
        }

        @media screen and (min-width: 1024px) {
          padding: 22px 0;
        }

        a img {
          padding: 0px;
          margin: 0px;
          max-width: 20rem !important;

          @media screen and (min-width: 801px) {
            padding: 0px;
          }

          @media screen and (min-width: 1024px) {
            padding: 0px;
          }
        }

        .ant-top-nav {
          ul {
            @media screen and (min-width: 801px) {
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;
            }
          }
        }
      }

      .ant-navigation-wrapper {
        .ant-nav-col {
          transition-property: unset;
          transition-timing-function: unset;
          transition-delay: unset;
          transition-duration: unset;

          &:last-child {
            transition-property: unset;
            transition-timing-function: unset;
            transition-delay: unset;
            transition-duration: unset;
          }

          li {
            margin: 7px 0;
            position: relative;
          }
        }
      }
    }
  }

  .page-header-wrapper {
    margin-top: 65px;

    @media screen and (min-width: $breakpoint-sm) {
      margin-top: 62px;
    }

    @media screen and (min-width: $breakpoint-md) {
      margin-top: 85px;
    }
  }

  .page-header-container {
    flex-wrap: wrap;
  }

  a#lnk-info-scroll {
    span.pf-info-icon,
    span.view-info-label {
      color: #ffffff !important;
    }
  }
}

/* secure changes for nav when menu is open */

.ant-menu-visible {
  position: relative;

  @media screen and (min-width: 801px) {
    overflow-y: auto;
  }

  .fad-page-wrapper {
    .fad-top-of-page-wrapper.ant-secure-nav-overrides {
      height: 100%;
      overflow-y: scroll;

      @media screen and (min-width: 801px) {
        overflow-y: hidden;
      }

      [data-fad-top-menu-cmp],
      [data-fad-top-menu2-cmp] {
        padding-left: 0px;
        padding-right: 0px;
      }

      .ant-navigation-wrapper {
        padding: 15px 0 30px;

        .ant-nav-col {
          width: 100%;
          margin: 0px;

          &:last-of-type {
            width: 100%;
          }

          ul.ant-menu-cont {
            margin-top: 0px;
          }

          li {
            display: inline-block;
            margin: 0.15rem 0px;

            a {
              background-color: transparent;
              //display: block;
              display: inline-block;
              font-weight: 300;
              font-size: 14px;
              padding: 5px 15px;
              width: 100%;
              color: #333;

              &:hover {
                background-color: rgba(255, 255, 255, 0.85);
                color: #0079c2;
              }

              span.fa-angle-down {
                z-index: 1;
                color: #333;
              }

              &.sub-head {
                padding-left: 0;
              }
            }
          }

          &.show-column {
            span.ant-heading-two {
              display: none;
            }
          }
        }
      }
    }
  }

  a.ant-task-bar-icon {
    background: transparent;
    border: 0px;
  }

  @media screen and (min-width: 801px) {
    .fad-top-of-page-wrapper.ant-secure-nav-overrides {
      .ant-navigation-wrapper {
        &.align-care-left {
          left: 64px;
        }

        &.align-support-left {
          left: 109px;
        }
      }
    }

    .fad-page-wrapper .fad-top-of-page-wrapper.ant-secure-nav-overrides {
      background: transparent;

      [data-fad-top-menu-cmp] {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .fad-page-wrapper .fad-top-of-page-wrapper.ant-secure-nav-overrides div.ant-navigation-wrapper {
      width: 393px;
      background: #0079c2;
      margin-left: -10px;
    }
  }

  @media screen and (min-width: 1024px) {
    .fad-top-of-page-wrapper.ant-secure-nav-overrides {
      .ant-navigation-wrapper {
        &.align-care-left {
          left: 84px;
        }

        &.align-support-left {
          left: 149px;
        }
      }
    }

    .fad-page-wrapper .fad-top-of-page-wrapper.ant-secure-nav-overrides div.ant-navigation-wrapper {
      margin-left: -20px;
    }
  }
}

.ant-menu-visible .toggle-co-brand.fad-page-wrapper {
  .fad-top-of-page-wrapper.ant-secure-nav-overrides {
    .ant-logo-wrapper-col {
      display: flex;
    }
  }
}

/* secure changes for menu */

.fad-top-of-page-wrapper.ant-secure-nav-overrides {
  .ant-navigation-wrapper {
    ul {
      margin: 0px;
    }

    li.ant-menu-expanded {
      a.ant-heading-two.ant-menu-expand {
        font-weight: 600;
      }
    }

    a {
      color: #333;
      font-weight: 400;
    }
  }

  .dummy-task-bar {
    float: right;
  }

  .data-fad-switch-plans-link,
  .data-fad-profile-link,
  .data-fad-logout-link {
    display: none;
  }

  /* NEW NAV CSS **********************/
  .ant-top-wrapper {
    height: auto;

    .ant-spanish-link {
      position: absolute;
      top: 50%;
      margin: 0px;
    }
  }

  .ant-task-bar-item {
    float: none;
    height: auto;
  }

  @media screen and (min-width: 801px) {
    .ant-top-wrapper .ant-spanish-link,
    .ant-top-nav .ant-spanish-link {
      margin-top: 10px;
      position: relative;
    }
  }

  .ant-menu-cont .badge {
    background-color: #fff;
    color: #0079c2;
    margin-left: 10px;
  }

  .fad-top-of-page-wrapper {
    box-shadow: none;
  }

  .ant-task-bar {
    float: none;
  }

  .ant-tasks,
  .ant-messages {
    display: none;
  }

  a.ant-task-bar-icon {
    margin: 0;
    background: transparent;
    border: 0px;
    display: inline;
    line-height: normal;
    overflow: unset;

    &:hover {
      border: none;
      background: transparent;
    }

    .fa {
      color: #0079c2;
    }
  }

  a.ant-task-bar-icon .fa-user {
    font-size: 23px;
    top: 0px;
  }

  .ant-profile-wrapper {
    margin-top: 10px;
  }

  .ant-navigation-wrapper {
    width: 40%;
  }

  .ant-navigation-wrapper .ant-nav-col {
    display: none;

    @media screen and (min-width: 801px) {
      .ant-task-bar-item {
        height: 23px;
      }
    }
  }

  .ant-navigation-wrapper .ant-nav-col.show-column {
    display: block;
    padding: 0px;
    margin: 0px;

    &.ant-menu-expanded {
      background: #0079c2;
    }

    ul {
      padding-bottom: 10px;
    }
  }

  .ant-main-nav.menu-options {
    margin-left: 0px;
  }

  @media screen and (min-width: 1024px) {
    .ant-main-nav.menu-options {
      margin-left: 0px;
    }

    .ant-logo-wrapper img {
      margin: 0px;
    }
  }

  @media screen and (min-width: 801px) {
    .ant-main-nav.menu-options {
      margin-left: 0px;
    }

    .ant-logo-wrapper img {
      margin: 0px;
    }

    .fad-page-wrapper .fad-top-of-page-wrapper.ant-secure-nav-overrides div.ant-navigation-wrapper {
      margin-left: -20px;
      padding-bottom: 0px;
      padding-top: 0px;

      ul {
        margin-top: 0px;
      }
    }
  }

  .ant-menu-button {
    overflow: none;
  }

  .ant-menu-button {
    // margin-top: 15px;
  }

  .ant-task-bar {
    // display: none;
    margin: 0px;
  }

  .ant-member-secure .masthead {
    margin-top: 109px;
  }

  /* .ant-sub-navigation-container, .ant-pattern-library-sub-nav {
        top: 70px;
      } */
  .ant-task-bar-content {
    text-align: left;
    top: 70px;
    // left: 220px;
  }

  @media screen and (min-width: 480px) {
    .ant-task-bar-content {
      top: 46px;
      right: -53%;
      left: unset;
    }
  }

  @media screen and (min-width: 801px) {
    .ant-task-bar-content {
      top: 46px;
      right: -53%;
      left: unset;
    }

    .ant-task-bar {
      display: block;
    }

    /* .ant-sub-navigation-container, .ant-pattern-library-sub-nav {
          top: 106px;
        } */
  }

  @media screen and (min-width: 1024px) {
    .ant-task-bar-content {
      top: 46px;
      right: -55%;
      left: unset;
    }
  }

  .fad-page-header {
    margin-top: 107px;
  }

  @media screen and (min-width: 1024px) {
    .fad-page-header {
      margin-top: 125px;
    }
  }

  .ant-task-bar-widget {
    display: flex;
    align-items: flex-end;

    .profile-text {
      font-size: 12px;
    }
  }

  .ant-menu-button {
    height: 40px;
    overflow: visible;
  }

  @media screen and (min-width: 480px) {
    .ant-menu-button {
      margin-top: 17px;
      height: 40px;
      overflow: visible;
    }
  }

  .ant-menu-button .ant-menu-toggle .ant-menu-icon {
    background-color: #0079c2;
  }

  .ant-menu-button .ant-menu-toggle .ant-menu-icon:before {
    background-color: #0079c2;
  }

  .ant-menu-button .ant-menu-toggle .ant-menu-icon:after {
    background-color: #0079c2;
  }

  .ant-top-wrapper .ant-top-nav ul,
  .ant-top-nav ul {
    justify-content: flex-end;
    align-items: center;

    li.data-fad-switch-plans-link {
      a {
        color: #0079c2;
      }
    }
  }

  .ant-spanish-link ul.ant-task-bar {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    flex-direction: row;
  }
}

/* gray background manual code */
.fad-page-body.bkg-gray {
  background: #eee;
  margin-top: 0px;
}

.toggle-co-brand {
  .co-brand-logo-wrapper {
    display: flex;
    align-items: center;
  }
}

.toggle-co-brand .fad-middle-of-page .ant-sub-navigation-container {
  top: 52px;

  @media screen and (min-width: $breakpoint-xs) {
    top: 72px;
  }

  @media screen and (min-width: $breakpoint-sm) {
    top: 119px;
  }

  @media screen and (min-width: $breakpoint-md) {
    top: 134px;
  }
}

.toggle-co-brand .fad-top-of-page-wrapper.ant-secure-nav-overrides {
  .ant-logo-wrapper-col {
    display: flex;
    align-items: center;

    .ant-logo-wrapper {
      display: flex;

      a {
        display: inline-block;
        padding-right: 10px;
        border-right: 1px solid #c5c9cc;
      }
    }

    .ant-logo-wrapper.co-brand-image {
      padding: 0 0 0 10px;
    }

    @media screen and (min-width: 480px) {
      .ant-logo-wrapper {
        a {
          padding-right: 15px;
        }
      }

      .ant-logo-wrapper.co-brand-image {
        padding: 0 0 0 15px;
      }
    }
  }

  .ant-task-bar-content {
    top: 102px;
    right: unset;
  }

  @media screen and (min-width: 480px) {
    .ant-task-bar-content {
      top: 46px;

      &::before {
        right: 86%;
      }

      &::after {
        right: 86%;
      }
    }
  }

  @media screen and (min-width: 801px) {
    .ant-task-bar-content {
      right: -53%;

      &::before {
        right: 50px;
      }

      &::after {
        right: 50px;
      }
    }
  }

  .ant-menu-button {
    margin-top: 8px;
  }
}

.toggle-co-brand .ant-member-secure .masthead {
  margin-top: 86px;
}

.toggle-co-brand.fad-page-wrapper .fad-page-header {
  margin-top: 50px;
}

.fad-page-wrapper .ant-anthem-alert.ant-dismissable,
.toggle-co-brand .ant-anthem-alert.ant-dismissable {
  margin-top: 52px;
}

@media screen and (min-width: 480px) {
  .toggle-co-brand.fad-page-wrapper .fad-page-header {
    margin-top: 67px;
  }

  .fad-page-wrapper .ant-anthem-alert.ant-dismissable,
  .toggle-co-brand .ant-anthem-alert.ant-dismissable {
    margin-top: 67px;
  }
}

.fad-top-of-page-wrapper.ant-secure-nav-overrides .ant-top-wrapper .ant-top-nav ul.ant-nav-secondary {
  display: block;
}

/* Cart Icon --------------------------------------------------*/

.fad-top-of-page-wrapper {
  .fa-cart:before {
    content: "\f2fa";
    color: #0079c2;
  }
}

/* End Cart Icon -----------------------------------------------*/

@media screen and (min-width: 801px) {
  .toggle-co-brand.fad-page-wrapper .fad-page-header,
  .fad-page-wrapper .fad-page-header {
    margin-top: 105px;
  }

  .toggle-co-brand .ant-anthem-alert.ant-dismissable {
    margin-top: 118px;
  }

  .fad-page-wrapper .ant-anthem-alert.ant-dismissable {
    margin-top: 113px;
  }
}

@media screen and (min-width: 1024px) {
  .toggle-co-brand.fad-page-wrapper .fad-page-header,
  .fad-page-wrapper .fad-page-header {
    margin-top: 127px;
  }

  .toggle-co-brand .ant-member-secure .masthead {
    margin-top: 126px;
  }

  .fad-page-wrapper .ant-anthem-alert.ant-dismissable {
    margin-top: 132px;
  }

  .toggle-co-brand .ant-anthem-alert.ant-dismissable {
    margin-top: 128px;
  }
}

/** Dismissable Alerts **/

.ant-alert {
  &.ant-dismissable {
    position: fixed;
    left: 0px;
    top: -120px;
    margin: 0px auto;
    width: 100%;
    padding: 0px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    transition: top 0.5s;
    z-index: 99999;
    color: $primary-text-color-inverse;
    background: $light-gray;

    &.on {
      top: 55px;

      @media screen and (min-width: $breakpoint-xs) {
        top: 70px;
      }

      @media screen and (min-width: $breakpoint-sm) {
        top: 110px;
      }

      @media screen and (min-width: $breakpoint-md) {
        top: 130px;
      }
    }

    @media screen and (min-width: 800px) {
      width: 70%;
      left: 15%;
    }

    .media-left {
      background: $primary-color-light-blue;
      text-align: center;
      padding: 0px 12px 0px 13px;
    }

    .fa {
      color: $white;
      font-size: 1.25rem;
      top: 1px;
      position: relative;
    }

    p {
      display: block;
      float: left;
      max-width: 94%;
      margin: 0;
      text-align: left;
      white-space: normal;
    }

    .media-body {
      padding: 15px 10px 15px 20px;
    }

    a.ant-dismiss-alert {
      border-bottom: 0px solid;
      display: block;
      position: absolute;
      top: 15px;
      right: 15px;

      .fa.fa-remove {
        color: $gray-2;
        font-size: 1.25rem;
      }
    }

    &.ant-negative .media-left {
      background-color: $accent-color-magenta;
    }

    &.ant-positive .media-left {
      background-color: $accent-color-green;
    }
  }
}

.toggle-co-brand .ant-sub-navigation-container {
  top: 52px;

  @media screen and (min-width: $breakpoint-xs) {
    top: 72px;
  }

  @media screen and (min-width: $breakpoint-sm) {
    top: 119px;
  }

  @media screen and (min-width: $breakpoint-md) {
    top: 134px;
  }
}

.fad-page-wrapper .ant-sub-navigation-container {
  top: 53px;

  @media screen and (min-width: $breakpoint-xs) {
    top: 67px;
  }

  @media screen and (min-width: $breakpoint-sm) {
    top: 107px;
  }

  @media screen and (min-width: $breakpoint-md) {
    top: 127px;
  }
}

.ant-menu-visible .fad-page-wrapper .fad-top-of-page-wrapper.ant-secure-nav-overrides .ant-navigation-wrapper .ant-nav-col {
  .ant-heading-two {
    font-size: 16px;
    border: 0;
    color: #333;
    padding: 15px 15px;
    margin-bottom: 0px;
    display: inline-block;

    span.fa {
      font-size: 16px;
      color: #333;
      z-index: 1;
    }
  }

  li {
    width: 100%;

    a {
      color: white;
    }
  }

  &.ant-menu-expanded {
    .ant-heading-two {
      color: white;

      span.fa {
        color: white;
      }
    }

    &.show-column ul.ant-menu-cont {
      padding-bottom: 10px;
    }
  }
}

#ant-logout-link-tb .vivr-bb-caption {
  color: #0079c2;
  font-weight: 500;
  font-size: 16px;

  @media screen and (min-width: $breakpoint-sm) {
    font-size: 12px;
    font-weight: 400;
  }
}

#ant-logout-link-tb .fa-sign-out {
  display: none;
}

.fad-top-of-page-wrapper.ant-secure-nav-overrides .ant-navigation-wrapper .data-fad-profile-link,
.fad-top-of-page-wrapper.ant-secure-nav-overrides .ant-navigation-wrapper .data-fad-logout-link {
  display: block;
  text-align: left;
  padding-bottom: 10px;

  a {
    font-weight: 400;
    font-size: 16px;
  }

  @media screen and (min-width: $breakpoint-sm) {
    display: none;
  }
}

.fad-top-of-page-wrapper.ant-secure-nav-overrides .ant-navigation-wrapper .data-fad-profile-link {
  a.ant-heading-two {
    padding: 15px 15px;
    margin: 0px;

    ul {
      padding-bottom: 10px;
    }
  }
}

.data-fad-profile-link .ant-menu-cont {
  max-height: 0px;
  visibility: hidden;
  overflow: hidden;
  opacity: 0.1;
  transition:
    max-height 0.25s ease-out,
    opacity 0.25s ease-out,
    visibility 0s ease-out;
}

.data-fad-profile-link.ant-menu-expanded .ant-menu-cont {
  max-height: 1000px;
  overflow: hidden;
  visibility: visible;
  opacity: 1;
}

.care ul.ant-menu-cont li:first {
  font-weight: 400;
  font-size: 14px;
}

/** Start temp fix for spinning loaders */

.ant-data-load {
  outline: 0 none;
  overflow: hidden;
  position: relative;
  padding: 50px 15px 50px 15px;
  text-align: center;
  background-color: $ant-motif-light-gray;

  .ant-data-spin {
    float: left;
    width: 100%;
    display: block;
  }

  .ant-data-text {
    float: left;
    width: 100%;
    display: block;
  }

  .sk-spinner-wave div {
    background-color: $ant-medium-gray;
  }
}

.sk-spinner-wave.sk-spinner {
  font-size: 10px;
  height: 60px;
  margin: 0 auto;
  text-align: center;
}

.sk-spinner-wave div {
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
  background-color: $primary-color-light-blue;
  display: inline-block;
  height: 100%;
  margin: 0 1px;
  width: 6px;
}

.sk-spinner-wave .sk-rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-spinner-wave .sk-rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-spinner-wave .sk-rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-spinner-wave .sk-rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

// End temp fix for spinner loaders
// Start temp fix for radio button vertical align
.align-vertical {
  .prLabel {
    width: 100%;
  }
}

[data-fad-top-menu2-cmp] {
  padding: 0;
  margin: 0;
  width: 100%;
}

.searchLoader {
  animation: 2s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

[data-uxd-auto-suggest-cmp] .psDropdown.noresults {
  margin-top: 0px;

  input,
  label {
    display: none;
  }
}

.ant-logo-wrapper-col {
  .ant-logo-wrapper.brand-logo {
    //overflow-y: hidden;
  }
}

.careteam-page-container {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  background-color: $ant-motif-white;

  .row-spacing {
    padding-top: 1.2rem;
  }

  .pf-action-links {
    float: right;
    padding-top: 10px;
  }

  .careteam-print-email {
    float: right;
    display: flex;
  }

  .sort-container .sort-dropdown .psButton {
    width: 16em !important;
  }

  .care-add {
    font-weight: bold;
  }

  .care-pencil,
  .care-cancel,
  .care-save {
    padding-left: 5px;
  }

  .btn-sort {
    border: 0;
    background-color: #fff;
    color: #0079c2;
  }

  .btn-icon,
  .btn-icon:hover,
  .btn-icon:focus {
    text-decoration: none;
  }

  .ant-text-input {
    margin: 0px;
    padding: 5px;
    height: 25px;
  }

  .content-header-separator {
    margin-top: 1.5rem;

    .color-dash {
      width: 50px;
      height: 4px;
      background-color: #53b1a3;
    }
  }

  .content-head-container {
    background-color: #fff;
    font-size: 1rem;
    padding-top: 1.5rem;

    .content-head-right {
      display: flex;
      flex-wrap: wrap;
      padding-right: 0;
    }

    a {
      border-bottom: 0px solid #fff;
      text-decoration: none;
    }

    .blue1 {
      color: #286ce2;
    }

    .bold {
      font-weight: bold;
    }

    .icon-size {
      font-size: 1.4rem;
    }

    .icon-box {
      display: inline-block;
      margin-right: 0.5rem;
      position: relative;
      top: 0.3rem;
    }

    .provider-name {
      margin-top: 2rem;
    }

    .util-tray {
      font-size: 1.2rem;
      margin-top: 3.5rem;

      a span {
        color: #000;
      }
    }

    .fa-print,
    .fa-envelope,
    .motif-id-card {
      font-size: 24px;
    }

    .fa-envelope {
      margin-left: 2rem;
    }
  }

  .flex-tile-wrap {
    .fad-flex-tile {
      margin: 0px;

      @media (min-width: 480px) {
        margin: 0 0 0 1.25rem;
      }
    }
  }

  .tile-view-wrapper {
    .tile-name-wrapper,
    .tile-address-wrapper {
      padding: 0.5rem 0;
    }

    .tile-button-wrapper {
      margin-top: 1rem;
      align-items: normal;

      @media screen and (min-width: $breakpoint-md) {
        width: 100%;
      }

      .btn {
        width: 100%;
        align-self: flex-end;

        @media screen and (max-width: $breakpoint-md) {
          width: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .pf-action-links {
      flex-flow: column !important;
      float: left !important;
    }

    .addCT-btn-container .btn-addCT {
      top: 0.5rem !important;
    }
  }
}

// styles for virtual care
.vc-container {
  margin: 3% 0;

  .vc-header-title {
    font-weight: 300;
    font-family: Montserrat, sans-serif;
    font-size: 28px !important;
    color: #333;
  }

  .vc-line {
    width: 3%;
    height: 4px;
    margin: 16px 0px;
    background-color: #7fcdbb;
  }

  .vc-sub-para {
    width: 100%;
    font-family: Lato, sans-serif;
    font-size: 14px;
    color: #333;
  }

  .vc-search-now {
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #286ce2;
  }

  .vc-option {
    margin-top: 5%;

    @media screen and (max-width: $breakpoint-sm) {
      overflow: auto;
      width: 100%;
      display: block;
    }
  }

  .vc-cards {
    margin: 0 27px !important;
    padding: 25px 25px;
    border-radius: 2px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    justify-content: center;
    flex-direction: column;
    min-height: 155px;
    max-width: calc(100% - 14px);
    position: sticky;

    @media screen and (max-width: $breakpoint-sm) {
      margin-top: 20px !important;
      max-width: 100%;
      display: block;
    }

    .vc-content {
      margin-bottom: 17%;
      display: inline-block;
    }
  }

  .vc-card2 {
    box-shadow: 0 6px 12px #00000014;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    position: relative;
    margin-bottom: 24px;
    text-align: left;

    @media screen and (max-width: $breakpoint-sm) {
      display: block;
      position: relative;
      max-width: 100%;
      margin-bottom: 20px;
      flex: none;
    }
  }

  .vc-circle {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  .vc-circle2 {
    display: flex;
    justify-content: left;
    margin: 20px 0 15px 24px;
  }

  .vc-card-title {
    margin: auto;
    font-size: 18px !important;
    color: #333;
  }

  .vc-card-title2 {
    margin: auto;
    font-size: 18px !important;
    color: #333;
    margin-left: 24px;
  }

  .vc-card-desc {
    margin: 12px 5px 30px;
    color: #333;
  }

  .vc-card-desc2 {
    margin: 12px 5px 55px 24px;
    color: #333;
    display: flex;
    text-align: left !important;
    min-height: 100px;
    padding-bottom: 20px;
  }

  .vc-button {
    padding: 15px 10px;
    background-color: #286ce2;
    justify-content: center;
    text-align: center;
    position: absolute;
    bottom: 5%;
    margin-top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;

    a {
      color: #fff;
      text-decoration: none;
      font-weight: 700;
    }
  }

  .vc-button2 {
    padding: 0.8rem 3.4rem;
    background-color: #286ce2;
    justify-content: center;
    text-align: center;
    position: absolute;
    bottom: 5%;
    margin-top: 20px;
    right: 20px;

    a {
      color: #fff;
      text-decoration: none;
      font-weight: 700;
    }
  }

  .vc-button-text {
    color: #fff;
  }

  .vc-button-icon {
    vertical-align: text-bottom;
    padding-left: 5px;
    height: 16px;
  }

  .vc-lato {
    font-family: Lato, sans-serif;
    text-align: center;
    display: block;
    font-size: 14px;
  }
}

//style for khealth popup
.pf-kHealth-modal-container {
  .pf-khealth-header {
    height: 55px;
  }

  .pf-consent-block {
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .modal-header {
    @media screen and (max-width: $breakpoint-xs) {
      .motif-theme .modal.motif-theme .modal-header .close {
        border: none !important;
      }
    }
  }

  a {
    color: #ffffff;
    text-decoration: none;
    font-weight: 500;
  }

  a:hover {
    color: #ffffff;
    text-decoration: none;
    font-weight: 500;
  }

  a:active {
    color: #ffffff;
    text-decoration: none;
    font-weight: 500;
  }

  a.policy-action-link {
    padding-right: 0;
    color: #286ce2 !important;
  }

  p.consent-label {
    line-height: 1.7 !important;
  }
}

//style for recent activity section
.vc-ra-rowcontainer {
  margin: 3% 22% 0 10%;
}

.recentactivity {
  padding-bottom: 70px;
}

.vc-ra-header-title {
  font-weight: 600;
  font-family: sans-serif;
  font-size: 22px !important;
  color: #333;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-bottom: 16px;
}

.vc-ra-row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 1rem -14px 1rem 0;
}

.vc-ra-row-symptom {
  min-height: 70px;
  flex: 1 1 calc(14.28% - 14px);
  margin-left: 24px;
  max-width: calc(20% - 14px);
  min-width: 120px;
  margin-bottom: 14px;
}

.vc-ra-row-description {
  min-height: 70px;
  flex: 1 1 calc(14.28% - 14px);
  text-align: center;
  margin-right: 14px;
  max-width: calc(60% - 14px);
  min-width: 120px;
  margin-bottom: 14px;
  margin-top: 24px;
}

.vc-ra-row-date {
  min-height: 70px;
  flex: 1 1 calc(14.28% - 14px);
  text-align: right;
  margin-right: 24px;
  max-width: calc(20% - 14px);
  min-width: 120px;
  margin-bottom: 14px;
  margin-top: 24px;
}

.ra-symptom {
  color: #333;
  font-family: Lato, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.2rem;
  margin-bottom: 0.1rem;
  margin-top: 24px;
}

.ra-symptom-desc {
  font-family: Lato, sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.2rem;
  margin-bottom: 1rem;
  margin-top: 2px;
}

.vc_ra_deviderow {
  border: solid 1px #e3e1e1;
}

.vc-ra-wrapper {
  background-color: #fff;
  margin-top: -1.09rem;
  border: none 0;
}

.go-to-chat {
  text-decoration: none !important;
}

.vc-ra-title-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  align-items: center;
}

//eoc ra section

@import "../../../node_modules/@anthem/uxd/assets/styles/themes/_baseline.theme.scss";
@import "../../../node_modules/@anthem/uxd/assets/styles/themes/_motif.theme.scss";
@import "../../../node_modules/@anthem/uxd/assets/styles/components/_main-navigation.scss";
@import "src/assets/styles/partials/variables";

a.anchor-underline {
  text-decoration: underline !important;
  font-size: 14px;
}

.anchor-icon {
  text-decoration: none;
}

.ant-form-item-error {
  font-size: 14px !important;
}

.ant-form-item-error:before {
  font-size: 18px !important;
}

#backButtonLink {
  padding-top: 10px;
  display: block;
  font-weight: 400;
}

.header-section-name {
  font-size: 1.25rem !important;
  color: #333 !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
}

.pop-over-cnt {
  p {
    font-size: 14px;
  }

  .glossary-term {
    font-weight: normal;
  }

  .popover.ant-tooltip-component {
    border: solid 1px #3a77e4 !important;
  }

  .popover.top {
    margin-left: 0px !important;
  }

  .popover-close,
  .popover-indicator {
    display: none !important;
  }

  .popover-content a {
    font-size: 14px !important;
    font-family: "Lato", sans-serif !important;
  }
}

.dropdown-fix {
  legend {
    margin-bottom: 5px;
  }
}

.sidebar-nav .sidebar-list .sidebar-item {
  background: transparent;
  font-size: 14px;
}

.close:focus {
  opacity: 1;
}

.sidebar-nav .siderail-header {
  font-size: 1.22rem;
}

.popover.top {
  margin-left: 0px;
}

a.ant-action-link span.fa {
  font-size: 25px;
}

.routing-col-tip {
  font-size: 14px;
}

p,
a,
label,
.form-item label,
legend {
  font-size: 14px;
}

.sidebar-item {
  background: transparent;
}

.section-header {
  .header-page-name {
    font-size: 0.89rem;
  }

  .header-section-name {
    font-size: 1.25rem;
  }
}

.tmp-form-overrides {
  .temp-input-style {
    height: 37px;
    border-radius: 0px;
    border: 1px solid #ccc;
  }
}

.modal-header {
  border-bottom: none !important;
}

small,
.small {
  font-size: 80% !important;
}

.ant-font-bold {
  font-weight: bold;
}

.psDropdown .psLabel,
.psDropdown .psLabel:last-of-type,
.psDropdown .psLabel:last-of-type {
  padding: 0.75rem 0 0.75rem 0.75rem !important;
}

.x-ant-toggle-ctrl {
  .fa-caret-down {
    float: right;
    padding-left: 10px;
    padding-right: 5px;
    font-size: 22px;
    color: #4c6684;
    bottom: 0.15rem;
    position: relative;
  }
}

@media only screen and (max-width: $breakpoint-xs) {
  .pfToggleSwitch .ptLabel {
    max-width: 12.6rem !important;
  }

  .x-ant-toggle-ctrl {
    .fa-caret-down {
      font-size: 18px;
      bottom: 0rem;
    }
  }
}

@media only screen and (max-width: 370px) {
  .pfToggleSwitch .ptLabel {
    max-width: 9rem !important;
  }
}

.section-header {
  .header-page-name {
    font-size: 0.88889rem;
  }

  .header-section-name {
    font-size: 1.25rem;
  }
}

// temporary reseting of font sizes
//mobile
h1,
.ant-heading-one {
  font-size: 32px;
}

h2,
.ant-heading-two {
  font-size: 26px;
}

h3,
.ant-heading-three {
  font-size: 22px;
}

h4,
.ant-heading-four {
  font-size: 22px;
}

h5,
.ant-heading-five {
  font-size: 18px;
}

h6,
.ant-heading-six {
  font-size: 16px;
}

p,
span:not(.fa.fa-remove):not(.inheritFontSize):not(.alert-icon):not(.fa-caret-down),
div,
label {
  font-size: 14px;
}

button:not(.uxd-expansion-button),
.btn {
  font-size: 14px;
}

.pfSelect legend {
  font-size: 14px;
  font-weight: 400;
}

#searchWrapper .vgr-btn-float-right {
  float: right;
}

#searchWrapper .vgr-btn-float {
  float: left;
}

@at-root {
  @font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/Lato/Lato-Light.ttf") format("truetype");
  }

  /* lato-300italic - latin */
  @font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 300;
    src: url("../fonts/Lato/Lato-LightItalic.ttf") format("truetype");
  }

  /* lato-regular - latin */
  @font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Lato/Lato-Regular.ttf") format("truetype");
  }

  /* lato-italic - latin */
  @font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 400;
    src: url("../fonts/Lato/Lato-Italic.ttf") format("truetype");
  }

  /* Lato-700 - latin */
  @font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/Lato/Lato-Bold.ttf") format("truetype");
  }

  /* Lato-700italic - latin */
  @font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 700;
    src: url("../fonts/Lato/Lato-BoldItalic.ttf") format("truetype");
  }

  /* Lato-900 - latin */
  @font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/Lato/Lato-Black.ttf") format("truetype");
  }

  /* Lato-900italic - latin */
  @font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 900;
    src: url("../fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
  }

  /* Montserrat-300italic - latin */
  @font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 300;
    src: url("../fonts/Montserrat/Montserrat-LightItalic.ttf") format("truetype");
  }

  /* Montserrat-300 - latin */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
  }

  /* Montserrat-regular - latin */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    // src: url("/assets/fonts/montserrat-v12-latin-regular.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  }

  /* montserrat-italic - latin */
  @font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 400;
    src: url("../fonts/Montserrat/Montserrat-Italic.ttf") format("truetype");
  }

  /* Montserrat-700italic - latin */
  @font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 700;
    src: url("../fonts/Montserrat/Montserrat-BoldItalic.ttf") format("truetype");
  }

  /* Montserrat-700 - latin */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  }

  /* Montserrat-900 - latin */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
  }

  /* Montserrat-900italic - latin */
  @font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 900;
    src: url("../fonts/Montserrat/Montserrat-BlackItalic.ttf") format("truetype");
  }
}

div,
span {
  font-family: "Lato", sans-serif;
}

.modal-body .ant-alert .ant-alert-left {
  min-width: 55px;
  /* for IE using older alert style */
}

.button-link-motif {
  color: #0079c2;
  font-style: normal;
  font-weight: normal;
  background: transparent;
  border: none;
  text-decoration: underline;
  padding-left: none !important;

  &:hover {
    color: darken(#0079c2, 13%);
  }
}

.ant-auto-suggest {
  .psDropdown {
    position: relative;
    height: auto;
  }

  .psArrow {
    color: $ant-gray-blue;
  }
}

.ant-lg-select {
  .ant-auto-suggest {
    width: 100%;
  }
}

.ant-alert.ant-dismissable.on {
  top: 130px;
}

@font-face {
  font-family: "ant-classic-icons";
  src: url("../fonts/icons/classic/ant-classic-icons.eot");
  src:
    url("../fonts/icons/classic/ant-classic-icons.eot") format("embedded-opentype"),
    url("../fonts/icons/classic/ant-classic-icons.ttf") format("truetype"),
    url("../fonts/icons/classic/ant-classic-icons.woff") format("woff"),
    url("../fonts/icons/classic/ant-classic-icons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ant-motif-icons";
  src: url("../fonts/icons/motif/ant-motif-icons.eot");
  src:
    url("../fonts/icons/motif/ant-motif-icons.eot") format("embedded-opentype"),
    url("../fonts/icons/motif/ant-motif-icons.ttf") format("truetype"),
    url("../fonts/icons/motif/ant-motif-icons.woff") format("woff"),
    url("../fonts/icons/motif/ant-motif-icons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src:
    url("../fonts/open-sans/OpenSans-Light.woff") format("woff"),
    url("../fonts/open-sans/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Open Sans";
  src:
    url("../fonts/open-sans/OpenSans-Regular.woff") format("woff"),
    url("../fonts/open-sans/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Open Sans";
  src:
    url("../fonts/open-sans/OpenSans-Semibold.woff") format("woff"),
    url("../fonts/open-sans/OpenSans-Semibold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Open Sans";
  src:
    url("../fonts/open-sans/OpenSans-Bold.woff") format("woff"),
    url("../fonts/open-sans/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

.ant-alert {
  background: none;
  border: 0px;
}

small,
.small {
  font-size: 80%;
}

@media screen and (min-width: 480px) and (max-width: 640px) {
  .modal-content {
    width: auto;
  }
}

@media screen and (max-width: 480px) {
  .modal .modal-body {
    padding-bottom: 5rem;
  }

  .modal-footer {
    right: 0;
  }
}

.fad-page-wrapper {
  /* hiding dropdown content for base toggle */
  .base-toggle-collapsed {
    overflow: hidden;
  }

  // fix for auto suggest input content appearing behind button
  .ant-text-input.ant-search-input {
    padding-right: 3rem;
  }

  .align-vertical {
    display: inline-block;
    float: left;
  }

  .carousel-content-container ul .color-select-item-container.active {
    cursor: default;
    color: #fff;
  }

  //fixing message center menu red icon
  .badge {
    font-size: 12px;
  }

  div[data-uxd-collapse-item-cmp] .ant-collapse-body,
  div[data-fad-wcs-expand-collapse] .ant-collapse-body {
    overflow: hidden;
  }

  a.ant-arrow-next {
    border-bottom: none;

    &:after {
      @extend %ant-link-pseudo;
      content: $ant-classic-chevron-right-filled;
    }
  }

  .ant-content-callout .ant-callout-content h3 {
    color: #00609a;
  }

  /* dashboard alert */
  .pfSelect.borderless .btn {
    width: auto;
    border-color: transparent;
  }

  .ant-alert {
    width: 100%;
    //weird color overrides in billpay
    border: 0px;
    background: transparent;
  }

  .input-alert {
    .ant-alert {
      max-width: 100%;
    }
  }

  .search-alert {
    margin-left: 10px;
  }

  /* claims orange filter button */
  .filter-panel-btn {
    min-width: 200px;
    text-align: left;
  }

  /* fix for alt togglt on claims and benefits */
  .alt-toggle-cmp {
    .alt-toggle-header {
      .alt-toggle-body.closed {
        height: 0;
      }
    }
  }

  /* fix for auto suggest not pushing input content behind icon */
  .search-text-input {
    padding-right: 3rem;
  }

  .panel-group {
    .panel {
      border-radius: 0px;

      &.panel-default {
        border-color: transparent;
      }

      + .panel {
        margin-top: 0px;
      }
    }
  }

  /* search field fix to reduce input so button doesn't overlap - darrel */
  .fad-auto-suggest input {
    padding-right: 50px;
  }

  //fixes font size issue in mobile for certain accordions
  @media only screen and (max-width: $breakpoint-sm) {
    font-size: 0.875rem;
  }

  //fix welcome widget header
  .fad-page-header h1 {
    float: none;
  }

  //fix ant-toggle-ctrl underline issue
  .ant-toggle-ctrl {
    color: transparent;
  }

  //payment and billing update back button
  .btn-icon,
  .btn-icon:focus,
  .btn-icon:hover {
    background: transparent;
    border: none;
    padding: 0px;
  }

  .prLabel.email.active:before,
  .prLabel.mail.active:before,
  .prLabel.text.active:before,
  .prLabel.voice.active:before {
    background: none;
  }

  .prLabel.focus:before {
    box-shadow: none;
  }

  @media screen and (min-width: $breakpoint-sm) {
    .ant-responsive-collapse-heading a {
      display: none;
    }
  }
}

/* additional fix when modal content imports page markup using nested .container wrapper - darrel */

.modal-body .container {
  width: auto;
}

html,
body {
  height: 100%;
  -ms-overflow-style: scrollbar;
  background-color: $ant-motif-gallery !important; // important needed to over-ride motif-theme
}

html.motif-theme {
  overflow-x: hidden !important;
}

.pad-t20 {
  padding-top: 20px;
}

.nav-link-container {
  width: 100%;
  background-color: $ant-motif-white;
}

.fad-page-container {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  background-color: $ant-motif-white;
}

.content-container {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
}

div#ng-view {
  top: 0px !important;
}

#isEmulate {
  position: fixed;
  width: 320px;
  background: #eee;
  border-right: 2px solid #fe5e3c;
  min-height: 600px;
  z-index: 1000;
  left: -320px;
  transition: left 0.5s;
}

#isEmulate.active {
  left: 0px;
}

.close-emulation {
  position: absolute;
  top: 10px;
  right: 10px;
  border-bottom: none;
}

#isEmulate .btn {
  font-size: 14px;
  padding: 5px 15px;
  min-width: auto;
  display: inline-block;
}

#isEmulate .btn + div.btn-wrapper {
  margin-left: 15px;
  display: inline-block;
}

#emulation-inner {
  padding: 15px;
  max-height: 100%;
  overflow-x: auto;
}

.height-alignment {
  height: 50px !important;
}

#emulation-inner h3 {
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  margin: 0px 0px 20px;
  clear: both;
}

#emulation-form {
  text-align: left;
  margin-bottom: 20px;
}

.emulate-actions {
  margin-top: 20px;
}

.line-gap {
  height: 5.7rem;
}

.vertical-tab {
  transform: rotate(-90deg);
  width: 100%;
  position: absolute;
  top: 170px;
  transform-origin: 100% 0;
}

.vertical-tab h2 {
  background-color: #fe5e3c;
  float: right;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
}

.vertical-tab a {
  padding: 10px;
  display: inline-block;
}

.vertical-tab a,
.vertical-tab .fa {
  color: white;
  border-bottom: none;
}

.vertical-tab .fa {
  margin-right: 5px;
}

#filterSlide {
  position: fixed;
  width: 320px;
  background: #fff;
  border-right: 2px solid #ccc;
  min-height: 600px;
  z-index: 1000;
  left: -320px;
  transition: left 0.5s;
  top: 0;
}

#filterSlide.active {
  left: 0;
  top: 128px;

  .filter-tab {
    top: 115px;

    @media screen and (max-width: $breakpoint-sm) {
      top: 500px;
      display: none;
    }
  }
}

#filterSlide .btn {
  font-size: 14px;
  padding: 5px 15px;
  min-width: auto;
  display: inline-block;
}

#filterSlide .btn + div.btn-wrapper {
  margin-left: 15px;
  display: inline-block;
}

#filter-slide-inner {
  height: calc(100vh - 120px);
  padding: 20px;
  padding-left: 30px;
  max-height: 100%;
  overflow-x: auto;
  //Margin top set for RCP
  // margin-top: 66px;
  // @media screen and (min-width: $breakpoint-md) {
  //   margin-top: 108px;
  // }
  // @media screen and (min-width: $breakpoint-lg) {
  //   margin-top: 126px;
  // }

  fieldset.ant-auto-suggest {
    .psDropdown.active {
      position: absolute;
      top: 44px;
    }
  }
}

.filter-tab {
  transform: rotate(-90deg);
  width: 100%;
  position: absolute;
  top: 240px;

  @media screen and (max-width: $breakpoint-sm) {
    top: 625px;
    display: none;
  }

  transform-origin: 100% 0;
}

.filter-tab h2 {
  background-color: $accent-color-salmon;
  float: right;
  border: 1px solid #ccc;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
}

.filter-tab a {
  padding: 10px;
  display: inline-block;
  font-size: 18px;
}

.filter-tab a,
.filter-tab .fa {
  color: $ant-motif-white !important;
  border-bottom: none;
}

.filter-tab .fa {
  margin-right: 5px;
}

.current-user p,
.current-user .btn {
  margin: 10px 0px;
}

#wcs-navigate,
#wcs-url {
  float: left;
}

.input-with-btn {
  float: left;
  font-size: 13px;
  width: 200px;
}

.input-btn {
  padding: 8px;
  float: left;
  vertical-align: middle;
  line-height: 1.7em;
  margin: 2px 0px 0px 5px;
}

.go-to-url,
.language,
.navigation {
  margin-top: 20px;
}

.navigation ul {
  margin-bottom: 40px;
}

.ant-anthem-alert.ant-dismissable {
  top: -1000px;
  margin-top: 65px;

  a.ant-dismiss-alert {
    display: none;
  }

  &.on {
    a.ant-dismiss-alert {
      display: block;
    }
  }
}

@media (min-width: 1024px) {
  .ant-anthem-alert.ant-dismissable {
    top: -1000px;
    margin-top: 85px;
  }
}

.fad-top-of-page-wrapper {
  .sk-spinner-wave div {
    background-color: $white;
  }
}

.ant-menu-lastfocus {
  display: none;
}

.ant-menu-visible {
  position: fixed;

  .ant-menu-lastfocus {
    display: block;
  }

  .ant-navigation-wrapper {
    padding-bottom: 50px;
  }
}

.modal-open {
  /*removed due to mobile auto scrolling to top
  position: fixed;*/
  overflow: hidden;
}

@media screen and (min-width: $breakpoint-sm) {
  .ant-menu-visible {
    position: relative;

    .ant-navigation-wrapper {
      padding-bottom: 0px;
    }
  }

  .modal-open {
    position: relative;
  }
}

.ant-curve-down,
.ant-curve-up {
  width: 100% !important;
  left: 0 !important;
}

.areyouSureModal {
  .modal-footer {
    .btn-primary {
      margin-bottom: 10px;
    }

    .btn-secondary {
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
}

.task-bar-support-center {
  width: 300px !important;
}

.iframe-mode {
  .fad-page-header {
    margin-top: 0px !important;
  }
}

.fad-page-wrapper {
  .fad-top-of-page-wrapper {
    box-shadow: none;

    &.ant-secure-nav-overrides {
      .ant-menu-button .ant-menu-toggle {
        padding: 0px;
        margin: 0px;
      }

      .ant-main-nav {
        &.menu-options {
          @media screen and (min-width: 801px) {
            margin-left: -10px;
          }

          @media screen and (min-width: 1024px) {
            margin-left: -20px;
          }
        }
      }

      .ant-logo-wrapper {
        padding: 0.8rem 0;
        margin: 0;

        @media screen and (min-width: 480px) {
          padding: 17px 0;
        }

        @media screen and (min-width: 1024px) {
          padding: 22px 0;
        }

        a img {
          padding: 0px;
          margin: 0px;

          @media screen and (min-width: 801px) {
            padding: 0px;
          }

          @media screen and (min-width: 1024px) {
            padding: 0px;
          }
        }

        .ant-top-nav {
          ul {
            @media screen and (min-width: 801px) {
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;
            }
          }
        }
      }

      .ant-navigation-wrapper {
        .ant-nav-col {
          transition-property: unset;
          transition-timing-function: unset;
          transition-delay: unset;
          transition-duration: unset;

          &:last-child {
            transition-property: unset;
            transition-timing-function: unset;
            transition-delay: unset;
            transition-duration: unset;
          }

          li {
            margin: 7px 0;
            position: relative;
          }
        }
      }
    }
  }
}

.page-header-container {
  flex-wrap: wrap;
  height: 92px;
  align-content: center;
  padding: 0;

  .page-head {
    font-size: 2rem;
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .page-header-wrapper .container {
    margin: 0;
    text-align: left;
  }
}

/* secure changes for nav when menu is open */

.ant-menu-visible {
  position: relative;

  @media screen and (min-width: 801px) {
    overflow-y: auto;
  }

  .fad-page-wrapper {
    .fad-top-of-page-wrapper.ant-secure-nav-overrides {
      height: 100%;
      overflow-y: scroll;

      @media screen and (min-width: 801px) {
        overflow-y: hidden;
      }
      [data-fad-top-menu-cmp],
      [data-fad-top-menu2-cmp] {
        padding-left: 0px;
        padding-right: 0px;
      }

      .ant-navigation-wrapper {
        padding: 15px 0 30px;

        .ant-nav-col {
          width: 100%;
          margin: 0px;

          &:last-of-type {
            width: 100%;
          }

          ul.ant-menu-cont {
            margin-top: 0px;
          }

          li {
            display: inline-block;
            margin: 0.15rem 0px;

            a {
              background-color: transparent;
              //display: block;
              display: inline-block;
              font-weight: 300;
              font-size: 14px;
              padding: 5px 15px;
              width: 100%;
              color: #333;

              &:hover {
                background-color: rgba(255, 255, 255, 0.85);
                color: #0079c2;
              }

              span.fa-angle-down {
                z-index: 1;
                color: #333;
              }

              &.sub-head {
                padding-left: 0;
              }
            }
          }

          &.show-column {
            span.ant-heading-two {
              display: none;
            }
          }
        }
      }
    }
  }

  a.ant-task-bar-icon {
    background: transparent;
    border: 0px;
  }

  @media screen and (min-width: 801px) {
    .fad-top-of-page-wrapper.ant-secure-nav-overrides {
      .ant-navigation-wrapper {
        &.align-care-left {
          left: 64px;
        }

        &.align-support-left {
          left: 109px;
        }
      }
    }

    .fad-page-wrapper .fad-top-of-page-wrapper.ant-secure-nav-overrides {
      background: transparent;

      [data-fad-top-menu-cmp] {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .fad-page-wrapper .fad-top-of-page-wrapper.ant-secure-nav-overrides div.ant-navigation-wrapper {
      width: 393px;
      background: #0079c2;
      margin-left: -10px;
    }
  }

  @media screen and (min-width: 1024px) {
    .fad-top-of-page-wrapper.ant-secure-nav-overrides {
      .ant-navigation-wrapper {
        &.align-care-left {
          left: 84px;
        }

        &.align-support-left {
          left: 149px;
        }
      }
    }

    .fad-page-wrapper .fad-top-of-page-wrapper.ant-secure-nav-overrides div.ant-navigation-wrapper {
      margin-left: -20px;
    }
  }
}

.ant-menu-visible .toggle-co-brand.fad-page-wrapper {
  .fad-top-of-page-wrapper.ant-secure-nav-overrides {
    .ant-logo-wrapper-col {
      display: flex;
    }
  }
}

/* secure changes for menu */

.fad-top-of-page-wrapper.ant-secure-nav-overrides {
  .ant-navigation-wrapper {
    ul {
      margin: 0px;
    }

    li.ant-menu-expanded {
      a.ant-heading-two.ant-menu-expand {
        font-weight: 600;
      }
    }

    a {
      color: #333;
      font-weight: 400;
    }
  }

  .dummy-task-bar {
    float: right;
  }

  .data-fad-switch-plans-link,
  .data-fad-profile-link,
  .data-fad-logout-link {
    display: none;
  }

  /* NEW NAV CSS **********************/
  .ant-top-wrapper {
    height: auto;

    .ant-spanish-link {
      position: absolute;
      top: 50%;
      margin: 0px;
    }
  }

  .ant-task-bar-item {
    float: none;
    height: auto;
  }

  @media screen and (min-width: 801px) {
    .ant-top-wrapper .ant-spanish-link,
    .ant-top-nav .ant-spanish-link {
      margin-top: 10px;
      position: relative;
    }
  }

  .ant-menu-cont .badge {
    background-color: #fff;
    color: #0079c2;
    margin-left: 10px;
  }

  .fad-top-of-page-wrapper {
    box-shadow: none;
  }

  .ant-task-bar {
    float: none;
  }

  .ant-tasks,
  .ant-messages {
    display: none;
  }

  a.ant-task-bar-icon {
    margin: 0;
    background: transparent;
    border: 0px;
    display: inline;
    line-height: normal;
    overflow: unset;

    &:hover {
      border: none;
      background: transparent;
    }

    .fa {
      color: #0079c2;
    }
  }

  a.ant-task-bar-icon .fa-user {
    font-size: 23px;
    top: 0px;
  }

  .ant-profile-wrapper {
    margin-top: 10px;
  }

  .ant-navigation-wrapper {
    width: 40%;
  }

  .ant-navigation-wrapper .ant-nav-col {
    display: none;

    @media screen and (min-width: 801px) {
      .ant-task-bar-item {
        height: 23px;
      }
    }
  }

  .ant-navigation-wrapper .ant-nav-col.show-column {
    display: block;
    padding: 0px;
    margin: 0px;

    &.ant-menu-expanded {
      background: #0079c2;
    }

    ul {
      padding-bottom: 10px;
    }
  }

  .ant-main-nav.menu-options {
    margin-left: 0px;
  }

  @media screen and (min-width: 1024px) {
    .ant-main-nav.menu-options {
      margin-left: 0px;
    }

    .ant-logo-wrapper img {
      margin: 0px;
    }
  }

  @media screen and (min-width: 801px) {
    .ant-main-nav.menu-options {
      margin-left: 0px;
    }

    .ant-logo-wrapper img {
      margin: 0px;
    }

    .fad-page-wrapper .fad-top-of-page-wrapper.ant-secure-nav-overrides div.ant-navigation-wrapper {
      margin-left: -20px;
      padding-bottom: 0px;
      padding-top: 0px;

      ul {
        margin-top: 0px;
      }
    }
  }

  .ant-menu-button {
    overflow: none;
  }

  .ant-task-bar {
    // display: none;
    margin: 0px;
  }

  .ant-member-secure .masthead {
    margin-top: 109px;
  }

  /* .ant-sub-navigation-container, .ant-pattern-library-sub-nav {
        top: 70px;
      } */
  .ant-task-bar-content {
    text-align: left;
    top: 70px;
    // left: 220px;
  }

  @media screen and (min-width: 480px) {
    .ant-task-bar-content {
      top: 46px;
      right: -53%;
      left: unset;
    }
  }

  @media screen and (min-width: 801px) {
    .ant-task-bar-content {
      top: 46px;
      right: -53%;
      left: unset;
    }

    .ant-task-bar {
      display: block;
    }

    /* .ant-sub-navigation-container, .ant-pattern-library-sub-nav {
          top: 106px;
        } */
  }

  @media screen and (min-width: 1024px) {
    .ant-task-bar-content {
      top: 46px;
      right: -55%;
      left: unset;
    }
  }

  .fad-page-header {
    margin-top: 107px;
  }

  @media screen and (min-width: 1024px) {
    .fad-page-header {
      margin-top: 125px;
    }
  }

  .ant-task-bar-widget {
    display: flex;
    align-items: flex-end;

    .profile-text {
      font-size: 12px;
    }
  }

  .ant-menu-button {
    height: 40px;
    overflow: visible;
  }

  @media screen and (min-width: 480px) {
    .ant-menu-button {
      margin-top: 17px;
      height: 40px;
      overflow: visible;
    }
  }

  .ant-menu-button .ant-menu-toggle .ant-menu-icon {
    background-color: #0079c2;
  }

  .ant-menu-button .ant-menu-toggle .ant-menu-icon:before {
    background-color: #0079c2;
  }

  .ant-menu-button .ant-menu-toggle .ant-menu-icon:after {
    background-color: #0079c2;
  }

  .ant-top-wrapper .ant-top-nav ul,
  .ant-top-nav ul {
    justify-content: flex-end;
    align-items: center;

    li.data-fad-switch-plans-link {
      a {
        color: #0079c2;
      }
    }
  }

  .ant-spanish-link ul.ant-task-bar {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    flex-direction: row;
  }
}

/* gray background manual code */
.fad-page-body.bkg-gray {
  background: #eee;
  margin-top: 0px;
}

.toggle-co-brand {
  .co-brand-logo-wrapper {
    display: flex;
    align-items: center;
  }
}

.toggle-co-brand .fad-middle-of-page .ant-sub-navigation-container {
  top: 52px;

  @media screen and (min-width: $breakpoint-xs) {
    top: 72px;
  }

  @media screen and (min-width: $breakpoint-sm) {
    top: 119px;
  }

  @media screen and (min-width: $breakpoint-md) {
    top: 134px;
  }
}

.toggle-co-brand .fad-top-of-page-wrapper.ant-secure-nav-overrides {
  .ant-logo-wrapper-col {
    display: flex;
    align-items: center;

    .ant-logo-wrapper {
      display: flex;

      a {
        display: inline-block;
        padding-right: 10px;
        border-right: 1px solid #c5c9cc;
      }
    }

    .ant-logo-wrapper.co-brand-image {
      padding: 0 0 0 10px;
    }

    @media screen and (min-width: 480px) {
      .ant-logo-wrapper {
        a {
          padding-right: 15px;
        }
      }

      .ant-logo-wrapper.co-brand-image {
        padding: 0 0 0 15px;
      }
    }
  }

  .ant-task-bar-content {
    top: 102px;
    right: unset;
  }

  @media screen and (min-width: 480px) {
    .ant-task-bar-content {
      top: 46px;

      &::before {
        right: 86%;
      }

      &::after {
        right: 86%;
      }
    }
  }

  @media screen and (min-width: 801px) {
    .ant-task-bar-content {
      right: -53%;

      &::before {
        right: 50px;
      }

      &::after {
        right: 50px;
      }
    }
  }

  .ant-menu-button {
    margin-top: 8px;
  }
}

.toggle-co-brand .ant-member-secure .masthead {
  margin-top: 86px;
}

.toggle-co-brand.fad-page-wrapper .fad-page-header {
  margin-top: 50px;
}

.fad-page-wrapper .ant-anthem-alert.ant-dismissable,
.toggle-co-brand .ant-anthem-alert.ant-dismissable {
  margin-top: 52px;
}

@media screen and (min-width: 480px) {
  .toggle-co-brand.fad-page-wrapper .fad-page-header {
    margin-top: 67px;
  }

  .fad-page-wrapper .ant-anthem-alert.ant-dismissable,
  .toggle-co-brand .ant-anthem-alert.ant-dismissable {
    margin-top: 67px;
  }
}

.fad-top-of-page-wrapper.ant-secure-nav-overrides .ant-top-wrapper .ant-top-nav ul.ant-nav-secondary {
  display: block;
}

/* Cart Icon --------------------------------------------------*/

.fad-top-of-page-wrapper {
  .fa-cart:before {
    content: "\f2fa";
    color: #0079c2;
  }
}

/* End Cart Icon -----------------------------------------------*/

@media screen and (min-width: 801px) {
  .toggle-co-brand.fad-page-wrapper .fad-page-header,
  .fad-page-wrapper .fad-page-header {
    margin-top: 105px;
  }

  .toggle-co-brand .ant-anthem-alert.ant-dismissable {
    margin-top: 118px;
  }

  .fad-page-wrapper .ant-anthem-alert.ant-dismissable {
    margin-top: 113px;
  }
}

@media screen and (min-width: 1024px) {
  .toggle-co-brand.fad-page-wrapper .fad-page-header,
  .fad-page-wrapper .fad-page-header {
    margin-top: 127px;
  }

  .toggle-co-brand .ant-member-secure .masthead {
    margin-top: 126px;
  }

  .fad-page-wrapper .ant-anthem-alert.ant-dismissable {
    margin-top: 132px;
  }

  .toggle-co-brand .ant-anthem-alert.ant-dismissable {
    margin-top: 128px;
  }
}

/** Dismissable Alerts **/

.ant-alert {
  &.ant-dismissable {
    position: fixed;
    left: 0px;
    top: -120px;
    margin: 0px auto;
    width: 100%;
    padding: 0px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    transition: top 0.5s;
    z-index: 99999;
    color: $primary-text-color-inverse;
    background: $light-gray;

    &.on {
      top: 55px;

      @media screen and (min-width: $breakpoint-xs) {
        top: 70px;
      }

      @media screen and (min-width: $breakpoint-sm) {
        top: 110px;
      }

      @media screen and (min-width: $breakpoint-md) {
        top: 130px;
      }
    }

    @media screen and (min-width: 800px) {
      width: 70%;
      left: 15%;
    }

    .media-left {
      background: $primary-color-light-blue;
      text-align: center;
      padding: 0px 12px 0px 13px;
    }

    .fa {
      color: $white;
      font-size: 1.25rem;
      top: 1px;
      position: relative;
    }

    p {
      display: block;
      float: left;
      max-width: 94%;
      margin: 0;
      text-align: left;
      white-space: normal;
    }

    .media-body {
      padding: 15px 10px 15px 20px;
    }

    a.ant-dismiss-alert {
      border-bottom: 0px solid;
      display: block;
      position: absolute;
      top: 15px;
      right: 15px;

      .fa.fa-remove {
        color: $gray-2;
        font-size: 1.25rem;
      }
    }

    &.ant-negative .media-left {
      background-color: $accent-color-magenta;
    }

    &.ant-positive .media-left {
      background-color: $accent-color-green;
    }
  }
}

.toggle-co-brand .ant-sub-navigation-container {
  top: 52px;

  @media screen and (min-width: $breakpoint-xs) {
    top: 72px;
  }

  @media screen and (min-width: $breakpoint-sm) {
    top: 119px;
  }

  @media screen and (min-width: $breakpoint-md) {
    top: 134px;
  }
}

.fad-page-wrapper .ant-sub-navigation-container {
  top: 53px;

  @media screen and (min-width: $breakpoint-xs) {
    top: 67px;
  }

  @media screen and (min-width: $breakpoint-sm) {
    top: 107px;
  }

  @media screen and (min-width: $breakpoint-md) {
    top: 127px;
  }
}

.ant-menu-visible .fad-page-wrapper .fad-top-of-page-wrapper.ant-secure-nav-overrides .ant-navigation-wrapper .ant-nav-col {
  .ant-heading-two {
    font-size: 16px;
    border: 0;
    color: #333;
    padding: 15px 15px;
    margin-bottom: 0px;
    display: inline-block;

    span.fa {
      font-size: 16px;
      color: #333;
      z-index: 1;
    }
  }

  li {
    width: 100%;

    a {
      color: white;
    }
  }

  &.ant-menu-expanded {
    .ant-heading-two {
      color: white;

      span.fa {
        color: white;
      }
    }

    &.show-column ul.ant-menu-cont {
      padding-bottom: 10px;
    }
  }
}

#ant-logout-link-tb .vivr-bb-caption {
  color: #0079c2;
  font-weight: 500;
  font-size: 16px;

  @media screen and (min-width: $breakpoint-sm) {
    font-size: 12px;
    font-weight: 400;
  }
}

#ant-logout-link-tb .fa-sign-out {
  display: none;
}

.fad-top-of-page-wrapper.ant-secure-nav-overrides .ant-navigation-wrapper .data-fad-profile-link,
.fad-top-of-page-wrapper.ant-secure-nav-overrides .ant-navigation-wrapper .data-fad-logout-link {
  display: block;
  text-align: left;
  padding-bottom: 10px;

  a {
    font-weight: 400;
    font-size: 16px;
  }

  @media screen and (min-width: $breakpoint-sm) {
    display: none;
  }
}

.fad-top-of-page-wrapper.ant-secure-nav-overrides .ant-navigation-wrapper .data-fad-profile-link {
  a.ant-heading-two {
    padding: 15px 15px;
    margin: 0px;

    ul {
      padding-bottom: 10px;
    }
  }
}

.data-fad-profile-link .ant-menu-cont {
  max-height: 0px;
  visibility: hidden;
  overflow: hidden;
  opacity: 0.1;
  transition:
    max-height 0.25s ease-out,
    opacity 0.25s ease-out,
    visibility 0s ease-out;
}

.data-fad-profile-link.ant-menu-expanded .ant-menu-cont {
  max-height: 1000px;
  overflow: hidden;
  visibility: visible;
  opacity: 1;
}

.care ul.ant-menu-cont li:first {
  font-weight: 400;
  font-size: 14px;
}

/** Start temp fix for spinning loaders */

.ant-data-load {
  outline: 0 none;
  overflow: hidden;
  position: relative;
  padding: 50px 15px 50px 15px;
  text-align: center;
  background-color: $ant-motif-light-gray;

  .ant-data-spin {
    float: left;
    width: 100%;
    display: block;
  }

  .ant-data-text {
    float: left;
    width: 100%;
    display: block;
  }

  .sk-spinner-wave div {
    background-color: $ant-medium-gray;
  }
}

.sk-spinner-wave.sk-spinner {
  font-size: 10px;
  height: 60px;
  margin: 0 auto;
  text-align: center;
}

.sk-spinner-wave div {
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
  background-color: $primary-color-light-blue;
  display: inline-block;
  height: 100%;
  margin: 0 1px;
  width: 6px;
}

.sk-spinner-wave .sk-rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-spinner-wave .sk-rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-spinner-wave .sk-rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-spinner-wave .sk-rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

// End temp fix for spinner loaders
// Start temp fix for radio button vertical align
.align-vertical {
  .prLabel {
    width: 100%;
  }
}

[data-fad-top-menu2-cmp] {
  padding: 0;
  margin: 0;
  width: 100%;
}

.searchLoader {
  animation: 2s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

[data-uxd-auto-suggest-cmp] .psDropdown.noresults {
  margin-top: 0px;

  input,
  label {
    display: none;
  }
}

.pf-removecare-modal-container {
  .modal-content .modal-footer {
    display: block !important;
  }
}

.careteam-page-container {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  background-color: $ant-motif-white;

  .anchor-icon {
    cursor: pointer;
  }

  .tile-view-wrapper .floater-tag {
    @media screen and (max-width: 1024px) {
      right: 0%;
    }

    @media screen and (max-width: 1200px) {
      right: 0%;
    }
  }

  .row-spacing {
    padding-top: 1.2rem;
  }

  .pf-action-links {
    float: right;
    display: flex;

    @media screen and (max-width: $breakpoint-sm) {
      flex-flow: row;
    }
  }

  .sort-container .sort-dropdown .psButton {
    width: 16em !important;
  }

  .care-add {
    font-weight: bold;
  }

  .care-pencil,
  .care-cancel,
  .care-save {
    padding-left: 5px;
  }

  .btn-sort {
    border: 0;
    background-color: #fff;
    color: #0079c2;
  }

  .btn-icon,
  .btn-icon:hover,
  .btn-icon:focus {
    text-decoration: none;
  }

  .ant-text-input {
    margin: 0px;
    padding: 5px;
    height: 25px;
  }

  .content-header-separator {
    margin-top: 1.5rem;

    .color-dash {
      width: 50px;
      height: 4px;
      background-color: #53b1a3;
    }
  }

  .content-head-container {
    background-color: #fff;
    font-size: 1rem;
    padding-top: 1.5rem;

    .content-head-right {
      display: flex;
      flex-wrap: wrap;
      padding-right: 0;
    }

    a {
      border-bottom: 0px solid #fff;
      text-decoration: none;
    }

    .blue1 {
      color: #286ce2;
    }

    .bold {
      font-weight: bold;
    }

    .icon-size {
      font-size: 1.4rem;
    }

    .icon-box {
      display: inline-block;
      margin-right: 0.5rem;
      position: relative;
      top: 0.3rem;
    }

    .provider-name {
      margin-top: 2rem;
    }

    .util-tray {
      font-size: 1.2rem;
      margin-top: 3.5rem;

      a span {
        color: #000;
      }
    }

    .fa-print,
    .fa-envelope,
    .motif-id-card {
      font-size: 24px;
    }

    .fa-envelope {
      margin-left: 2rem;
    }
  }

  .flex-tile-wrap {
    .fad-flex-tile {
      margin: 0px;

      @media (min-width: 480px) {
        margin: 0 0 0 1.25rem;
      }
    }
  }

  .tile-view-wrapper {
    .tile-name-wrapper,
    .tile-address-wrapper {
      padding: 0.5rem 0;
    }

    .tile-button-wrapper {
      margin-top: 1rem;
      align-items: normal;

      @media screen and (min-width: $breakpoint-md) {
        width: 100%;
      }

      .btn {
        width: 100%;
        align-self: flex-end;

        @media screen and (max-width: $breakpoint-md) {
          width: 100%;
        }
      }
    }
  }
}

.lho-float-left {
  float: left;
  width: auto;
}

// < new

.modal-container.slide-in.right {
  .close {
    top: 1rem;
  }

  .close-icon {
    padding: 1rem;
    font-size: 2rem;
  }
}

.rtModalContent {
  h2 {
    padding-bottom: 4rem;
  }

  h3 {
    font-weight: normal;
  }

  .slide-modal-title {
    padding-bottom: 4rem;
  }

  h6 {
    font-weight: bold;
    font-size: 1rem;
    margin: 0;
  }

  .detail-group {
    padding-left: 0;
    margin-bottom: 3rem;

    .item-label {
      font-weight: 600;
      font-size: 1rem;
      margin: 0;
    }

    .detail-right {
      padding-right: 0;
    }

    .detail-left {
      padding-left: 0;
    }

    .price {
      font-size: 1.25rem;
    }

    .description {
      border-top: 1px #000 solid;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .pcp-remove {
    padding-bottom: 1.5rem;
  }

  .rtModalContentTop {
    margin-left: 0;
    margin-right: 0;

    input {
      margin-right: 0.7rem;
    }

    .pcp-info {
      padding-left: 1.6rem;
      padding-bottom: 0.9rem;
    }
  }

  .slidePcpIdSection {
    margin-top: 2rem;

    .pcpdesc {
      padding-top: 0.8rem;
    }
  }

  .selectDropList {
    margin-top: 2rem;

    button {
      height: 2.3rem;
      width: 100% !important;
    }

    .ant-lg-select {
      width: 100% !important;
    }
  }

  .button-group {
    float: right;
    padding-top: 1rem;

    @media screen and (max-width: 768px) {
      float: none;

      .btn,
      .btn-secondary {
        margin-left: 0rem !important;
        margin-right: 0rem !important;
        display: block;
        width: 100%;
      }
    }
  }

  .clear {
    clear: both;
  }
}

.floater-tag {
  color: white;
  background: #268476;
  border-radius: 2px;
  display: inline-block;
  font-size: 10px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  padding: 5px;
  line-height: 12px;
  width: fit-content;

  @media screen and (max-width: 1024px) {
    right: 10%;
  }

  @media screen and (max-width: 1200px) {
    right: 10%;
  }
}

.addCT-btn-container {
  .btn-addCT {
    position: relative;
    top: -0.8rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: 0.5rem;

    .motif-icon.motif-plus {
      display: inline-block;
      margin-right: 0.5rem;
      position: relative;
      top: 0.25rem;
      font-size: 1.5rem;
    }
  }

  @media only screen and (max-width: 800px) {
    .btn {
      padding: 6px 12px;
    }

    .btn-addCT {
      top: -0.5rem;
    }
  }
}

.care-team-item .floater-tag {
  display: block;
  float: right;
}

.tile-view-wrapper .floater-tag {
  display: block;
  float: right;
}

.buttonContainer {
  margin-top: 2rem;

  .pcpBtn {
    width: 100%;
    padding: 0.3rem;
    margin-bottom: 1rem;
    border: none;
  }
}

.remove-border {
  border: none;
}

.margin-top10 {
  margin-top: 10px;
}

// new >

// < price detail modal adjustment RCP/Stadalone consistecy

.pricetab.rtModalContent {
  h2 {
    padding-bottom: 4rem;
  }

  h3 {
    font-weight: normal;
  }

  .slide-modal-title {
    padding-bottom: 4rem;
  }

  h6 {
    font-weight: bold;
    font-size: 1rem;
    margin: 0;
  }

  .detail-group {
    padding-left: 0;
    margin-bottom: 3rem;

    .item-label {
      font-weight: 600;
      font-size: 1rem;
      margin: 0;
    }

    .detail-right {
      padding-right: 0;
    }

    .detail-left {
      padding-left: 0;
    }

    .price {
      font-size: 1.25rem;
    }

    .description {
      border-top: 1px #000 solid;
      padding-right: 0;
      padding-left: 0;
    }
  }

  // .pcp-remove {
  //   padding-bottom: 1.5rem;
  // }
  .rtModalContentTop {
    margin-left: 0;
    margin-right: 0;

    input {
      margin-right: 0.7rem;
    }

    .pcp-info {
      padding-left: 1.6rem;
      padding-bottom: 0.9rem;
    }
  }

  // .slidePcpIdSection {
  //   margin-top: 2rem;
  //   .pcpdesc{
  //     padding-top: .8rem;
  //   }
  // }
  // .selectDropList {
  //   margin-top: 2rem;
  //   button {
  //     height: 2.3rem;
  //     width: 100% !important;
  //   }
  //   .ant-lg-select {
  //     width: 100% !important;
  //   }
  // }
  .button-group {
    float: right;
    padding-top: 1rem;

    @media screen and (max-width: 768px) {
      float: none;

      .btn,
      .btn-secondary {
        margin-left: 0rem !important;
        margin-right: 0rem !important;
        display: block;
        width: 100%;
      }
    }
  }

  .fad-action-buttons {
    display: block;
  }

  .clear {
    clear: both;
  }
}

.justify-content-start {
  justify-content: flex-start !important;
}

// price detail modal adjustment RCP/Standalone consistency
@media only screen and (max-width: 600px) {
  .fixed-tray-wrap-btn {
    padding-top: 20px;

    .fixed-tray {
      .tray-button-row {
        flex-flow: wrap;
        justify-content: flex-start;
        padding: 0.1rem 0;

        .btn {
          margin-top: 0.5rem;
        }

        .img {
          margin: 0 0 5px 5px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .pf-side-panel .fad-action-buttons {
    display: block;
  }

  .pricing-location-wrapper {
    span {
      top: -10px !important;
    }
  }
}

.pricing-print-only {
  display: none;
}

.green-medical-BH-close-button {
  display: none;
}

@media print {
  .fad-page-container {
    border-bottom: none !important;
  }

  .pricing-print-only {
    display: block;
  }

  div.masthead.ant-bkg-blue {
    // consumerpublic
    display: none;
  }

  div.state-header {
    // consumerpublic
    margin-top: 0px;
  }

  .pf-location-phone {
    a[href]:after {
      visibility: hidden;
    }
  }
}

.assign-care-side-panel {
  .rtModalContent {
    h2 {
      padding-bottom: 2rem;
    }
  }
}

.pf-btn-disabled {
  padding: 0.85714286rem 3.42857143rem;
  border: 1px solid transparent;
  border-color: #dcdcdc;
  margin-bottom: 1rem;
}

.faux-disabled {
  opacity: 0.3;
}

.motif-theme .modal-footer {
  border-top: none !important; // Motif over-ride
}

.fad-page-wrapper {
  .page-header-wrapper {
    background-image: url("/assets/images/common/page-header-background.svg"), linear-gradient(347deg, #58abdf, #0079c2);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
  }
}

// fix to over-ride the baseline theme over-riding motif theme
.ant-alert .ant-alert-left span.alert-icon.alert-icon-negative::before {
  content: $motif-cancel;
}

// drop down fix on guest login questions
.fad-plan-selection fieldset.ant-auto-suggest .ant-search-button span.fa {
  margin-top: 5px;
}

.flex-col {
  display: flex;
  flex-flow: column;
}

.flex-row {
  display: flex;
  flex-flow: row;
}

// fix for the fact that our public servers render the HTML for this alert component incorrectly
#txt-results-error {
  .ant-alert:not(.ant-negative) {
    .ant-alert-main {
      padding: 0.86rem 10px 0.86rem 0.57rem !important;
    }

    .ant-alert-left {
      background: #cc0033;
      padding: 0.5rem !important;

      .alert-icon {
        padding: 1.4rem !important;

        &:before {
          color: white;
          content: $motif-cancel;
        }
      }
    }
  }
}

// fix for recognitions filter labels on public
.fad-page-wrapper {
  background: #fff;

  .pill-list-wrapper .searchFilterItem {
    label.pcLabel {
      font-size: 14px;
    }
  }
}

// prevent provider names from smashing into second column on narrow screens
.pf-search-results-section .provider-name-link {
  width: 90%;
}

// aesthetic tweak to modal close focus.
// (add additional selectors replacing `pf-map-modal-container` for each modal needing this fix)
.motif-theme [data-uxd-modal-cmp].pf-map-modal-container .modal-content .close .fa.fa-remove {
  margin-right: 0px;
}

.motif-theme .pf-map-modal-container.modal .modal-header .close {
  right: -27px;
}

[data-uxd-modal-cmp] .modal-content .modal-footer {
  display: block !important;
}

@media (max-width: $breakpoint-sm) {
  .flyout-spec-panel {
    .pf-side-panel {
      .modal-component-body {
        padding: 5rem 1rem 5rem 2rem !important;
      }
    }
  }

  .modal-container.slide-in.right .close {
    position: absolute !important;
  }

  .overlay-container .modal-container.slide-in.right .close .close-icon {
    top: 0rem !important;
    left: 0rem !important;
    padding-top: 1rem !important;
  }

  .overlay-container .modal-container.slide-in.right .close .close-icon:before {
    font-size: 2rem !important;
  }
}

.overlay-container .modal-container.slide-in.right .modal-panel {
  max-width: 500px;
  --filter-panel-width: auto;
  @media (min-width: $breakpoint-xs) {
    width: 80% !important;
    --filter-panel-width: 80%;
  }
  @media (min-width: $breakpoint-sm) {
    width: 60% !important;
    --filter-panel-width: 60%;
  }
  @media (min-width: $breakpoint-md) {
    width: 50% !important;
    --filter-panel-width: 50%;
    max-width: none;
  }
  @media (max-width: $breakpoint-xs) {
    width: 100% !important;
    --filter-panel-width: 100%;
    max-width: none;
  }
  .filter-side-panel-buttons {
    width: var(--filter-panel-width);
  }
}
.pf-side-panel {
  overflow-y: auto !important;
  height: 100% !important;

  .close-btn-ht {
    height: 60px;

    .fcr-close-icon {
      font-size: 26px !important;
      color: #286ce2;

      @media screen and (max-width: $breakpoint-sm) {
        font-size: 22px !important;
      }
    }
  }

  .modal-component-body {
    padding: 5rem 1rem 5rem 5.5rem !important;

    @media screen and (min-width: 801px) {
      padding: 1rem 5rem 5rem 5rem !important;
    }
  }

  .price-detail-modal.modal-component-body.cost-share {
    overflow: auto;
  }

  .sidepanel-plan-search {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    flex-basis: 80%;
    width: 80%;
    align-self: stretch;

    &.margin-adjust {
      margin-bottom: 0;
      margin-top: 20px;
    }

    .plan-search-inputs-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .plan-search-input-wrapper {
        p {
          margin: 0;
          margin-top: 0.5rem;
        }

        legend {
          margin-bottom: 0.5rem;
        }

        > * {
          margin-top: 30px;
        }

        .ant-text-input {
          width: 100%;
        }
      }
    }
  }

  .sidepanel-footer-actions {
    text-align: right;
    padding: 20px 30px;
    border-top: solid 1px #e4e4e4;

    @media screen and (max-width: $breakpoint-xs) {
      text-align: center;
      padding: 20px;
    }

    .btn {
      padding: 0;
      height: 40px;
      width: 125px;

      @media (max-width: 620px) {
        width: 40%;
      }
    }

    > * {
      margin-left: 15px;
    }

    :first-child {
      margin-left: 0;
    }
  }

  .print-and-heading-container {
    a {
      padding-top: 5px;
    }
    align-items: center;
    display: flex;
    justify-content: space-between;
    .pf2-mb-40 {
      margin-bottom: 0;
    }
    .print-link {
      margin-left: auto;
    }
  }

  // hot state locations desktop slider
  .motif-theme {
    .modal-component-body {
      padding: 5rem 1rem 5rem 4.5rem !important;

      @media screen and (min-width: 801px) {
        padding: 5rem 5rem 5rem 6rem !important;
      }
    }

    .care-team-container .uxd-panel-content {
      overflow: unset;
    }
  }

  [data-uxd-radio-input-cmp] {
    display: flex;
    // padding-bottom: 1rem;
  }

  .dropddown-wrapper {
    padding: 3rem 0 1rem 0;
  }

  .modal-title {
    font-weight: 300;
    font-size: 22px;
    padding-bottom: 2rem;
  }

  .modal-search-suggestion-box {
    padding-top: 25px;

    a {
      text-decoration: none;
      color: #286ce2;
      cursor: pointer;
      padding-bottom: 4px;
    }
  }

  .pf-side-panel-details {
    padding-bottom: 2rem;

    label.pcLabel {
      margin-left: 25px;

      @media only screen and (max-width: $breakpoint-sm) {
        margin-left: 7%;
      }

      @media only screen and (max-width: $breakpoint-xs) {
        margin-left: 9%;
      }
    }
  }

  .pcp-radio-grp {
    div {
      display: flex;
      flex-flow: column;
    }
  }

  .fad-action-buttons {
    display: flex;
    margin: 2rem 0 1rem 0;

    .btn {
      margin-bottom: 1rem;

      @media only screen and (max-width: $breakpoint-sm) {
        width: 80% !important;
      }

      &:not(:only-child) {
        margin-right: 1rem;
      }
    }

    a {
      display: inline-block;

      &:not(:only-child) {
        margin-right: 1rem;
      }
    }

    @media screen and (max-width: 480px) {
      .btn {
        width: 100%;
      }
    }
  }

  .panel-pcp-row {
    padding-bottom: 2rem;
  }

  .pcp-info-wrapper {
    .motif-phone {
      color: #286ce2;
      padding-right: 0.25rem;
    }
  }

  .dropdown-wrapper {
    padding-bottom: 0.5rem;
  }

  .ant-auto-suggest {
    width: 80% !important;
  }

  [data-uxd-auto-suggest-cmp].ant-lg-select .ant-text-input {
    width: 600px;
    max-width: 100%;
  }

  [data-uxd-radio-input-cmp] .prLabel {
    line-height: inherit;
  }

  fieldset.ant-auto-suggest {
    .psDropdown.active {
      position: absolute;
      top: 100%;
    }
  }

  @media screen and (max-width: 800px) {
    .fad-action-buttons {
      display: flex;
      justify-content: flex-start;
      margin: 2rem 0 1rem;
    }
  }

  .width80 {
    width: 80%;
  }
}

//fixing dismissable alert icons disapparing due to having same color as alert background due to motif theming
.ant-alert.ant-dismissable .media-left .fa {
  color: white !important;
  font-size: 2.38rem !important;
}

.pf2-mt-14 {
  margin-top: 1rem;
}

.pf2-mt-10 {
  margin-top: 10px;
}

.pf2-mb-10 {
  margin-bottom: 10px;
}

.pf2-mt-20 {
  margin-top: 20px;
}

.pf2-mb-40 {
  margin-bottom: 40px;
}

.pf2-pb-40 {
  padding-bottom: 40px;
}

.pf2-mt-40 {
  margin-top: 40px;
}

.pf2-mt-8 {
  margin-top: 8px;
}

.pf2-mb-32 {
  margin-bottom: 32px;
}

.pf2-block {
  display: block;
}

.pf2-modal-line {
  border-top: 1px solid #ccc;
}

.pf2-width-40 {
  width: 40%;
}

.pf2-responsive-width {
  @media only screen and (max-width: $breakpoint-xs) {
    width: 100%;
  }
}

.pf2-btn-slider {
  position: absolute;
  bottom: 10%;
  right: 10%;
}

@media only screen and (max-width: $breakpoint-xs) {
  .pf-side-panel .fad-action-buttons.pf2-btn-slider {
    display: block;
    margin-top: 10%;
    position: static;

    .btn {
      width: 100% !important;
    }
  }
}

.changeLocation {
  .pf2-disclaimer {
    font-size: 12px;
    color: #fff;
    display: block;
    margin-top: 10px;
  }

  .pf2-link-white {
    text-decoration: underline;
    color: #fff !important;
    cursor: pointer;
  }
}

.disabled-input {
  background: #e4e4e4;
  opacity: 1 !important;
}

@media only screen and (max-width: $breakpoint-xs) {
  .pf2-change-location {
    .fly-out {
      z-index: 0;
      position: static;

      .look-up-item-header {
        .col-sm-7 {
          width: 50%;
          float: left;
        }

        .show-more-label {
          width: 50%;
          float: right;
        }
      }
    }
  }
}

.pf2-change-location {
  .fly-out {
    height: 200px;
    width: 250px !important;
    overflow-y: auto;

    .look-up-item-header {
      .col-sm-7 {
        width: 50%;
      }

      .show-more-label {
        width: 50%;
      }
    }
  }
}

.motif-theme {
  .ant-font-bold {
    font-weight: bold;
  }

  .change-location {
    .align-vertical .prLabel {
      padding-left: 0;
    }
  }

  #txt-reason-code-other {
    margin-left: 20px !important;
  }

  .not-interested-input {
    width: calc(100% - 25px) !important;
  }
}

@media only screen and (max-width: 768px) {
  .yns-main-wrapper-side-pannel .yns-sidepan-body .yns-action-item-section {
    padding: 20px 35px 20px 0px;
  }

  .yns-action-item-section {
    .yns-action-item-cta {
      padding-right: 10px;
      min-width: 75%;
    }

    .yns-action-item-main-text {
      padding-right: 10px;
      min-width: 100%;
    }
  }

  .yns-action-item-kebab {
    margin-top: -40px;
    margin-left: none;
  }
}

.yns-main-wrapper,
.yns-main-wrapper-side-pannel {
  .yns-header-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 20px 20px 35px;
    background-color: #f8f8f8;
    line-height: 30px;
    border-top: 1px solid #cccccc;
    align-items: center;

    .yns-header-left-title {
      font-size: 1.25em;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 1px;
      color: #2453a6;
    }
  }

  .yns-action-item-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 32px 35px 32px 35px;
    border-bottom: 1px solid #cccccc;
    align-items: center;

    .yns-action-item-main-text {
      width: 60%;
      padding-right: 26px;
      text-align: left;
    }

    .yns-action-item-cta {
      display: flex;
      width: 30%;
      justify-content: flex-start;
      text-align: left;

      a {
        text-decoration: none;
      }

      .yns-cirle-arrow-right {
        margin-top: 3px;
        padding-right: 7px;
        vertical-align: middle;
      }
    }

    .yns-action-item-archive-x {
      width: 18px;
      height: 18px;
      margin: 2px 0px 24px 18px;
      object-fit: contain;

      span {
        width: 10px;
        font-size: 2rem;
      }
    }
  }

  .yns-no-aviable-actions-section {
    text-align: center;
    padding: 20px 40px 20px 40px;

    .yns-no-available-actions-big-check {
      font-size: 80px;
      color: #53b1a3;
    }

    .yns-no-available-actions-big-text {
      margin-top: 19px;
      padding: 0px;
      font-size: 1.375rem;
      font-weight: 300;
      color: #555555;
      margin-bottom: 15px;
    }

    .yns-no-available-actions-link {
      .yns-cirle-arrow-right {
        padding-right: 7px;
        vertical-align: middle;
      }
    }
  }

  .yns-covid-section {
    padding: 20px 30px 20px 30px;
    border: 1px solid #cccccc;
    border-left: 4px solid #c03;
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: flex-start;

    .motif-megaphone {
      font-size: 2rem;
    }

    .covid-text a {
      text-decoration: underline !important;
    }
  }

  .yns-action-err-occured-alert {
    padding: 0px 35px;
  }

  .minimal-tabbedview .ant-tab-button {
    flex-basis: 100%;
    background-color: #ffffff;
  }

  .yns-sidepan-header {
    margin-bottom: 20px;
  }

  .ant-tab-button.is-active {
    font-weight: bold;
  }
}

.card-white {
  box-shadow: 0 6px 12px 0 rgb(0 0 0 / 8%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.pf-personalization-modal-container {
  .modal-dialog {
    .modal-content {
      margin: 25% auto;

      .modal-top {
        .modal-header {
          display: none !important;
        }

        .modal-body {
          .close-button {
            left: 95%;
            position: relative;
          }

          .btn-secondary {
            margin-right: 2%;
          }

          .footer {
            display: flex;
            justify-content: flex-end;
          }
        }
      }

      .modal-footer {
        display: none !important;
      }
    }
  }
}

.font-wt-normal {
  font-weight: normal !important;
}

.applied-filters .filter-item {
  background: #286ce2;
  color: #ffffff;
}

.fts-suggestion {
  .suggestion-item {
    background: #00609a;
    color: $white;

    &:hover,
    &:focus {
      background: #31b0d5;
      border-color: #269abc;
    }
  }
}

.landing-header-wrapper {
  .ant-tooltip-component.top {
    top: 60px !important;
  }
}

.plan-search-wrapper {
  z-index: unset !important;
}

.external-link-bh {
  text-decoration: none;
  margin-left: 8px;
}

.external-link-icon {
  font-size: 30px !important;
  padding: 0 !important;
}

.external-link-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

fieldset.ant-auto-suggest .ant-search-button span.fa {
  color: #000;
  top: 7px;
}

//Recent chat styles//
.rc-side-panel {
  overflow-y: auto;
  height: 100%;
}

.model-recent-chat {
  padding: 5rem 1rem 5rem 5.5rem !important;

  @media screen and (min-width: 801px) {
    padding: 1rem 12.5rem 5rem 5rem !important;
  }
}

.model-recent-chat-title {
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
  margin-bottom: 24px;
}

.rc-rows-container {
  overflow-y: auto !important;
  height: 100% !important;
  margin: 0, 30px, 50px, 30px;
}

.rc-chat-row {
  height: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.rc-chat-detail-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.rc-chat-row-toggle-container {
  width: 30px;
  justify-content: center;
}

.rc-detail-desc {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.rc-chat-title {
  color: #333;
  font-family: Lato, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
}

.rc-chat-type {
  font-family: Lato, sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.2rem;
}

.rc-desc-go-chat {
  margin-top: 16px;
  padding-right: 15px;
}

.rc-chat-row-description {
  align-self: center;
  margin-bottom: 16px;
}

.rc-close-btn-slider {
  position: absolute;
  bottom: 1%;
  right: 5%;
}

.rc-divider-horizontal {
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.rc-header-divider {
  margin: 0 0 24px;
}

.rc-row-devider {
  margin: 24px 0 24px;
}

.rc-no-decorate {
  text-decoration: none !important;
}

.rc-full-width {
  width: 100%;
}

.rc-go-to-chat {
  padding: 0 0.25rem 0 0.25rem;
}

.rc-back-btn {
  float: none;
  height: 60px;
  width: 60px;
  border: none !important;
}

.backButton {
  font-family: "ant-classic-icons", serif;

  &:before {
    content: $ant-classic-arrow-right;
    font-size: 2rem; // redundant?
    color: #286ce2;
  }
}

.pf-vc-lm-container {
  text-align: end;
}

.pf-vc-bottom-space {
  padding-bottom: 3rem;
}

.BaseFooter {
  background-color: #fff;
  bottom: 0;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.08);
  padding: 1rem 2rem 1rem 1rem;
  position: absolute;
  width: 100%;
  text-align: end;
}

//Recent chat style eoc
//Activity
.pf-split-col-adjust {
  display: flex;
  position: relative;
  left: 0;
}

.colorgray {
  color: #808080;
}

.headingRecent {
  font-size: 18px;
  font-weight: 400;
  color: #000000c9;
}

.padding-10 {
  padding: 1rem 0;
}

.padding-5 {
  padding: 0.25rem 0;
}

.pf-justify-content-between {
  display: flex;
  justify-content: space-between;
}

.pf1-split-col {
  width: 50%;
  float: left !important;

  &:first-child {
    float: left;
    margin-right: 40px;
  }

  &:last-child {
    float: right;
  }

  @media (max-width: $breakpoint-sm) {
    width: 100%;
  }
}

.ra-pf-bold {
  font-weight: bold;
}

.ra-pf2-split-col {
  width: 50%;
  float: left !important;

  &:first-child {
    float: left;
    margin-right: 110px;
  }

  &:last-child {
    float: right;
  }

  @media (max-width: $breakpoint-sm) {
    width: 100%;
  }

  &.firstchildpfSplit {
    width: 44%;
    box-shadow: 0 6px 12px #00000014;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    flex: 1 1 calc(50% - 14px);
    text-align: center;
    margin-right: 14px;
    max-width: calc(50% - 14px);
    min-width: 120px;
    margin-bottom: 14px;
  }
}

.cpt-flyout {
  @media only screen and (max-width: $breakpoint-sm) {
    padding: 5rem 1rem !important;
  }

  .minimal-tabbedview .ant-tab-button {
    flex-basis: 28%;

    @media only screen and (max-width: $breakpoint-sm) {
      flex-basis: 50%;
    }
  }
}

.cpt-scroll {
  height: auto;
  max-height: 141px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0 !important;
}

.sticky-btn {
  position: absolute;
  bottom: -8px;
  padding: 0 !important;
  margin: 0;
  background: #fff;
  width: auto;
}

.relative {
  position: relative;
}

.speciality-sticky-button {
  .btn-row-area {
    position: absolute;

    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
      left: 0;
    }

    .btn-align-right {
      width: 50%;
      float: right;

      .btn-position {
        justify-content: space-evenly;
      }
    }

    .btn-location-slider {
      width: auto;
      margin-top: 24px;
      margin-bottom: 12px;
      float: right;
      margin-right: 20px;

      @media screen and (max-width: $breakpoint-md) {
        width: 100%;

        .btn {
          display: block;
          width: 90%;
          margin-top: 8px;
          margin-left: 10%;
        }
      }
    }

    .btn-align-right-direction {
      width: 100%;
      padding: 1rem 6rem 0 5rem !important;
      display: flex;
      justify-content: flex-end;

      @media only screen and (max-width: $breakpoint-lg) {
        padding-right: 5rem !important;
      }

      .btn-container {
        @media only screen and (max-width: $breakpoint-sm) {
          display: flex;
          justify-content: flex-end;

          .btn.btn-primary {
            width: 48%;
          }
        }
      }

      .btn-position {
        @media only screen and (max-width: $breakpoint-sm) {
          text-align: end;
          width: 100%;
          display: inline-block;
          padding: 0;
        }
      }
    }
  }
}

.font-speciality {
  font-weight: 400;
}

.eyeball-svg {
  filter: invert(43%) sepia(60%) saturate(5958%) hue-rotate(209deg) brightness(94%) contrast(88%);
}

.disabled-section {
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}

// Styling for the hyperlink in Free Text search to switch to classic integrated search.
.pf-link-white-swtich {
  color: #fff;
  cursor: pointer;
  text-decoration: underline !important;
}

//styling for other suggestion in search-result page
// Free Text Search result summary
.fts-infoBox-container {
  position: relative;
  padding: 1rem;
  margin: 18px;
  border: 1px solid #b3b3b3;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

  .search-criteria-suggestions {
    margin: 1%;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .fts-alternative-suggestions {
      flex: 4;
      flex-shrink: 1;
    }

    .beta-feedback {
      margin-top: 1%;
      text-align: right;
      flex: 1;
      flex-shrink: 3;
      order: 2;
    }
  }

  .dismiss {
    position: absolute;
    top: 16%;
    right: 2%;

    .fa.fa-plus {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      color: #949494;
      font-size: 28px !important;
    }
  }

  @media (max-width: 600px) {
    .search-criteria-suggestions {
      flex-direction: column;

      .beta-feedback {
        text-align: center;
        margin-top: 2%;
      }
    }
  }
}

.fcr-fts-available-suggestions-container {
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem 0 0;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.fcr-fts-infoBox-container {
  position: relative;
  padding: 1rem;
  border: 1px solid #b3b3b3;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;

  .search-criteria-suggestions {
    max-width: 90%;
    flex: 1;
    margin: 1%;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .fts-alternative-suggestions {
      max-width: 70%;
      flex-shrink: 1;
      margin-right: 1%;
    }
    .fcr-beta-feedback {
      position: relative;
      order: 2;
      .fcr-beta-feedback-btn-container {
        width: 100%;
        height: auto;
        .fcr-beta-feedback-btn {
          width: auto;
          height: auto;
          white-space: nowrap;
          padding: 0.5em 1em;
          background: #ffffff;
          border: 1px solid #286ce2;
          color: #286ce2;
          text-align: center;
        }
      }
    }
  }
  .dismiss {
    margin-left: auto;
    order: 2;

    .fa.fa-plus {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      color: #949494;
      font-size: 28px !important;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    .search-criteria-suggestions {
      flex-direction: column;

      .fts-alternative-suggestions {
        max-width: 100%;
      }

      .fcr-beta-feedback {
        text-align: left;
        margin-top: 2%;
      }
    }
  }
}

.pf-fts-suggestions {
  a {
    color: #286ce2;
    text-decoration: none !important;
  }

  a:hover {
    font-weight: bold;
  }
}

.pf-fts-alternate-search-options {
  margin-top: 20px;
}

.feedback-btn {
  margin-top: 1%;
  padding: 2% 10%;
}

.fcr-feedback-btn {
  background: #ffffff;
  color: #286ce2 !important;
  padding: 0.5em 1em;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border: 1px solid #286ce2;
}

.feedback-row {
  margin-bottom: 5%;
}

.feedback-div {
  display: flex;
  box-sizing: inherit;

  .feedback-textarea {
    overflow: auto;
    resize: none;
    width: 100%;
    height: 20vh;
  }
}

.pf-feedback-remaining-chars {
  display: flex;
  justify-content: flex-start;
  font-size: 0.8rem;
  color: #808080;
}

.modal-component-footer {
  border-top: 1px solid #f7f7f7;
  padding: 2.5%;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -2px 10px 0px #00000014;
  background-color: #ffffff;
}

.modal-action-btn {
  display: flex;
  justify-content: flex-end;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.fcr-clear-filter {
  display: flex;
  align-items: center;

  .fcr-clear-filter-icon {
    font-size: 26px !important;
  }

  .fcr-clear-filter-btn-txt {
    font-weight: 700;
  }
}

//Pagination
.fcr-pagination-summary {
  margin-bottom: 20px;
}

.fcr-pagination-container {
  display: flex;
  justify-content: center;

  .pagination {
    li {
      &.active > a {
        background-color: #286ce2;
        border-color: #286ce2;
        color: #ffffff;
      }

      a {
        border-radius: 50%;
        margin: 0 5px;
        background: #286ce2;
        color: #fff;

        &#lnk-pf-pagination-next,
        &#lnk-pf-pagination-last,
        &#lnk-pf-pagination-first,
        &#lnk-pf-pagination-previous {
          background: transparent;
          color: #286ce2;
          font-size: 52px;
          padding: 0;
          border: none;
          height: 32px;
          position: relative;
          top: 12px;
          line-height: 0;
          font-weight: 200;
        }
      }

      .pagination-number {
        background-color: #f7f7f7;
        border: 1px solid #949494;
        border-radius: 50%;
        color: #286ce2;
      }
    }
  }

  .fad-pf-pagination-link a {
    border-bottom: none;
  }
}

.filter-side-panel {
  overflow-y: auto !important;
  height: 100% !important;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .filter-modal-component-body {
    padding: 5rem 1rem 5rem 5.5rem !important;
    height: calc(100vh - 150px);
    overflow-y: scroll;

    hr {
      border-top: 1px solid #cccccc;
      margin-top: 0;
    }

    .fcr-filter-category {
      .fcr-filter-item {
        margin-left: 1.5rem;
        margin-bottom: 1rem;

        label.pcLabel {
          font-size: 1em;

          &::before {
            font-size: 11px;
            font-weight: 100;
            width: 16px;
            height: 16px;
            padding: 2px;
          }
        }
      }

      .fcr-filter-item-description {
        margin-left: 57px;
      }

      .fcr-filter-description {
        margin-left: 30px;
      }

      .billing-code-filter {
        margin-left: 2rem;
        margin-bottom: 2rem;

        .billingcode-dropdown-container {
          width: 100%;
        }

        .cpt-filter-desc {
          padding: 5px 0;
        }
      }

      .uxd-expansion-panel {
        border-bottom: none;
      }

      .filter-distance-lookup-container {
        .fly-out {
          position: relative;
        }
        .look-up-container {
          border: none;
          box-shadow: none;
        }
      }
    }

    @media screen and (min-width: 801px) {
      padding: 1rem 5rem 5rem 5rem !important;
    }

    .filter-heading-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .close-button {
      border: none;
      background: none;
      color: rgba(19, 85, 233, 1);
    }

    .filter-margin {
      margin-right: 9px;
    }

    .filter-headig {
      font-family: Montserrat, sans-serif;
      font-size: 26px;
      font-weight: 300;
      line-height: 32px;
    }
  }

  .uxd-expansion-header {
    padding: 16px 0px;
  }

  .filter-accordion-component {
    .uxd-expansion-toggle {
      width: 100%;
    }

    .uxd-expansion-button {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .expansion-title {
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      color: rgba(51, 51, 51, 1);
      margin-left: 8px;
    }

    .selected-filter-count {
      font-family: Lato, sans-serif;
      font-size: 12px !important;
      font-weight: 700;
      line-height: 15px;
      text-align: right;
      color: #333333;
    }
  }

  .filter-side-panel-chip {
    .chip-close-button {
      color: #ffffff;
      background: none;
      border: none;

      span {
        font-size: 9px !important;
      }
    }

    display: flex;
    align-items: center;
    border-radius: 16px;
    padding: 4px 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
    background-color: rgba(40, 108, 226, 1);
    color: #ffffff;
  }

  .filter-chip span {
    margin-right: 8px;
  }

  .filter-side-panel-buttons {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: -webkit-fill-available !important;
    align-items: center;
    height: 93px;
    padding-right: 33px;
    padding-left: 55px;
    box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.08);
    #fcr-buttons-container {
      gap: 16px;
    }
    > div {
      display: flex;
    }

    .cancel-button {
      border: none;
      background: none;
      padding: 12px;
      font-weight: 700;
    }

    .undo-button {
      padding: 12px 16px;
      border: 1px solid #286ce2;
      color: #286ce2;
      font-weight: 700;
      display: flex;
      align-items: center;

      .motif-undo {
        font-size: 26px;
      }

      span {
        display: flex;
        align-items: center;
        padding-right: 10px;
      }
    }

    .apply-button {
      background-color: #286ce2;
    }
  }
}

.fcr-applied-filter {
  padding: 0px;
  margin-bottom: 16px;

  a {
    color: #ffffff;
    text-decoration: none;
    line-height: 1rem;

    &:hover {
      .filter-item-close {
        background: rgba(255, 255, 255, 0.15);
      }

      .filter-more-showmore {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    &:focus {
      outline: 0;

      .filter-item-close {
        border-width: 2px;
      }

      .filter-more-showmore {
        border-width: 2px;
      }
    }
  }

  .filter-item {
    line-height: 1.2rem;
    display: inline-flex;
    align-items: center;
    padding: 7px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 16px;
    margin-left: auto !important;
    background: #286ce2;
    color: #ffffff;

    .filter-item-close,
    .filter-more-showmore {
      display: inline-flex;
      flex: 0 0 16px;
      align-items: center;
      justify-content: center;
      font-size: 10px !important;
      font-size: 7px !important;
      font-weight: bold;
      border-radius: 8px;
      height: 16px;
      width: 16px;
    }

    .filter-item-close {
      color: white;
      font-size: 10px !important;
      font-weight: 400;
    }

    .filter-more-showmore {
      transform: rotate(45deg);
      color: #286ce2;
      border: 1px solid #286ce2;
    }

    .custom-icon-showless {
      transform: none;
      font-size: 14px !important;

      &:before {
        content: "\002212";
        margin-top: -4px;
      }
    }

    .filter-name {
      font-size: 12px !important;
      font-weight: 400;
      padding-right: 10px;
    }

    &.filter-more {
      border: 1px solid #286ce2;
      background: white;
      color: #286ce2;
    }
  }
}

// FindCare redesign css - end

.care-now-container {
  .care-now-cards-container {
    padding: 0 18.5%;

    > uxd-expansion-panel {
      border-bottom: none;
    }

    .card-title {
      font-weight: 400;
    }

    .card-content-container {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin: 0 76px 60px 76px;

      @media screen and (max-width: $breakpoint-sm) {
        display: none;
      }
    }

    .card-content-container-resp {
      display: none;

      @media screen and (max-width: $breakpoint-sm) {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 0 48px 32px;
      }
    }

    @media screen and (max-width: $breakpoint-sm) {
      padding: 0;
      margin: 0 15px;
    }
  }

  .care-now-banner-container {
    position: relative;
    margin-bottom: 59px;

    .care-now-banner {
      width: 100%;
      height: 316px;
    }

    .banner-text-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      position: absolute;
      top: 15%;
      left: 52%;
      padding-right: 10%;

      .banner-title {
        line-height: 39px;
      }

      .banner-description {
        line-height: 22px;
      }
    }

    @media screen and (max-width: $breakpoint-sm) {
      display: none;
    }
  }

  .care-now-banner-container-resp {
    display: none;

    @media screen and (max-width: $breakpoint-sm) {
      display: block;
      margin-bottom: 42px;

      .banner-text-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 0 22px 0 20px;
        margin-bottom: 27px;

        .banner-title {
          line-height: 39px;
        }

        .banner-description {
          line-height: normal;
        }
      }

      .care-now-banner {
        width: 100%;
      }
    }
  }

  .text-container {
    display: flex;
    gap: 33px;

    .care-text {
      flex: 1;
      line-height: 22px;
    }

    .good-choice-text {
      font-weight: 700;
    }
  }

  .care-button {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 7px 30px;
  }

  .cost-time-container {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .cost-container {
      display: flex;
      flex-direction: column;

      .cost-label {
        font-size: 12px !important;
        font-weight: 700;
        line-height: 15px;
      }

      .dollar-sign {
        color: var(--Grays-Dark-Gray-2, #4a4a4a);
        font-size: 22px !important;
        font-weight: 700;
        line-height: normal;
      }
    }

    .separator {
      border-left: 1px solid #ccc;
    }
  }

  .points-container {
    margin-top: 98px;

    .points-ol {
      color: var(--Grays-Dark-Gray-1, #000);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      padding-left: 12px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      margin-top: 43px;
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    .uxd-expansion-button {
      margin-right: 3px !important;
    }
  }
}

// FCR styles starts
// FindCare redesign css - starts
.fcr-plan-slider {
  .ant-auto-suggest {
    width: 100% !important;
  }
}
.fcr-page-wrapper {
  background-color: #ffffff;
  .mbr-top-of-page-wrapper {
    height: 61px;

    @media screen and (max-width: $breakpoint-xs) {
      //margin-top: 5.05rem;

      .ant-logo-wrapper a img {
        max-width: auto;
        max-height: auto;
        height: auto;
      }
    }
  }

  .page-header-wrapper {
    margin-top: 5.0625rem;

    &::after,
    &::before {
      display: none;
    }

    @media screen and (min-width: $breakpoint-sm) {
      margin-top: 3.875rem;
    }

    @media screen and (min-width: $breakpoint-md) {
      margin-top: 5.3125rem;
    }

    @media screen and (max-width: $breakpoint-xs) {
      margin-top: 5.05rem;
    }
  }

  .fcr-page-sticky-top-container {
    position: sticky;
    top: 85px;
    z-index: 120;

    @media screen and (max-width: $breakpoint-md) {
      top: 60px;
    }
    @media screen and (max-width: $breakpoint-sm) {
      top: 63px;
    }
  }

  .fcr-container-spacing-left {
    padding-left: 15px;

    @media screen and (min-width: $breakpoint-sm) {
      padding-left: calc((100vw - $container-sm + 30px) / 2);
    }

    @media screen and (min-width: $breakpoint-md) {
      padding-left: calc((100vw - $container-md + 30px) / 2);
    }

    @media screen and (min-width: $breakpoint-lg) {
      padding-left: calc((100vw - $container-lg + 30px) / 2);
    }
  }

  .fcr-container-spacing-right {
    padding-right: 15px;

    @media screen and (min-width: $breakpoint-sm) {
      padding-right: calc((100vw - $container-sm + 30px) / 2);
    }

    @media screen and (min-width: $breakpoint-md) {
      padding-right: calc((100vw - $container-md + 30px) / 2);
    }

    @media screen and (min-width: $breakpoint-lg) {
      padding-right: calc((100vw - $container-lg + 30px) / 2);
    }
  }

  .secure-sticky-back {
    width: 158% !important;
    margin-left: -24rem !important;
    padding-left: 24rem !important;
  }
}

.container {
  &.fcr-container-no-spacing {
    padding-left: 0;
    padding-right: 0;
  }
}

//find care result page style starts here
.pr-10 {
  padding-right: 10px !important;
}

.fcr-results-content {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
  --result-content-width: 100%;

  .fcr-results-main-content {
    display: flex;
    position: relative;

    .fcr-member-plan-widget {
      max-width: 480px;
    }

    .fcr-disable-actions {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ffffffb0;
      z-index: 1;
    }

    .fcr-results-list-container {
      width: 100%;

      &.show-card-view {
        flex: 0 0 51%;

        &.show-affiliation-card-view {
          overflow: hidden;
          position: relative;
        }

        &.show-location-card-view {
          overflow: hidden;
          position: relative;
          flex: 0 0 150px;
        }

        &.list-full-width {
          flex: 0 0 100%;
        }
        @media screen and (max-width: $breakpoint-md) {
          display: none;
        }
      }

      &.show-map-view {
        flex: 0 0 49%;
        position: relative;

        &.show-affiliation-map-view {
          flex: 0 0 174px;
          overflow: hidden;
          position: relative;
          .provider-card-container {
            height: 150px;
          }
        }

        &.show-location-map-view {
          flex: 0 0 174px;
          overflow: hidden;
          position: relative;
        }

        @media screen and (max-width: $breakpoint-md) {
          display: none;
        }
      }

      .fcr-list-buttons-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 12px;
        flex: 1 1 auto;

        @media screen and (max-width: $breakpoint-md) {
          position: fixed;
          bottom: 16px;
          top: auto;
          left: 0;
          right: 0;
          margin-left: 0;
          z-index: 1;
          justify-content: center;
        }
      }

      .fcr-plan-progress-widget-container {
        flex-direction: row;
        align-items: center;
        gap: 8px;
        width: 100%;
        > div {
          flex: 1 1 auto;
          max-width: 50%;
          @media screen and (max-width: $breakpoint-md) {
            max-width: 100%;
          }
        }
        &.flex-col {
          flex-direction: column;
          align-items: stretch;
          padding-right: 10px;
          > div {
            flex: 1 1 auto;
            max-width: 100%;
          }
        }
        @media screen and (max-width: $breakpoint-sm) {
          padding-right: 10px;
          padding-left: 10px;
        }
      }

      @media screen and (max-width: $breakpoint-md) {
        width: 100%;
      }
    }

    .fcr-results-right-container {
      position: relative;
      flex: 0 0 51%;
      z-index: 1;

      &.show-map-view {
        flex: 0 0 51%;
        --map-sticky-offset: 135px;
        height: calc(100vh - 1px);
        position: sticky;
        top: var(--map-sticky-offset);
        &.single-page-map-ht {
          height: calc(100vh - 75px);
        }

        @media screen and (max-width: $breakpoint-md) {
          flex: 0 0 100%;
        }

        &.show-affiliation-map-view {
          flex: 0 0 calc(100% - 174px);
        }

        &.show-location-map-view {
          flex: 0 0 calc(100% - 174px);
        }

        .fcr-provider-card {
          border-radius: 20px;
          position: absolute;
          left: 0px;
          z-index: 1;
          bottom: 10px;
          top: 0px;
          margin: 10px;
        }
      }

      &.show-card-view {
        flex: 0 0 49%;
        --map-sticky-offset: 135px;
        position: sticky;
        top: var(--map-sticky-offset);
        height: calc(100vh - 16px);
        display: flex;
        gap: 10px;
        &.single-page-card-ht {
          height: calc(100vh - 90px);
        }
        &.show-location-card-view {
          flex: 1;
          display: flex;
          .fcr-view-locations-card {
            background-color: #ffffff;
            height: calc(100vh - 160px);
            border: solid 1px #cccccc;
            margin-top: 10px;
          }
        }

        &.list-full-width {
          flex: 0;
        }

        .fcr-provider-card {
          border-radius: 0px;
          left: inherit;
          margin-top: 10px;
          max-width: none;
        }
        .fcr-map-toggle-button {
          display: none;
        }

        @media screen and (max-width: $breakpoint-md) {
          flex: 0 0 100%;
          width: var(--result-content-width);

          .fcr-map-toggle-button {
            display: block;
          }

          .fcr-provider-card {
            width: 100%;
            top: 70px;
            max-width: 100%;
            height: calc(100vh - 160px);
          }
        }
      }

      &.hide-it {
        display: none !important;
      }

      .fcr-provider-card {
        width: 100%;
        max-width: 454px;
        height: calc(100vh - 160px);
        max-height: 100vh;
        background-color: #ffffff;
        border: solid 1px #cccccc;

        .fcr-provider-card-header {
          padding: 20px 20px 0px;
          display: flex;
          flex-direction: column;
          align-items: stretch;
          gap: 6px;
          min-height: 72px;

          .fcr-provider-card-header-top {
            display: flex;
            flex-direction: row;
            gap: 16px;
            align-items: center;

            p {
              flex: 1 1 auto;
              font-size: 18px;
              font-weight: 700;
              margin-bottom: 0;
            }

            .fcr-provider-card-top-actions-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              flex: 0 0 auto;
              gap: 16px;
            }

            .fcr-provider-print-icon {
              color: #286ce2;
              line-height: 26px;
              cursor: pointer;

              img {
                padding-right: 5px;
              }

              span {
                font-weight: 700;
              }
            }

            .fcr-provider-card-close-icon {
              span {
                height: 22px;
                width: 22px;
                cursor: pointer;
              }
            }
          }

          .fcr-provider-card-header-down {
            display: flex;

            .fcr-provider-card-location {
              display: flex;
              font-weight: 400;
              color: #666666;
              text-transform: capitalize;

              .county-label {
                padding-left: 5px;
              }

              a {
                padding-left: 10px;
                font-weight: 700;
                color: #286ce2;
                text-decoration: none;
                cursor: pointer;
              }
            }
          }
        }

        .fcr-page-alert-details-content {
          margin: 20px 10px 20px 10px;
        }

        .fcr-provider-card-content {
          height: calc(100% - 185px);
          margin-top: 21px;

          &.hide-footer {
            height: calc(100% - 95px);
            margin-top: 0px;
          }

          .fcr-provider-tab {
            position: relative;

            .ant-tab-header {
              border-bottom: solid 1px #cccccc;
              box-shadow: 0px 6px 12px 0px #00000014;
              display: flex;
              align-items: center;
              width: 100%;

              .ant-tab-list {
                display: flex;
                align-items: center;
                flex: 1 1 auto;
                gap: 10px;
                justify-content: space-evenly;

                .ant-tab-button {
                  border-bottom: none;

                  &.is-active {
                    border-bottom: solid 2px #286ce2;
                    font-weight: 700;
                  }
                }
              }
            }

            .tab-body {
              height: calc(100% - 50px);
              overflow-y: auto;
              scrollbar-width: none;
              -ms-overflow-style: none;

              &::-webkit-scrollbar {
                display: none;
              }
              .ant-tab-body {
                height: 100%;
                .card-loader {
                  display: flex;
                  align-items: center;
                  height: 100%;
                }
              }
            }
          }

          .fcr-provider-card-content-top {
            display: flex;
            border-bottom: solid 1px #cccccc;
            border-top: solid 1px #cccccc;

            .fcr-provider-img {
              width: 200px;
              overflow: hidden;
              object-fit: contain;
              background-color: #cccccc;
              height: 200px;
              display: flex;
              align-items: flex-end;
              img {
                width: 100%;
                height: auto;
                max-height: 200px;
              }
            }

            .fcr-provider-quicklinks {
              flex: 1;
              padding: 15px 10px 10px 10px;
              align-items: center;
              display: flex;

              .fcr-quicklink {
                list-style: none;
                padding-left: 0px;
                margin-bottom: 7px;

                li {
                  padding: 5px 0px;
                  display: flex;

                  img {
                    padding-right: 5px;
                  }

                  .fcr-pcp-list {
                    display: flex;
                    flex-direction: column;

                    p {
                      img {
                        padding-right: 5px;
                      }

                      a {
                        color: #286ce2;
                        text-decoration: none;
                        font-weight: 700;
                        cursor: pointer;
                      }

                      margin-bottom: 0;
                    }

                    p:not(:first-of-type) {
                      padding-left: 25px;
                    }
                  }
                }
              }
            }
          }

          .fcr-provider-card-content-down {
            display: flex;
            height: 70px;
            align-items: center;
            text-align: center;
            border-bottom: solid 1px #cccccc;
            justify-content: space-between;
            padding: 0 20px;
            gap: 7px;

            .fcr-provider-appointment {
              flex: 0 0 auto;
              display: none;

              .fcr-provider-btn {
                min-width: 152px;
                width: auto;
                height: 30px;
                font-weight: 700;
                border: solid 1px #286ce2;
                background-color: #ffffff;
                color: #286ce2;
                font-size: 12px;
              }
            }

            .fcr-provider-review-wrapper {
              display: flex;
              flex: 1 1 auto;
              align-items: center;
              justify-content: space-between;
              gap: 7px;
            }

            .fcr-provider-rating {
              flex: 1 1 auto;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 4px;
              padding: 0;
              &.justify-left {
                justify-content: flex-start;
              }

              .total-reviews {
                color: #286ce2;
                text-decoration: underline;
                font-weight: 700;
                cursor: pointer;
                &.is-disabled {
                  text-decoration: none;
                  color: #333333;
                  font-weight: 400;
                  cursor: inherit;
                }
              }
            }

            .fcr-provider-add-review {
              flex: 0 0 auto;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              gap: 7px;

              a {
                font-weight: 700;
                color: #286ce2;
                text-decoration: none;
                cursor: pointer;
              }
            }
          }
        }

        .fcr-overview-accordian {
          padding: 16px 20px;
          border-bottom: solid 1px #cccccc;
          border-top: solid 1px #cccccc;
          justify-content: space-between;

          .cpt-expansion-panel {
            width: 100%;
            border: none;
            margin-bottom: 0;
          }

          .fcr-accordian-title {
            font-weight: 700;
            display: flex;
            cursor: pointer;

            .accordian-title {
              flex: 1;
              padding-left: 5px;
              padding-top: 2px;
            }

            .action-btn {
              &.affiliation {
                transform: rotate(270deg);
              }

              &.recognition {
                transform: rotate(270deg);
              }
            }

            .uxd-expansion-header {
              display: flex;
              justify-content: space-between;
              padding-top: 0px;
              padding-bottom: 0;
              width: 100%;

              .uxd-expansion-toggle {
                flex: 1;

                .uxd-expansion-button {
                  width: 100%;
                  margin-right: 0;
                  padding: 0;
                  display: flex;
                  justify-content: space-between;
                  text-align: left;

                  span.motif-icon.motif-angle-down.ant-font-bold.ant-heading-two {
                    font-size: 24px !important;
                  }

                  .accordian-title {
                    padding-top: 0;
                    color: #333333;
                  }
                }

                &:focus-visible {
                  outline: none;
                }
              }
            }
          }

          .fcr-no-accordian-title {
            font-weight: 700;
            display: flex;

            .no-accordian-title {
              flex: 1;
              padding-left: 5px;
              padding-top: 2px;
            }
          }

          .fcr-no-accordian {
            padding-left: 21px;
            padding-top: 2px;
          }

          &:last-child {
            border-top: none;
          }

          .fcr-accordian-content {
            padding-top: 16px;

            .fcr-office-hour-time {
              text-transform: capitalize;
              padding-right: 24px;
            }

            ul {
              list-style: none;
              padding-left: 20px;
              display: flex;
              flex-direction: column;

              li {
                display: flex;
                justify-content: space-between;
                padding: 5px 0px;

                span:first-child {
                  font-weight: 700;
                }
              }

              &:first-child {
                padding-top: 0;
              }

              &:last-child {
                padding-bottom: 0;
              }
            }
          }
        }

        .fcr-morelinks {
          .action-title {
            font-weight: 700;
            padding: 20px 20px 0px;
            font-size: 14px;
          }

          ul {
            list-style: none;
            color: #286ce2;
            line-height: 22px;
            margin-bottom: 0;
            padding-top: 10px;
            padding-left: 20px;

            &.quick-facts {
              color: #333333;

              li {
                a {
                  cursor: pointer;
                  color: #286ce2;
                  font-weight: 700;
                  text-decoration: none;
                }
              }
            }

            &.fcr-more-action {
              li {
                span {
                  top: 2px;
                  position: relative;
                  a {
                    &.online-provider {
                      color: #333333;
                      cursor: initial;
                    }
                  }
                }
              }
            }

            li {
              padding: 5px 0px;
              font-weight: 700;
              &.fcr-education-entry {
                display: flex;
                img {
                  align-self: flex-start;
                  padding-top: 5px;
                }
              }

              img {
                padding-right: 5px;
              }

              a {
                text-decoration: none;
                font-weight: 700;
              }
            }

            &:last-child {
              padding-bottom: 10px;
            }
          }
        }

        .fcr-provider-card-footer {
          height: 90px;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          box-shadow: 0px -2px 10px 0px #00000014;

          .fcr-card-btn {
            height: 45px;
            width: auto;
            min-width: 168px;
            font-weight: 700;
            margin-right: 10px;

            &.fcr-primary-button {
              background: #286ce2;
              color: #ffffff;
              border: none;
            }

            &.fcr-border-button {
              background: #ffffff;
              color: #286ce2;
              border: solid 1px #286ce2;
            }

            &.fcr-no-border-button {
              font-size: 14px;
              font-weight: 700;
              line-height: 22px;
              color: #286ce2;
              cursor: pointer;
              display: flex;
              align-items: center;
              border: none;
              background: #f7f7f7;
            }
          }
        }
      }

      .fcr-results-map-container {
        width: 100%;
        background-color: #d9d9d9;
        position: relative;

        @media screen and (max-width: $breakpoint-md) {
          width: 100%;
        }
      }
      .fcr-map-buttons-wrapper {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        justify-content: center;
        gap: 12px;
        flex-direction: column;

        @media screen and (max-width: $breakpoint-md) {
          flex-direction: row;
          position: fixed;
          bottom: 16px;
          top: auto;
          left: 0;
          right: 0;
          z-index: 1;
        }
      }

      .fcr-search-results-floating-button {
        position: absolute;
        top: 16px;
        right: calc(100% + 16px);
        background-color: #286ce2;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
        border-radius: 8px;
        cursor: pointer;
        padding: 10px;
        width: 120px;
        height: 90px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        border: none;

        .motif-numbered-list {
          font-size: 28px !important;

          @media screen and (max-width: $breakpoint-md) {
            font-size: 20px !important;
          }
        }

        &-text {
          font-size: 12px;
          line-height: 15px;
          text-transform: capitalize;
          margin-top: 8px;

          @media screen and (max-width: $breakpoint-md) {
            margin-top: 0;
          }
        }

        @media screen and (max-width: $breakpoint-md) {
          display: flex;
          gap: 8px;
          width: 135px;
          height: 36px;
        }
      }
    }

    .fcr-map-toggle-button {
      width: 160px;
      height: 45px;
      padding: 10px;
      border-radius: 40px;
      background: #333333;
      border: none;

      img {
        margin-right: 10px;
      }

      .motif-icon {
        font-size: 16px;
        margin-right: 12px;
      }

      .fcr-map-toggle-button-desktop {
        display: block;

        @media screen and (max-width: $breakpoint-md) {
          display: none;
        }
      }

      .fcr-map-toggle-button-mobile {
        display: none;

        @media screen and (max-width: $breakpoint-md) {
          display: block;
        }
      }
    }

    .fcr-view-options-toggle-button {
      width: 160px;
      height: 45px;
      padding: 10px;
      border-radius: 40px;
      color: #286ce2;
      background: #ffffff;
      box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
      border: none;

      .fcr-virtual-options-label {
        margin-left: 8px;
      }
    }

    @media screen and (max-width: $breakpoint-md) {
      flex-direction: column;
    }
  }
}

.fad-app-wrapper {
  .fcr-results-content {
    .fcr-results-main-content {
      .fcr-results-right-container {
        &.show-card-view {
          .fcr-provider-card {
            top: 70px;
            height: calc(100vh - 220px);
          }
        }
      }
    }
  }
}

label.pcLabel.focus {
  outline: none;
}
.fcr-disclaimer {
  width: 100%;
  max-width: 83.12rem;
  margin: 0 auto;
  padding: 1rem;
}

.map-view {
  height: calc(100% - 100px);
  overflow-y: auto;
  border-right: solid #0c7905 8px;
}

.member-phone {
  margin-right: 10px;
}

.fcr-selected-provider-container {
  background-color: #113966;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08);
  position: sticky;
  top: 85px;
  z-index: 2;

  @media only screen and (max-width: 480px) {
    .fcr-selected-providers {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px !important;
    }

    .fcr-selected-providers-count {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    .selected-provider {
      width: 100%;
      margin-top: 10px;
    }
  }

  .fcr-selected-providers {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 0 4px;
    margin: 10px auto;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .selected-provider-count {
    font-family: Lato;
    margin-right: 10px;
    line-height: 26.4px;
    text-align: left;
    font-size: 22px !important;
    color: #ffffff;
    flex: 0 0 auto;
  }

  .selected-providers-toggle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    order: 0;
    flex-wrap: wrap;
    padding: 0 10px;
  }

  .selected-provider-count-mobile {
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: #ffffff;
    padding-right: 8px;
  }

  .selected-provider {
    display: flex;
    justify-content: space-between;
    padding: 4px 20px;
    align-items: flex-start;
    background-color: #113966;
    height: 30px;
    color: #ffffff;
    gap: 8px;
    border-radius: 32px;
    border: 1px solid #ffffff;

    .selected-provider-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 110px;
    }

    .compare-close {
      font-size: 10px !important;
      padding: 5px 0px;
      cursor: pointer;
    }
  }

  .selected-providers-actions {
    margin-left: auto;

    .cancel-selection {
      color: #ffffff;
    }

    .compare-providers {
      padding: 4px 20px;
      color: #113966;
      height: 30px;
      background-color: #ffffff;
      border-radius: 32px;
      border: 1px solid #ffffff;
      margin-left: 20px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .fcr-selected-provider-container {
    top: 65px;
  }
}

.fcr-filter-chips-container {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background-color: #f7f7f7;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08);

  .fcr-selected-filters {
    display: flex;
    gap: 10px;
    padding: 0 4px;
    margin: auto;
    width: 100%;
    @media screen and (min-width: 801px) {
      width: $container-sm;
    }

    @media screen and (min-width: 1025px) {
      width: $container-md;
    }

    @media screen and (min-width: 1201px) {
      width: $container-lg;
    }
  }

  .filter-chips-button {
    display: flex;
    align-items: center;
    padding: 4px 20px;
    gap: 10px;
    border: 1px solid #949494;
    border-radius: 32px;
    background-color: #ffffff;
    &.no-mobile {
      @media screen and (max-width: $breakpoint-sm) {
        display: none;
      }
    }
  }

  .filter-chips-selected-button {
    border: 2px solid #286ce2;
  }

  .filter-sort-lookup-container {
    .fcr-look-up {
      display: grid;
    }

    .filter-sort-button {
      border: none;
      background: none;
      text-align: left;
    }
  }
}

.fad-app-wrapper {
  .fcr-filter-chips-container {
    top: 5px;
  }
  .fcr-selected-provider-container {
    top: 5px;
  }
}

.fcr-sav-container {
  padding: 30px 15px;
  .fcr-sav-results-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .fcr-sav-search-wrapper {
    padding: 40px 15px;
    margin-top: 0;
    border: none 0;
  }

  .fcr-sav-county-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

.provider-card-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 0;
  border-top: 0 !important;
  border-bottom: 1px solid #ccc;

  &.is-active {
    background-color: #f7f7f7;
  }

  &:hover {
    background-color: #f7f7f7;
  }

  &.in-network {
    border-right: solid 8px #0c7905;
  }

  &.not-in-network {
    border-right: solid 8px #d49203;
  }

  &.out-of-network {
    border-right: solid 8px #a35c00;
  }

  &.virtual-provider {
    border-right: solid 8px #511284;
  }

  .child-element {
    flex-grow: 1;
    /* Allow child elements to grow and fill the remaining space */
  }

  &:first-child {
    border-top: 1px solid #ccc;
  }

  label.pcLabel {
    font-size: 1em;

    &::before {
      font-size: 11px;
      font-weight: 100;
      width: 16px;
      height: 16px;
      padding: 2px;
    }
  }

  .provider-card-input-wrapper {
    padding: 0 5px 0 0;

    input {
      width: 16px;
      height: 16px;
      border-radius: 0 !important;
    }
  }

  .provider-card-user-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex: 1;
  }

  .provider-card-left-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    flex-direction: column;
    position: relative;
    margin-right: 10px;
    .member-avatar {
      background: #cccc;
      border-radius: 50%;
      margin-bottom: 10px;
    }

    .member-profile-check {
      position: absolute;
      width: 26px;
      height: 26px;
      border-radius: 100%;
      background: #0c7905;
      color: #fff;
      bottom: 50px;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .member-profile-pic {
      width: 88px;
      height: 88px;
      border-radius: 50%;
      padding: 3px;
      position: relative;
      top: 3px;
    }

    .network-status {
      font-size: 12px;
      color: #666;
      font-weight: 600;
      text-align: center;
    }

    .network-status-icon-overlay {
      position: absolute;
      width: 26px;
      height: 26px;
      background-color: transparent;
      top: 62px;
      right: 0;
      &.in-network {
        background-image: url(/assets/images/common/icon-green-circle.svg);
        background-size: 26px 26px;
      }
      &.not-in-network {
        background-image: url(/assets/images/common/icon-warning-circle.svg);
        background-size: 26px 26px;
      }
    }
  }

  .provider-card-mid-container {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    .provider-card-row {
      display: flex;
      align-items: center;
      margin-top: 4px;

      .cost-info {
        width: 150px;
      }

      .provider-card-phone {
        flex: 1;
      }
    }

    .provider-name-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      @media screen and (max-width: $breakpoint-xs) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      &.col-flex {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      &.row-flex {
        flex-direction: row !important;
        justify-content: space-between !important;
      }
    }

    .fcr-address-line {
      // for details unavailable text
      line-height: 1.45rem;
    }

    .fcr-spfi-disclaimer {
      color: #666666;
      font-weight: 700;
      font-size: 12px;
      font-family: Lato;
      line-height: 15px;
    }
    .provider-label-wrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;
      height: 18px;
      overflow: visible;
    }

    .provider-hours-wrapper {
      width: 200px;
      text-align: right;
      @media screen and (max-width: $breakpoint-xs) {
        text-align: left;
        width: 100%;
      }
      &.mobile-view {
        text-align: left;
        width: 100%;
      }
      > span {
        font-size: 12px !important;
      }
      .hours-label {
        font-weight: 700;
        padding-right: 5px;
      }
    }
    .base-wrap {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-top: 4px;
    }

    .member-name {
      color: #286ce2;
      font-size: 16px;
      margin-bottom: 0;
      cursor: pointer;
      font-weight: 700;
    }

    .member-type {
      font-size: 14px;
      color: #333;
      margin-bottom: 4px;
      font-weight: 700;
    }

    .fcr-provider-address {
      padding: 5px 0;
      color: #666;
      font-weight: 400;
    }

    &.greyOut-text {
      opacity: 1;
      .member-name {
        opacity: 0.8;
        color: $ant-motif-dove-dark-gray !important;
        pointer-events: none;
      }
    }

    .phone-and-cost-container {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: $breakpoint-xs) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
      &.col-flex {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    .padding-right-2 {
      padding-right: 2px;
    }

    .phone-info {
      padding-top: 8px;
    }

    .cost-info {
      justify-content: flex-end;
      display: flex;
      align-items: baseline;
    }

    .affliation-label {
      margin-top: 10px;
      color: #666;
      font-weight: 600;
    }

    .affliation-provider-name {
      margin-top: 5px;
      font-weight: 600;
      color: #286ce2;
      margin-bottom: 5px;
    }
  }
  .fcr-online-provider-label {
    font-family: Lato;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-align: center;
    font-weight: 700;
    color: #fff;
    background: #511284 !important;
    border-radius: 2px;
    margin: 0;
    width: max-content;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
  }
  .provider-card-right-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .cost-info {
    align-self: flex-end;
    justify-content: flex-end;

    a {
      font-size: 18px;
    }
  }
  .provider-labels {
    display: flex !important;
    padding: 3px 6px !important;
    background: #511284;
    border: 2px;
    margin-bottom: auto;

    span {
      color: #ffffff !important;
      width: max-content !important;
      font-size: 10px !important;
      font-weight: 700 !important;
    }

    &.fcr-provider-badge--pcp {
      background-color: #027c1d;
    }

    &.fcr-provider-badge--pcp-future {
      background-color: #f2bc35;
    }
  }

  .provider-labels-mobile {
    margin-top: 8px;
    width: max-content;
  }
}

.fcr-online-pharmacy {
  height: 18px;
  margin-top: 3px;
  @media screen and (max-width: $breakpoint-sm) {
    display: flex !important;
  }
  .online-pharmacy-text {
    letter-spacing: 1px;
    text-align: center;
  }
  .online-phramacy-label {
    // text styles
    font-family: Lato;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-align: center;
    font-weight: 700;
    color: #fff;
    // box styles
    background: #511284 !important;
    border-radius: 2px;
    margin: 0;
    width: 123px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1024px) {
      right: 10%;
    }
    @media screen and (max-width: 1200px) {
      right: 10%;
    }
  }

  .popIcon {
    margin-left: 0;
    vertical-align: sub !important;
    display: flex;
    align-items: center;
    padding-right: 0;
    .fa {
      color: #286ce2 !important;
      font-size: 20px !important;
      top: 0;
      height: 18px;
    }
  }
}

.fcr-result-summary-container {
  border-bottom: 1px solid #ccc;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;

  &.fcr-result-actions {
    min-width: 100%; // required for location card view
    justify-content: flex-end;
    margin-top: 0;
    .fcr-action-links {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: stretch;
      gap: 10px;

      > * {
        padding: 0;
        margin: 0;
      }
    }
  }

  .logo {
    height: 25px;
  }
}

.fcr-result-cost-range-container {
  padding-right: 20px;
  margin-top: 20px;

  .fcr-result-cost-range-box {
    padding: 13px 15px 13px 15px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    flex-direction: row;

    .cost-range-label {
      font-size: 16px;
      font-weight: 400;
      padding-right: 5px;
    }

    .cost-range-info {
      font-size: 16px;
      font-weight: 700;
    }
  }
}

//find care result page style ends here
// Provider map styles starts
.fcr-infobox-address-line {
  display: none;
}

.fcr-map-container {
  --map-sticky-offset: 135px;
  height: calc(100vh - var(--map-sticky-offset));
  position: sticky;
  top: var(--map-sticky-offset);
  transition: max-height 0.3s ease-in;
  overflow: hidden;

  .fcr-map-default-view {
    height: 100%;
    min-height: calc(100vh - var(--map-sticky-offset));
  }

  .fcr-map-pushpin-infobox {
    display: flex;
    width: 380px;
    padding: 12px 10px;
    min-height: 90px;
    background-color: #ffffff;
    border: solid 1px rgba(0, 0, 0, 0.1);
    cursor: auto;

    p {
      margin: 0;
      min-height: 18px;
    }

    .infobox-img-wrapper {
      position: relative;
      margin: 0 10px auto 0;
      border-radius: 50%;
      background-color: #ccc;

      img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
      }

      svg {
        position: absolute;
        bottom: 0;
        right: 0;
      }

      .network-status-icon-overlay {
        position: absolute;
        --overlay-size: 17px;
        width: var(--overlay-size);
        height: var(--overlay-size);
        background-color: transparent;
        top: 40px;
        left: 40px;
        &.in-network {
          background-image: url(/assets/images/common/icon-green-circle.svg);
          background-size: var(--overlay-size);
        }
        &.not-in-network {
          background-image: url(/assets/images/common/icon-warning-circle.svg);
          background-size: var(--overlay-size);
        }
      }
    }

    .infobox-text-wrapper {
      position: relative;
      text-align: center;
      width: 100%;
      line-height: 2px;

      .infobox-title {
        font-family: Lato;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        text-align: left;
        color: #1355e9;
        cursor: pointer;
      }

      .infobox-subtitle {
        font-family: Lato;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        text-align: left;
        color: #333333;
      }

      .infobox-address {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        text-align: left;
        .fcr-infobox-address-line {
          display: inline;
        }
        .fcr-address-line {
          &.line-two,
          &.county-name {
            display: none;
          }
        }
      }
    }

    @media screen and (max-width: $breakpoint-md) {
      position: fixed;
      width: auto;
      bottom: 14px;
      left: 16px;
      right: 16px;
      padding: 16px;
      border-radius: 8px;
    }
  }

  .fcr-map-cluster-infobox {
    overflow: hidden;
    border-radius: 8px;

    .fcr-providers-at-location {
      padding: 16px;
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      text-align: left;
      background-color: #f7f7f7;
      display: flex;
      justify-content: space-between;

      .fcr-map-cluster-infobox-close {
        width: 22px;
        height: 22px;
        background: none;
        border: none;
      }

      @media screen and (max-width: $breakpoint-md) {
        display: none;
      }
    }

    .fcr-cluster-infobox-content {
      min-width: 380px;
      max-height: 270px;
      overflow-y: scroll;

      @media screen and (max-width: $breakpoint-md) {
        display: flex;
        gap: 15px;
        padding: 0 16px;
      }
    }

    @media screen and (max-width: $breakpoint-md) {
      position: fixed;
      bottom: 14px;
      left: 0;
      right: 0;
      overflow: scroll;

      .fcr-map-pushpin-infobox {
        position: relative;
        width: 280px;
        height: 100%;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  .btn-fcr-map-search-area {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    width: 232px;
    height: 45px;
    background-color: #fff;
    border: none;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow:
      0px 4px 13px rgba(0, 0, 0, 0.25),
      0px 0px 3px rgba(0, 0, 0, 0.17);
    font-size: 14px;
    font-weight: 600;
    color: #286ce2;
    line-height: 22px;
    text-align: center;

    .motif-icon {
      font-size: 24px !important;
      transform: rotate(-90deg);
      margin-right: 10px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      top: 12px;
      bottom: unset;
    }
  }

  .fcr-map-navigation-bar {
    position: absolute;
    bottom: 120px;
    right: 32px;
    z-index: 10;
    width: 58px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: space-between;

    .btn-fcr-map-recenter {
      width: 48px;
      height: 48px;
      border: none;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
    }

    .fcr-map-zoom-wrapper {
      width: 40px;
      height: 88px;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      box-shadow:
        0px 6px 12px rgba(0, 0, 0, 0.08),
        0px 10px 20px rgba(0, 0, 0, 0.2);

      .btn-fcr-map-zoom {
        border: none;
        background-color: #fff;
        flex: 1;
        line-height: 0px;
        text-align: center;
      }

      .fcr-zoom-btn-divider {
        border: solid 0.5px #949494;
      }

      @media screen and (max-width: $breakpoint-sm) {
        display: none;
      }
    }

    .btn-fcr-map-chat {
      width: 58px;
      height: 58px;
      border: none;
      background-color: #ffffff;
      border-radius: 50%;
      box-shadow:
        0px 6px 12px rgba(0, 0, 0, 0.8),
        0px 10px 20px rgba(0, 0, 0, 0.2);
    }

    @media screen and (max-width: $breakpoint-sm) {
      display: none;
    }
  }
}

.fcr-map-infobox-placeholder {
  display: none;
}

// Provider map styles ends

// Find Care landing page styles starts
.change-language-wrapper {
  text-align: right;
  margin: 20px auto 0;
  .row {
    padding: 0 15px;
    > * {
      margin-left: 15px;
    }
  }
}
.fcr-page-main-content {
  margin: 30px auto 96px;

  .fcr-landing-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 48px;

    .fcr-landing-content-secondary {
      display: flex;
      width: 100%;
      gap: 24px;

      .fcr-column {
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
        &.more-options-container {
          flex-basis: 100%;
          &.fcr-secure-card {
            flex-basis: 50%;
          }
        }

        @media only screen and (max-width: $breakpoint-sm) {
          flex-basis: 100%;
          &.more-options-container {
            &.fcr-secure-card {
              flex-basis: 100%;
            }
          }
        }
      }

      @media only screen and (max-width: $breakpoint-sm) {
        flex-wrap: wrap;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-sm) {
    margin: 48px auto 64px;
  }
}

.fcr-page-alert-content {
  margin: 20px auto 0;
}

// Find Care landing page styles ends

// Quick Links styles starts
.fcr-search-qlink-wrapper {
  .fcr-motif-info {
    font-size: 16px;
  }

  .fcr-search-qlink-title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .fcr-qlink-title-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 16px;

      .fcr-search-qlink-title {
        font-family: Lato;
        font-size: 18px !important;
        font-weight: 400;
        line-height: 1.2;
        color: #333333;
        margin-bottom: 0;
      }

      .fcr-pop-icon {
        display: flex;
        align-self: center;
        text-decoration: none;
        margin-left: 8px;

        &:focus {
          outline: none;
        }

        &:focus-visible {
          outline-offset: unset;
          outline: none;
        }

        .fcr-motif-info {
          &:focus {
            outline: none;
          }

          &:focus-visible {
            outline: none;
          }
        }
      }
    }

    .fcr-qlink-viewmore {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 1.2;
      text-decoration: none;
      margin-bottom: 16px;

      .fcr-motif-info {
        color: #949494;
        font-size: 22px !important;
        margin-right: 8px;
      }

      .fcr-learn-more {
        font-family: Lato;
        font-size: 14px;
        font-weight: 700;
        color: #286ce2;
      }
    }
  }

  .fcr-list-of-iconCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 24px;

    @media only screen and (max-width: $breakpoint-xs) {
      gap: 20px;
    }

    .fcr-qlink-iconCard {
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08);
      border: 1px solid #cccccc;
      background-color: #ffffff;
      padding: 24px 8px;
      flex: 1 1 calc(14.28% - 24px);
      text-align: center;
      max-width: calc(20% - 14px);
      min-width: 120px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: $breakpoint-lg) {
        min-width: 140px;
      }

      @media screen and (max-width: $breakpoint-md) {
        min-width: 162px;
      }

      @media screen and (max-width: $breakpoint-sm) {
        min-width: 170px;
      }

      @media only screen and (max-width: $breakpoint-xs) {
        padding: 26px 40px;
        min-width: 40%;
        max-width: 47%;
      }

      a.fcr-qlink-icon-section:focus {
        outline: unset;
      }

      .fcr-qlink-icon-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 76px;
        min-width: 106px;
        text-decoration: none;
        cursor: pointer;

        @media only screen and (max-width: $breakpoint-xs) {
          min-width: 98px;
        }

        .motif-icon {
          color: #286ce2;
          font-size: 42px !important;
          margin-bottom: 16px;
        }

        .fcr-iconCardLabel {
          font-weight: 700;
          line-height: 1.2;
          text-align: center;
          color: #286ce2;

          @media only screen and (max-width: $breakpoint-xs) {
            line-height: 1.6;
          }
        }
      }

      .action-item {
        display: flex;
        align-items: flex-start;
        height: 100%;
        position: absolute;
        right: 30px;
        top: 20px;

        @media only screen and (max-width: $breakpoint-xs) {
          right: 24px;
          top: 16px;
        }

        .fcr-action-icon {
          padding: 0;
          margin: 0;
          border: 0;
          background: none;

          &::before {
            font-size: 18px;
            color: #949494;
            display: flex;
            width: 4px;

            @media only screen and (max-width: $breakpoint-xs) {
              width: 0;
            }
          }
        }

        .action-item-feedback-menu {
          margin-bottom: 10px;
          box-shadow: 0px 10px 21px -5px rgba(120, 119, 119, 0.368627451);
          border: 2px solid #eeeeee;
          border-radius: 8px;
          right: -78px;
          top: 9px;

          @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
            right: -36px;
          }

          @media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
            right: -24px;
          }

          @media only screen and (max-width: $breakpoint-xs) {
            right: -38px;
          }

          li {
            .motif-icon {
              font-size: 24px !important;
              color: #2a67d1 !important;
              padding: 24px 20px 18px !important;
              min-width: 268px;
              text-decoration: none;

              @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
                min-width: 254px;
              }

              @media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
                min-width: 246px;
              }

              @media only screen and (max-width: $breakpoint-xs) {
                padding: 14px 12px !important;
                min-width: 188px;
              }

              &:focus {
                outline: none;
              }

              &:focus-visible {
                outline-offset: unset;
                outline: none;
              }

              &::before {
                margin: 0 15px 0 0;
              }

              .item-font {
                font-size: 19px;
                line-height: 1.2;
              }

              &:hover {
                background-color: #2a67d1;
                color: #fff !important;
              }
            }
          }
        }
      }
    }
  }
}

.fcr-qlink-procedure {
  margin-top: 48px;
}

//  Quick Links styles ends

// More options component starts
.fcr-more-options-cmp {
  .fcr-headingStyle {
    margin-bottom: 16px;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 1.6;
    font-family: "Lato", sans-serif;
  }

  .fcr-iconCards {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0px;

    .fcr-listClass {
      padding: 28px 24px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      background-color: #ffffff;
      border: solid 1px #cccccc;
      margin-bottom: 16px;

      @media screen and (max-width: $breakpoint-sm) {
        padding: 16px;
      }

      ul {
        li {
          .fcr-cardHeading {
            font-size: 18px !important;
            font-weight: 400;
            line-height: 22px;
            margin-bottom: 4px;
          }

          .fcr-mr-24 {
            margin-right: 24px;
          }

          .fcr-cardheadingDiv {
            padding: 0px;

            &:last-child {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .fcr-iconCards-wrapper {
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: row;
    .fcr-listClass {
      flex: 1 1 calc(50% - 10px);
      max-width: calc(50% - 10px);
      margin-bottom: 0px;
      @media screen and (max-width: $breakpoint-sm) {
        flex: 1 1 calc(100% - 10px);
        max-width: calc(100% - 10px);
      }
    }
  }

  .fcr-card-style {
    @media screen and (max-width: $breakpoint-lg) {
      display: block;
      margin: 0;
      height: 92%;
    }

    @media screen and (max-width: $breakpoint-md) {
      height: 100%;
    }

    .fcr-listClass {
      @media screen and (max-width: $breakpoint-lg) {
        margin: 0;
        height: 100%;
        display: flex;
        align-items: center;
      }

      @media screen and (max-width: $breakpoint-md) {
        height: 100%;
      }
    }
  }

  ul.fcr-actionItemList.infoBox.pf2-mar-rt-10 {
    margin-right: 10px;
  }

  ul.fcr-actionItemList li.pf2-ptb-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  ul.fcr-actionItemList {
    margin: 0;
    margin-left: -2rem; // offset card padding to allow full width dividers
    margin-right: -2rem;
    padding: 0;
    list-style: none;
    text-align: left;

    .fcr-cardDesc,
    li {
      border-bottom: 1px solid #ccc;
      padding: 1rem 2rem;
      position: relative;

      &:last-child {
        border-bottom: none;
      }

      &:first-child {
        padding-top: 0;
      }

      .fcr-imgView {
        display: flex;
        align-items: center;

        .motif-icon {
          font-size: 70px !important;
          width: 70px;
          margin-right: 24px;
        }
      }
    }

    .infoBox {
      min-height: 70px;
      display: flex;

      .fcr-cardDesc {
        padding: 0px;
        margin: 0px;
        line-height: 22px;
      }
    }

    .pf2-sub-link {
      @media (max-width: $breakpoint-sm) {
        float: right;
        margin-top: 0;
      }
    }
  }
}

// More options component ends

// Last updated component styles starts
.fcr-last-updated-cmp {
  font-weight: 400;

  .fcr-search-footer-updated {
    font-size: 0.8571rem;
  }

  .fcr-margin-top-20 {
    margin-top: 20px;
  }
}

// Last updated component styles ends

// Standalone App style overrides for member selection modal
.modal-open {
  &:has(.fcr-member-selection-modal) {
    overflow: auto;
  }
}

//FindCare header css starts
.fcr-search-header-cmp {
  .care-plan-wrapper {
    height: 50px;
  }

  background-image: url(/assets/images/common/fcr-page-header-background.svg), linear-gradient(347deg, #58abdf, #0079c2);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  .fcr-tooltip-info-content {
    text-align: left !important;
    display: block;
  }

  .fcr-display-flex {
    display: flex;

    &.fcr-justify-content-space-btw {
      justify-content: space-between;
    }

    .flex-col {
      flex-direction: column !important;
    }

    .flex-row {
      flex-direction: row !important;
    }
  }

  @media only screen and (max-width: $breakpoint-xs) {
    background: #286ce2;
    top: 81px;
    gap: 24px;
  }

  //Findcare common style start

  .fcr-font-lato {
    font-family: "Lato", sans-serif !important;
  }

  .fcr-font-montserrat {
    font-family: "Montserrat", sans-serif !important;
  }

  .fcr-font-bold {
    font-weight: 700;
  }

  /* .fcr-header1-text {
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 0;

    @media only screen and (max-width: $breakpoint-xs) {
      font-size: 22px;
      line-height: 26px;
    }
  } */

  .fcr-for-content {
    font-family: "Lato", sans-serif !important;
    font-size: 32px !important;
    font-weight: 300 !important;
  }

  .fcr-normal-text {
    font-size: 14px;
    line-height: 22px;
  }

  .fcr-page-container {
    margin: 64px auto 96px;

    .fcr-landing-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 48px;

      .fcr-landing-content-secondary {
        display: flex;
        width: 100%;
        gap: 24px;

        .fcr-column {
          flex-basis: 50%;
          &.more-options-container {
            flex-basis: 100%;
            &.fcr-secure-card {
              flex-basis: 50%;
            }
          }

          @media only screen and (max-width: $breakpoint-sm) {
            flex-basis: 100%;
          }
        }

        @media only screen and (max-width: $breakpoint-sm) {
          flex-wrap: wrap;
        }
      }
    }

    @media only screen and (max-width: $breakpoint-sm) {
      margin: 48px auto 64px;
    }
  }

  .fcr-page-header-wrapper {
    display: flex;
    margin-top: 0;
    padding-top: 8px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-sm) {
      padding-top: 8px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      padding-top: 0;
    }
  }

  .fcr-page-header-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #ffffff;

    .fcr-find-care-text {
      font-family: "Montserrat", sans-serif !important;
      font-size: 32px !important;
      line-height: 39px;
      margin-right: 8px;
      flex-shrink: 0;

      @media only screen and (max-width: 601px) {
        font-size: 22px !important;
        line-height: 26px;
      }
    }
  }

  .fcr-header-content {
    font-weight: 700;

    @media screen and (min-width: 600px) and (max-width: 768px) {
      padding-top: 16px;
    }

    .fcr-single-member-name {
      font-size: 32px !important;
      margin-left: 5px;
      margin-top: 0px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      //width: 100%;
    }

    .header-lang-links {
      > a {
        color: white;
        text-decoration: none;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
        margin-left: 10px;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .en-es-toggle-link {
      &::before {
        content: " ";
        background: url(/assets/images/common/right-arrow2.svg) left top;
        width: 22px;
        height: 22px;
        margin-right: 6px;
      }
    }
  }

  .fcr-header-content-spacing {
    @media screen and (max-width: 600px) {
      margin-top: 24px;

      &.first-content {
        margin-top: 20px;
      }

      &.last-content {
        margin-bottom: 20px;
      }
    }

    @media screen and (min-width: 601px) {
      margin-top: 16px;

      &.first-content {
        margin-top: 24px;
      }

      &.last-content {
        margin-bottom: 22px;
      }
    }
  }

  .fcr-single-member-name {
    font-family: "Montserrat", sans-serif !important;
    font-size: 32px !important;
    font-weight: 700;
    margin-top: 5px;
    @media screen and (max-width: 601px) {
      font-size: 22px !important;
      margin-top: 0;
    }
  }

  .fcr-header-content {
    font-weight: 700;

    @media screen and (min-width: 600px) and (max-width: 768px) {
      padding-top: 4px;
    }
  }

  .fcr-row {
    display: flex;
    flex-direction: row;
  }
  .fcr-justify-end {
    justify-content: flex-end;
  }
  .fcr-align-center {
    align-items: center;
  }

  .fcr-align-stretch {
    align-items: stretch;
  }

  .fcr-justify-content-end {
    justify-content: flex-end;
  }

  .margin-t10 {
    margin-top: 10px;
  }

  .fcr-start-over {
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    cursor: pointer;

    span {
      padding-left: 8px;
    }
  }
  --fcr-responsive-page-width: 100%;

  .desktop-only {
    .fcr-header-mem-chips {
      flex-direction: row;
      align-items: center;
      &.single-member {
        .fcr-mem-chips-cmp {
          margin-top: 2px;
          span {
            //max-width: 375px;
            flex: 1 0 auto;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 600px;
          }
        }
        &.col-wise {
          // for spanish ui fix
          flex-direction: column;
          align-items: flex-start;
        }
      }
      &.multiple-members {
        .fcr-mem-chips-cmp {
          margin-left: 8px;
          gap: 9px;
          &.clear-left {
            margin-left: 0 !important;
          }
        }
        &.col-wise {
          // for spanish ui fix
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .fcr-mob-care-plan-wrapper {
        display: none;
      }
    }
    @media screen and (min-width: 801px) {
      width: $container-sm;
    }

    @media screen and (min-width: 1025px) {
      width: $container-md;
    }

    @media screen and (min-width: 1201px) {
      width: $container-lg;
    }
  }

  .mobile-only {
    .fcr-mem-chips-cmp {
      gap: 8px;
    }
  }

  @media only screen and (min-width: 601px) {
    .mobile-only .fcr-mob-care-plan-wrapper {
      display: none;
    }

    .search-bar-container .location-wrapper {
      flex: 1 0 25% !important;
    }

    .fcr-search-bar-container {
      display: flex;

      .fcr-search-wrapper {
        flex: 0 1 70%;
      }
      .fcr-integrated-search-cmp {
        flex: 1 1 70%;
      }
      .fcr-free-text-search-cmp {
        flex: 1 1 70%;
      }

      .care-plan-wrapper {
        cursor: pointer;
        max-width: 35%;
        flex: 1 1 30%;
        padding: 0;
        margin: 0;
        background-color: white;
        //border: 1px solid rgb(148, 148, 148);
      }
    }

    .search-bar-container .search-term-wrapper {
      .fcr-search-icon {
        input.fcr-search-input {
          border: none;
          margin-top: 8px;
          padding-left: 8px;
        }
      }

      .fcr-clear-text-search {
        display: none;
      }
      .fcr-fts-clear-text-search {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 0.25rem !important;
        color: #286ce2;
        border: 0;
        background: #ffffff;
        .motif-icon {
          font-size: 24px !important;
        }
      }

      .look-up-container {
        width: var(adjustedWidth);
        margin-top: 15px;
        border-radius: 10px;

        .look-up-item-header {
          .fcr-align-lookup-header {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
          }
        }

        .fcr-assistanceHelpText {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
          color: #333333;

          .fcr-fts-example-link {
            img {
              margin-left: 134px;
            }

            a.fts-more-example {
              float: right;
              position: relative;
              margin-left: 8px;
            }
          }

          .row .col-sm-3 {
            display: flex;
            justify-content: flex-end;

            a {
              margin-left: 8px;
            }
          }
        }
      }

      .fcr-search-btn-container {
        outline: none !important;
        outline-width: 0 !important;
        border-left: none;
        padding: 0 8px !important;
        justify-content: center;
        align-content: center;

        .fcr-search-btn {
          background-color: #286ce2;
          border: 1px solid #949494;
          width: 60px;
          border-radius: 4px;
          // opacity: 30%;
          height: 31px;
          font-size: 12px;
          color: #ffffff;
        }
      }
    }

    .search-bar-container .care-plan-wrapper {
      flex: 1 0 2% !important;

      .fcr-care-plan-box {
        width: 100%;

        .fcr-change-plan-content {
          flex-direction: column;

          &.fcr-care-plan-def-height {
            height: 50px;

            &:hover {
              height: auto;
              background-color: white;
              border: 1px solid #949494;
            }
          }

          .pf-justify-content-between {
            margin-top: 0;
            flex-direction: column;
            margin-left: 48px;

            h2.lbl-finding-care-1 span {
              font-family: Lato;
              font-size: 14px !important;
              font-weight: 400;
              line-height: 22px;
              text-align: left;
            }
          }
        }
      }
    }
  }

  .search-bar-container {
    .look-up-container {
      .look-up-item-header {
        .fcr-lookup-header-txt {
          font-weight: 700;
          font-size: 16px;
          line-height: 19.2px;
          font-family: "lato";
          color: #333333;
        }

        .show-more-label {
          justify-content: flex-start;

          img {
            cursor: pointer;
          }

          a.result-link {
            margin-right: 8px;
          }
        }

        .col-sm-3.show-more-label {
          padding: 0px;
          justify-content: flex-end;
          padding-right: 16px !important;
        }
      }

      .look-up-item-border {
        span.look-up-sub {
          color: #333333;
        }

        .address-container {
          color: #333333;
          line-height: 22px;
          font-weight: 400;
        }

        .fcr-npi-name-location-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .fcr-npi-location-container {
          display: flex;
          color: #333333;
        }
        .fcr-npi-distance-text {
          padding-left: 5px;
        }

        .row .distance-wrapper {
          padding-top: unset;
          justify-content: flex-end;

          .summary-dist-text {
            font-weight: 700;
            line-height: 22px;
            margin-left: 4px;
          }
        }
      }

      .look-up-item-more {
        height: unset;
        overflow-y: hidden;
      }

      .fts-look-up-item {
        height: unset;
        overflow-y: hidden;

        @media screen and (max-width: $breakpoint-xs) {
          max-height: unset;
        }

        .look-up-item {
          font-family: Lato;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
          color: #333333;
        }
      }
    }

    .fcr-classic-search {
      background-color: white;
      border: 1px solid rgb(148, 148, 148);
      flex: 1 1 auto;
      position: relative;

      .fcr-look-up-container-cmp {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 125;
      }
      .fcr-look-up {
        .fcr-align-lookup-header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .fcr-look-up-scroll {
        height: auto;
        max-height: 480px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 0 !important;
        margin-top: 10px;

        @media screen and (max-width: 600px) {
          max-height: 340px;
          margin-top: 6px;
        }
      }

      @media screen and (max-width: 600px) {
        height: 50px;
      }

      @media screen and (min-width: 601px) and (max-width: 800px) {
        flex: 1 1 calc(60% - 10px);
      }
    }

    .fcr-dynamic-care-plan {
      @media screen and (max-width: 600px) {
        margin-top: 23px;
        flex: 1 1 40%;
      }

      .fcr-mob-location-wrapper {
        width: 50%;
        flex: 0 1 auto;
        min-width: 158px;

        @media screen and (min-width: 1025px) {
          width: 200px;
        }

        @media screen and (min-width: 801px) and (max-width: 1024px) {
          width: 164px;
        }

        @media screen and (min-width: 601px) and (max-width: 800px) {
          width: 158px;
        }
      }

      @media screen and (max-width: 600px) {
        .fcr-mob-care-plan-wrapper {
          flex: 1 0 auto;
          background-color: white;
          height: 50px;
          width: 50%;

          .fcr-care-plan-box {
            width: 100%;

            .fcr-change-plan-content {
              &.fcr-care-plan-def-height {
                height: 50px;
              }

              &.fcr-care-plan-hov-height {
                height: 80px;
                background: #ffffff;
                border: 1px solid #949494;
              }
            }
          }
        }
      }

      @media screen and (min-width: 601px) {
        .fcr-mob-care-plan-wrapper {
          display: none;
        }
      }
    }

    .fcr-search-icon {
      margin: auto 10px;
    }

    .search-input {
      padding: 0;
      padding-right: 5px;
      width: auto;
      max-width: calc(98% - 32px);
      flex: 1 0 auto;
      font-size: 14px;
      color: #666666;
      border: none;
      font-weight: 400;
    }

    .search-input:focus {
      box-shadow: none !important;
      outline: none !important;
    }

    .search-input::placeholder {
      color: #666666;
      font-family: "Lato";
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      padding: unset;
    }

    .search-term-wrapper {
      flex: 1 0 auto;

      .fcr-clear-text-search {
        display: none;
      }
      .fcr-fts-clear-text-search {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 0.25rem;
        color: #286ce2;
        border: 0;
        background: #ffffff;
        .motif-icon {
          font-size: 24px !important;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .desktop-only {
      display: none;
    }

    .mobile-only {
      display: flex;

      &.fcr-margin-top-mobile {
        app-fc-member-chips-cmp.fcr-margin-top {
          margin-top: 24px;
        }
      }
    }

    .fcr-fts-plan-wrapper {
      .fcr-care-plan-box {
        border-width: 1px, 1px, 1px, 0px;
        border-style: solid;
        border-color: #cccccc;
        background: #ffffff;
        margin-top: 24px;
        display: grid;
        justify-content: center;
      }
    }

    .fcr-change-plan-content {
      .pf-justify-content-between {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        margin-left: 45px;
      }

      .care-side-link {
        max-width: calc(100% - 45px);
      }
    }

    .fcr-for-content {
      font-size: 22px !important;
      font-weight: 300;
      line-height: 26.4px;
    }

    .fcr-selected-member {
      font-family: "Lato";
      font-size: 16px;
      font-weight: 400;
      line-height: 19.2px;
    }
  }

  .fcr-change-plan {
    color: #333333;
    width: 100%;
    height: 100%;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    box-sizing: border-box;
  }

  .fcr-change-plan-cmp {
    /* align-items: stretch;
    display: flex;
    align-self: stretch;
    width: 100%;
    background-color: white; */

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: auto;
    min-height: 50px;
    padding-left: 45px;
    background-color: white;
    height: max-content;
    border: 1px solid rgb(148, 148, 148);

    .pf-care-team-container {
      width: 100%;
      padding: 2px;
    }
  }

  .fcr-plan-icon {
    margin: auto 10px;
    position: absolute;
    z-index: 1;
  }

  .fcr-location-icon {
    margin: auto 10px;

    .fcr-location-pin {
      height: 26px;
      width: 26px;
    }
  }

  .fcr-ht50 {
    height: 50%;
  }

  .fcr-w-100 {
    width: 100%;
  }

  .fcr-justify-space-around {
    justify-content: space-around;
  }

  .fcr-search-bar-container {
    .fcr-care-plan-box {
      width: 100%;
      position: relative;
    }

    .fcr-integrated-search-cmp {
      flex: 1 1 100%;
    }
    .fcr-free-text-search-cmp {
      flex: 1 1 100%;
      .search-input-container {
        height: 100%;
      }
    }

    .btn-update-location {
      height: 50px;
      background-color: #2453a6;
      color: #fff;
      border: none;
      cursor: pointer;
      font-size: 1rem;
      padding: 0;
      padding-right: 10px;

      &.no-padding {
        padding: 0;
      }

      .fcr-location-wrapper {
        padding: 4px 0;
        > * {
          height: 50%;
          margin: auto 0;
        }
      }

      .fcr-search-location-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @media screen and (max-width: $breakpoint-xs) {
          max-width: 100px;
        }
      }

      .fcr-update-location-label {
        white-space: nowrap;
      }

      @media screen and (max-width: $breakpoint-xs) {
        padding: unset;
      }
    }
  }

  .fcr-input {
    outline: none !important;
    outline-width: 0 !important;
  }

  .fcr-input::placeholder {
    color: #ffffff;
    font-family: Lato;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }

  .fcr-search-input {
    outline: none !important;
    outline-width: 0 !important;
    flex: 80%;
    border-left: none;
    padding: 0 8px !important;
    border-right: none;
  }

  .search-bar-container .fcr-search-input:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  .fcr-search-input::placeholder {
    color: #666666;
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  #selid {
    outline: none !important;
    outline-width: 0 !important;
  }

  .fcr-change-plan-content {
    //height: 48px;
  }

  .fcr-single-plan {
    font-family: "Lato";
    font-size: 14px !important;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: auto 0;
    color: #286ce2;
    padding-right: 5px;
    max-width: 275px;
    /* background-color: white;
    width: 100%; */

    @media screen and (max-width: $breakpoint-md) {
      max-width: 166px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      max-width: 178px;
    }

    @media screen and (max-width: 600px) {
      max-width: 172px;
    }
  }

  .fcr-single-plan:hover {
    white-space: pre-wrap;
    overflow: visible;
  }

  .fcr-open-panel-link {
    text-decoration: none;
    cursor: pointer;
    width: max-content;
    font-weight: 600;
    margin: auto 0;
    padding-right: 5px;
    padding-top: 0px;
    background-color: white;
    width: 100%;
  }

  .fcr-flex {
    display: flex;
    justify-content: center;
  }

  .fcr-trybeta-btn {
    display: flex;
    align-items: center;
    margin: auto 0;
    justify-content: center;
    height: 30px;
    width: 165px;
    gap: 10px;
    border: 1px solid #ffffff;
    background-color: transparent;
    &.mt-10 {
      margin-top: 10px;
    }

    & svg.fcr-try-beta-icon {
      float: left;
      margin-left: 20px;
    }

    > span {
      font-size: 12px !important;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: auto 0;
      padding-right: 5px;
      max-width: 125px;
    }
  }

  .fcr-multiple-member {
    flex: 1 1 auto;
    height: 30px;
    gap: 6px;
    border: 1px solid #ffffff;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;
    position: relative;

    &.first-button {
      font-family: "Lato";
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      color: #333333;
      background: #ffffff;
    }

    ~ .fcr-tooltip-info-content {
      top: 40%;
      transform: translate(-30%);
      font-family: Lato, sans-serif;
      font-size: 14px !important;
      color: #666666;
      text-align: center;
      position: absolute;
      z-index: 1;
      border: 1px solid #286ce2;
      background-color: #ffffff;
      width: 240px;
      height: 134px;
      word-wrap: break-word;
      line-height: 22px;
      padding: 24px 24px 22px;
      font-weight: 400;
    }

    .fcr-multiple-member-span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 70px;
      margin: auto 0;
      font-weight: normal;
      font-size: 12px !important;
    }
  }

  .fcr-multiple-member:disabled {
    .fcr-tooltip-container:hover ~ .fcr-tooltip-content {
      visibility: hidden;
    }
  }

  .fcr-view-multiple-member {
    border: 0;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    padding: 0;
    background-color: transparent;

    .fcr-view-link {
      margin-left: 8px;
      color: #ffffff;
      text-decoration: none;
      font-size: 14px;
      font-weight: 700;
    }

    .motif-right-round {
      font-size: 22px !important;
      color: #ffffff;
      margin-left: 0;
    }
  }

  .fcr-multiple-member.desktop-view-only {
    display: inline-block;

    @media screen and (max-width: $breakpoint-xs) {
      display: none;
    }
  }

  .fcr-multiple-member.mobile-view-only {
    display: none;

    @media screen and (max-width: $breakpoint-xs) {
      display: inline-block;
    }
  }

  .fcr-member-chip-btn {
    position: relative;
    min-width: 80px;
    justify-content: center;
  }

  .fcr-tooltip-container {
    position: relative;
    /* Establishes a positioning context for the tooltip */
    display: inline-block;
    /* Makes the container fit its content */
  }

  .fcr-tooltip-content {
    bottom: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-family: "Lato";
    font-size: 12px !important;
    visibility: hidden;
    color: #333333;
    width: 80px;
    height: 35px;
    text-align: center;
    position: absolute;
    padding: 4px 0;
    z-index: 501;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(/assets/images/common/tooltip-frame.svg);
  }

  .fcr-tooltip-content::after {
    // content: ""; /* Creates an empty content box */
    position: absolute;
    /* Positions the arrow relative to the tooltip */
    top: 100%;
    /* Positions the arrow at the bottom of the tooltip */
    left: 50%;
    /* Centers the arrow horizontally */
    margin-left: -5px;
    /* Adjusts horizontal alignment of the arrow */
    border-width: 5px;
    /* Size of the arrow */
    border-style: solid;
    /* Solid color for the arrow */
    border-color: #333 transparent transparent transparent;
    /* Colors the arrow */
  }

  .fcr-tooltip-container:hover ~ .fcr-tooltip-content {
    visibility: visible;
    /* Shows the tooltip on hover */
    opacity: 1;
    /* Makes the tooltip fully visible */
  }

  .fcr-circle-button {
    margin-top: 24px;
    margin-left: 10px;
    width: 26px;
    /* Adjust the width and height as needed */
    height: 26px;
    background-color: #3498db;
    /* Button color */
    border: 2px solid #ffffff;
    border-radius: 50%;
    /* Makes the button circular */
    color: white;
    /* Text color */
    font-size: 20px;
    /* Text size */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    outline: none;
    /* Removes outline on focus */
    // transition: background-color 0.3s ease; /* Smooth color transition */
  }

  #mobButton:active {
    color: #333333;
    background: #ffffff;
    // transition: background-color 0.3s ease;
  }

  #mobButton:last-child {
    color: transparent;
  }

  #mobButton:last-child::after {
    content: "...";
    /* New content to replace the original text */
    color: #ffffff;
    /* Color of the new content */
    font-size: 20px;
    /* Adjust font size if needed */
    position: absolute;
    /* Position the content absolutely */
    top: 25%;
    /* Center the text vertically */
    left: 50%;
    /* Center the text horizontally */
    transform: translate(-50%, -50%);
    /* Center the text */
    white-space: nowrap;
  }

  @media only screen and (max-width: 600px) {
    .fcr-search-bar-container {
      .fcr-search-wrapper {
        width: 100%;
      }
    }

    .search-bar-container {
      flex-wrap: wrap;
      box-shadow: none;
      display: flex;
      flex-direction: column-reverse;
      align-items: stretch;

      app-fc-look-up-container-cmp .fcr-look-up.fly-out.look-up-container {
        .look-up-item-header {
          .fcr-align-lookup-header {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
          }
        }

        .fcr-assistanceHelpText {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
          color: #333333;

          .fcr-fts-example-link {
            margin-top: 10px;

            a.fts-more-example {
              padding-left: 4px;
            }
          }
        }
      }

      .search-zip-container {
        display: block;
      }

      .search-term-wrapper {
        .fcr-search-wrapper {
          border: 1px solid rgb(148, 148, 148);
          width: 100%;
        }
        .fcr-clear-text-search {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 10px !important;
          color: #286ce2;
          border: 0;
          background: #ffffff;
          .motif-icon {
            font-size: 24px !important;
          }
        }
      }
    }

    .fcr-page-header-container {
      .search-term-wrapper {
        .fts-mobile {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          flex-wrap: nowrap;
        }
        .fcr-flex-wrap {
          flex-wrap: wrap;

          .fcr-search-icon {
            + input.fcr-search-input {
              padding-top: 14px !important;
            }
          }
        }
        .fcr-search-btn-container {
          margin-top: 9px;
          height: 31px;
          width: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 9px;

          .fcr-search-btn {
            width: 100%;
            height: 100%;
            background-color: #286ce2;
            border: 1px solid #286ce2;
            border-radius: 4px;
            font-size: 12px;
            color: #ffffff;
          }
        }
      }
    }
  }
}

//FindCare header css ends

//Live chat styles starts here
.fcr-live-chat {
  .ant-alert {
    display: flex;
    align-items: flex-start;
    margin: 0;
    border: none;
    border-left: none;

    .ant-alert-left {
      background-color: transparent;
      padding: 0;

      span.alert-icon.alert-icon-info {
        background: url(/assets/images/common/fcr-Info.svg) no-repeat center center;
        background-size: 24px 24px;
        width: 30px;
        height: 30px;
        margin-top: 0.85714286rem;
        &::before {
          content: "";
          color: #286ce2;
        }
      }

      ~ .ant-alert-main {
        background: transparent !important;
        word-break: break-all;
      }
    }
  }
}
//Live chat styles ends here

// Care teams component styles starts
.fcr-height-100 {
  height: 92%;
}

.fcr-care-team-cmp {
  .fcr-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .fcr-card-heading {
      font-size: 18px !important;
      font-weight: 400;
      line-height: 22px;
    }

    a {
      text-decoration: none;
    }
  }

  .fcr-careTeam-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    background-color: #ffffff;
    border: solid 1px #cccccc;

    .fcr-card-container {
      padding: 16px 24px;
      border-bottom: solid #cccccc 1px;
      display: flex;
      justify-content: space-between;
      gap: 32px;

      @media only screen and (max-width: $breakpoint-sm) {
        gap: 24px;
      }

      .fcr-more-button {
        .action-item {
          display: flex;
          align-items: flex-start;
          height: 100%;
          position: relative;

          .fcr-action-icon {
            padding: 0;
            margin: 0;
            border: 0;
            background: none;

            &::before {
              font-size: 18px;
              color: #949494;
              display: flex;
              height: 32px;
              margin-top: 16px;
              align-items: flex-start;

              @media only screen and (max-width: $breakpoint-xs) {
                width: 0;
              }
            }
          }

          .action-item-menu {
            a {
              text-decoration: none;
            }
          }
        }
      }

      .fcr-provider-name {
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        text-decoration: none;
        cursor: pointer;
      }

      .fcr-phone-num {
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        text-decoration: none;
      }

      .fcr-phone-num {
        color: #2a67d1;
      }

      .fcr-provider-address {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        margin: 10px 0;
        text-decoration: none;
      }

      .fcr-provider-badge {
        color: #ffffff;
        margin-left: 12px;
        font-size: 10px !important;
        font-weight: 700;
        line-height: 12px;
        padding: 3px 6px;
        border-radius: 2px;

        &.fcr-provider-badge--pcp {
          background-color: #108808;
        }

        &.fcr-provider-badge--pcp-future {
          background-color: #f2bc35;
        }

        &.fcr-provider-badge--virtual {
          background-color: #511284;
        }

        @media only screen and (max-width: $breakpoint-sm) {
          // flex-basis: 100%;
          display: flex;
          width: fit-content;
          margin: 12px 0px 18px 0px;
        }
      }

      .action-item {
        position: relative;
        right: 10%;
        top: -10%;

        .more-icon {
          .action-item-button {
            right: -24px;
            padding: 0;
          }

          .action-item-button:after {
            font-size: 21.5px;
          }
        }
      }
    }

    .fcr-careTeam-flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-self: center;
    }

    .fcr-careTeam-flex-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .fcr-ct-text-width {
      display: flex;
      flex-direction: column;
      width: 379px;
      align-items: center;
      justify-content: center;
    }

    .fcr-empty-state {
      padding: 30px 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (max-width: $breakpoint-sm) {
        padding: 32px 24px;
      }

      .fcr-doctor-icon {
        font-size: 64px !important;
        color: #949494;
        margin-bottom: 24px;
      }

      .fcr-ct-text {
        font-size: 18px;
        line-height: 1.2;
        margin: 0;
      }

      .fcr-ct-description,
      .fcr-ct-description-center {
        font-weight: 700;
        line-height: 22px;
        text-decoration: none;
        margin-bottom: 0;
        cursor: pointer;
      }

      .fcr-ct-description-center {
        text-align: center;
      }

      .fcr-search-pcp-btn {
        margin-top: 16px;
        padding: 8px 12px;
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        color: #ffffff;
        background: #286ce2;
        border: 0;
        min-width: 149px;
      }

      .fcr-careTeam-flex-row {
        .fcr-doctor-icon {
          margin: 0;
        }

        .fcr-careTeam-flex {
          align-items: flex-start;
          margin-left: 24px;

          @media screen and (max-width: $breakpoint-sm) {
            margin-left: 0;
            align-items: center;
          }
        }

        .fcr-search-pcp-btn {
          margin-top: -24px;
          min-width: 115px;

          @media screen and (max-width: $breakpoint-sm) {
            margin-top: 24px;
            min-width: 149px;
          }
        }

        .fcr-ct-text {
          padding-right: 45px;

          @media screen and (max-width: $breakpoint-sm) {
            padding-right: 0;
            text-align: center;
          }
        }
      }

      .fcr-careTeam-flex,
      .fcr-careTeam-flex-row {
        @media screen and (max-width: $breakpoint-sm) {
          flex-direction: column;
        }

        .fcr-doctor-icon {
          @media screen and (max-width: $breakpoint-sm) {
            font-size: 55px !important;
            margin-bottom: 30px;
          }
        }

        .fcr-ct-description,
        .fcr-ct-description-center,
        .fcr-ct-text {
          @media screen and (max-width: $breakpoint-sm) {
            text-align: center;
          }
        }
      }
    }

    .fcr-empty-state-padding {
      padding: 20px 24px;
    }
  }

  .fcr-careTeam-card.fcr-height-100 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.fcr-ct-side-panel {
  overflow-y: auto !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  .fcr-close {
    width: 62px;
    height: 60px;
    background: #f7f7f7;
    border: 0;

    @media screen and (max-width: $breakpoint-sm) {
      width: 48px;
      height: 48px;
    }

    &:focus-visible {
      outline: none;
    }

    .fcr-close-icon {
      font-size: 26px !important;
      color: #286ce2;

      @media screen and (max-width: $breakpoint-sm) {
        font-size: 22px !important;
      }
    }
  }

  .fcr-modal-component-body {
    flex: 1;
    padding: 8px 40px 42px 82px;

    @media screen and (max-width: $breakpoint-sm) {
      padding: 25px 16px 42px 25px;
    }

    .fcr-modal-title {
      font-size: 26px;
      font-weight: 300;
      line-height: 1.2;
      margin-bottom: 30px;
      color: #000000;

      @media screen and (max-width: $breakpoint-sm) {
        margin-bottom: 22px;
      }
    }

    .fcr-modal-description {
      line-height: 22px;
      margin-bottom: 30px;

      .bold-title {
        font-weight: 700;
      }

      p {
        padding-right: 42px;
      }

      @media screen and (max-width: $breakpoint-sm) {
        margin-bottom: 22px;
      }
    }

    .fcr-modal-text {
      line-height: 22px;
      list-style: none;

      span {
        display: list-item;
        list-style: disc outside none;
        margin-left: 16px;

        b {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
      }
    }

    .fcr-modal-text-bold {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 30px;
      line-height: 1.2;

      @media screen and (max-width: $breakpoint-sm) {
        margin-bottom: 22px;
      }
    }

    .fcr-ct-iconLink {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      margin-top: 30px;

      .motif-icon {
        font-size: 22px !important;
        color: #666;
        margin-right: 8px;
      }

      .fcr-link-text {
        font-weight: 700;
        line-height: 1.6;
      }
    }
  }

  .fcr-pcp-modal-footer {
    box-shadow: 0px -2px 10px 0px #00000014;
    background: #ffffff;
    width: 100%;

    .fcr-pcp-close-btn {
      float: right;
      padding: 24px 30px;

      @media screen and (max-width: $breakpoint-sm) {
        float: unset;
        padding: 16px 24px;
      }
    }

    .btn.fcr-btn-primary {
      background: #286ce2;
      width: 145px;
      height: 45px;
      color: #ffffff;

      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
      }
    }

    .fcr-remove-cta {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 12px;
      padding: 24px;

      .btn {
        @media screen and (max-width: $breakpoint-xs) {
          width: 153px;
        }

        .fcr-btn-primary {
          @media screen and (max-width: $breakpoint-xs) {
            width: 153px;
          }
        }
      }
    }
  }
}

.fcr-row-item-list {
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  /* Ensures items wrap according to the container */
  gap: 10px;
  /* Optional: Adds space between items */

  a {
    text-decoration: none;
    line-height: 1rem;
  }

  .fcr-row-item {
    display: inline-flex;
    align-items: center;
  }
}

@media print {
  body {
    .fcr-compare-providers {
      visibility: hidden;
    }
  }
  .print-table-container *,
  .print-content-container * {
    visibility: visible;
  }
  .print-table-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: white;
  }
  .print-content-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: white;
  }
  .print-content-container .fcr-provider-rating-container,
  .print-content-container .fcr-provider-rewards-scroll,
  .print-content-container .fcr-provider-telehealth-container {
    visibility: visible !important;
  }
  .print-table-container table {
    width: 100%;
    border-collapse: collapse;
    font-family: Lato;
  }
  .print-table-container th,
  .print-table-container td,
  .print-table-header > tr {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
    img {
      padding-right: 8px;
      vertical-align: text-top;
    }
  }
  .print-table-container thead {
    display: table-header-group !important;
  }
  .print-table-container .fcr-provider-map-data,
  .fcr-table-sticky-footer,
  .fcr-provider-empty-cell-data,
  .fcr-empty-footer-cell {
    display: none !important;
  }
  .fcr-page-wrapper {
    display: none !important;
  }
}

// Compare providers web modal styles ends

// Care teams component styles ends

//Provider Card More Details Tab style starts here

.fcr-card-more-details {
  .fcr-highlighted-details {
    background: #f7f7f7;
  }

  .fcr-highlighted-details,
  .fcr-more-details-cont {
    &.fcr-common {
      padding: 24px 20px;
      border-top: solid 1px #cccccc;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        &.fcr-accredit-status {
          display: flex;
          .highlight-label {
            padding-right: 5px;
          }
        }
        &:last-child {
          padding-bottom: 0px;
        }

        padding-bottom: 16px;

        .highlight-label {
          font-weight: 700;
          padding-right: 5px;
        }

        a {
          color: #286ce2;
          text-transform: capitalize;
          font-weight: 700;
          text-decoration: none;
          margin-left: 0;
          padding-right: 5px;
          position: relative;
          top: 2px;
        }

        .clickables {
          cursor: pointer;
          position: relative;
          top: 0px;
        }

        &.flex-list {
          display: flex;
        }
      }
    }
  }

  .fcr-more-details-accordian {
    padding: 20px;
    border-bottom: solid 1px #cccccc;
    border-top: solid 1px #cccccc;
    justify-content: space-between;

    .fcr-accordian-title {
      font-weight: 700;
      display: flex;
      cursor: pointer;

      .accordian-title {
        flex: 1;
        padding-left: 5px;
        padding-top: 2px;
      }

      .action-btn {
        &.affiliation {
          transform: rotate(270deg);
        }

        &.recognition {
          transform: rotate(270deg);
        }
      }
    }

    .fcr-no-accordian-title {
      font-weight: 700;
      display: flex;

      .no-accordian-title {
        flex: 1;
        padding-left: 5px;
        padding-top: 2px;
      }
    }

    .fcr-no-accordian {
      padding-left: 5px;
      padding-top: 2px;
    }

    &:last-child {
      border-top: none;
    }
  }
}

//Provider Card More Details Tab ends here

//Provider Inner Card common style starts here
.fcr-inner-card {
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;

  .fcr-inner-card-header {
    height: 80px;
    padding: 20px 0px;
    box-shadow: 0px 6px 12px 0px #00000014;
    border-bottom: 1px solid #cccccc;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .back-icon {
      cursor: pointer;
    }

    h5 {
      text-align: center;
      flex: 1;
      font-size: 16px;
      font-weight: 700;
    }

    .close-icon {
      cursor: pointer;

      img {
        height: 24px;
        width: 24px;
      }
    }
  }

  .fcr-inner-card-content {
    padding: 20px;
    overflow-y: auto;
    //height: calc(100% - 180px);
    flex: 1;
    scrollbar-width: none;
    text-align: left;
  }

  .fcr-inner-card-footer {
    height: 100px;
    box-shadow: 0px -6px 12px 0px #00000014;
    border-bottom: 1px solid #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    .fcr-back-btn {
      border: none;
      width: 100%;
      height: 45px;
      background: #286ce2;
      color: #ffffff;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      cursor: pointer;
    }
  }
}

//Provider Inner Card common style ends here

//Provider Overview PCP List Card style starts here

.fcr-pcp-card {
  .fcr-pcp-content {
    ul {
      list-style: none;
      margin: 0;
      padding-left: 0;

      li {
        padding-bottom: 11px;

        .pcp-id {
          font-weight: 700;
        }
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

//Provider Overview PCP List Card style ends here

//Provider Overview PCP speciality Card style starts here

.fcr-speciality-card {
  .fcr-speciality-header {
    padding: 20px 24px;
    background: #f7f7f7;

    h5 {
      text-align: left;
      padding-left: 10px;
    }
  }

  .fcr-speciality-content {
    p {
      padding-top: 20px;
    }

    ul {
      margin: 0;
      padding-left: 25px;

      li {
        padding-bottom: 5px;
      }

      &:last-child {
        padding-bottom: 10px;
      }
    }
  }
}

//Provider Overview PCP speciality Card style ends here

//Provider Overview Recognition Card style starts here

.fcr-recognition-card {
  .fcr-recognition-content {
    padding: 0px;

    .fcr-recognition-card {
      .fcr-recognition-card-header {
        padding: 20px 24px;
        background: #f7f7f7;

        h6 {
          text-align: left;
          font-size: 16px;
          font-weight: 700;
        }
      }

      .fcr-recognition-card-content {
        padding: 20px 24px;
      }
    }
  }

  .fcr-recognition-footer {
    border-top: solid 1px #ccc;
    box-shadow: -6px 12px 0px #00000014;
  }
}

.fcr-sort-items {
  width: 275px;
  border-radius: 0 !important;
  margin-top: 10px !important;
  padding: 20px !important;
  .fcr-title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 16px;
  }
  .providerType {
    display: flex;
    gap: 8px;
    align-items: flex-start;
    margin-bottom: 15px;
    flex-direction: column;
    label {
      margin: 0;
    }
    input[type="radio"] {
      margin-top: 0;
    }
    .personalized-message {
      margin-left: 20px;
    }
  }
  .fcr-sort-buttons {
    .mr-1 {
      margin-right: 10px;
    }
    .btn-small {
      padding: 0.4rem 1.5rem;
    }
  }
  .apply-button {
    background: #286ce2;
  }
  .fcr-sort-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}

//Provider Overview Recognition Card style ends here

//Provider More Details Insurance Card style ends here

.fcr-insurance-card {
  .fcr-insurance-content {
    padding: 10px 0px;

    .insurance-item {
      padding: 10px 24px;
      border-bottom: 1px solid #cccccc;

      h5 {
        font-weight: 700;
        padding-bottom: 10px;
        font-size: 14px;
        text-transform: capitalize;
      }

      ul {
        padding-left: 25px;
        margin-bottom: 0px;

        li {
          padding-bottom: 10px;

          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      hr {
        width: calc(100% + 48px);
        margin-left: -24px;
        height: 1px;
        background-color: #cccccc;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.fcr-page-alert-panel-heading {
  padding: 0 0 2rem 0.15rem;
}

.fcr-result-alert-container {
  padding: 1rem 2rem 0 0.15rem;
}

.fcr-page-alert-icon {
  font-size: 20px !important;
}

.fcr-assign-care-panel {
  .fcr-close-icon {
    color: #286ce2;
  }

  button.fcr-btn-secondary {
    background: #ffffff;
    color: #286ce2;
    border: solid 1px #286ce2;
  }

  button.fcr-btn-primary {
    background: #286ce2;
    color: #ffffff;
    border: none;
  }
}

//Provider More Details Insurance Card style ends here

//Provider More Details Services Card style ends here

.fcr-service-card {
  .fcr-service-content {
    padding: 20px 0px;

    .service-item {
      padding: 0px 24px;
      border-bottom: 1px solid #cccccc;

      ul {
        margin-bottom: 0px;

        li {
          padding-bottom: 10px;

          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.fcr-page-alert-panel-heading {
  padding: 0 0 2rem 0.15rem;
}

.fcr-result-alert-container {
  padding: 1rem 2rem 0 0.15rem;
}

.fcr-page-alert-icon {
  font-size: 20px !important;
}

.fcr-assign-care-panel {
  .fcr-close-icon {
    color: #286ce2;
  }

  button.fcr-btn-secondary {
    background: #ffffff;
    color: #286ce2;
    border: solid 1px #286ce2;
  }

  button.fcr-btn-primary {
    background: #286ce2;
    color: #ffffff;
    border: none;
  }
}

//Provider More Details Services Card style ends here

// Provider affiliation cards styles starts here

.fcr-affiliation-card {
  // height: calc(100vh - 150px);
  .fcr-inner-card-content {
    padding: 0px;

    .affiliation-card {
      h6 {
        background-color: #f7f7f7;
        padding: 18px 24px;
        font-weight: 700;
        color: #333333;
        font-weight: 16px;
      }

      .affiliation-list {
        list-style: none;
        padding-left: 0;

        li {
          padding: 20px 24px;
          display: flex;
          cursor: pointer;
          border-bottom: solid 1px #cccccc;

          &:last-child {
            border-bottom: none;
          }

          .affiliation-left {
            flex: 1;

            h5 {
              background: none;
              font-weight: 700;
              font-size: 16px;
              padding: 0;
            }

            p {
              line-height: 22px;
              margin-bottom: 0;
            }
          }

          .affiliation-right {
            width: 30px;

            img {
              transform: rotate(270deg);
            }
          }
        }
      }
    }
  }

  .fcr-affiliation-footer {
    box-shadow: 0px -6px 12px 0px #00000014;
  }
}

//Provider More Details Affiliation Card style ends here

//Provider More Details License Card style starts here

.fcr-license-card {
  .fcr-license-content {
    padding: 10px 0px;
    &.fcr-features-content {
      height: calc(100% - 180px);
    }
    .license-item {
      padding: 10px 24px;
      border-bottom: 1px solid #cccccc;

      h5 {
        font-weight: 700;
        padding-bottom: 10px;
        font-size: 14px;
        text-transform: capitalize;
      }

      ul {
        padding-left: 25px;
        margin-bottom: 0px;

        li {
          padding-bottom: 10px;

          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

// tooltip custom style starts here

.popover.ant-tooltip-component.top.tooltip-top.in.fade,
.popover.ant-tooltip-component.left.tooltip-left.in.fade,
.popover.ant-tooltip-component.right.tooltip-right.in.fade,
.popover.ant-tooltip-component.bottom.tooltip-bottom.in.fade {
  box-shadow: none;
  border: solid 1px #3a77e4;
}

.popover.top .popover-close,
.popover.top .popover-indicator,
.popover.bottom .popover-close,
.popover.bottom .popover-indicator,
.popover.left .popover-close,
.popover.left .popover-indicator,
.popover.right .popover-close,
.popover.right .popover-indicator {
  display: none;
}

.popover-content {
  max-height: 160px !important;
  overflow: hidden !important;
  overflow-y: auto !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
  &::-webkit-scrollbar {
    display: none;
  }
}

// tooltip custom style ends here
//virtual care only labels
.virtual-connect-container {
  padding: 0px 16px;
  width: max-content;
  border: 1px solid #286ce2;
  margin-top: 8px;
  color: #286ce2;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  span {
    margin: 8px;
  }
}

.fcr-sso-connect-now {
  color: #286ce2;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  padding-right: 8px;
  text-decoration: none;
}

//infolist Alert style starts here
.fcr-infoBox-container {
  padding: 8px;
  padding-bottom: unset !important;
}

.virtual-connect-now {
  .virtual-connect-container {
    background-color: #286ce2;
    color: #fff;
    #connect-now-text {
      position: relative;
      top: -3px;
      margin-right: 0px;
    }
    .fa-external-link {
      &::before {
        font-size: 20px;
      }
    }
  }
}

//infolist Alert style ends here

.search-dropdown-container {
  .toggle-body {
    border: solid 1px;
    width: min-content;
    padding: 8px;
  }
}

//Affiliation filter styles moved to Overlays.scss
// pharmacy card custom style starts here

.fcr-pharmacy-card {
  height: calc(100vh - 150px);
  .fcr-provider-card-header {
    border-bottom: solid 1px #ccc;
    padding-bottom: 20px !important;
    .fcr-provider-card-header-top {
      position: relative;
      top: 5px;
    }
  }
  .fcr-provider-card-content {
    &.hide-footer {
      height: calc(100% - 95px) !important;
    }
    .fcr-provider-card-content-top {
      border-top: none !important;
    }
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin-top: 0px !important;
    height: calc(100% - 164px) !important;
    &::-webkit-scrollbar {
      display: none;
    }
    .uxd-expansion-button {
      align-items: center;
    }
    .acordian-cont {
      font-weight: 400;
      display: inline-block;
      padding-left: 5px;
    }
  }
}

.feature-txt {
  display: inline-block;
  padding-left: 5px !important;
}
.feature-list {
  padding: 0px 0px 0px 21px !important;
  .listed-features {
    display: inline-block;
    padding: 5px 0px;
  }
}

.fcr-pharmacy-item {
  ul {
    list-style: none;
  }
}

.fcr-view-all-features {
  padding-left: 20px;
  .fcr-view-all-features-link {
    font-weight: 600;
    text-decoration: none;
    top: 0px !important;
    display: inline-block;
    text-transform: capitalize;
  }
}

.listed-features::before {
  content: "•";
  display: inline-block;
  margin-right: 5px;
  color: black; // Adjust color as needed
}

.info-section {
  padding: 20px 0px 10px 0px;
  border-bottom: solid 1px #ccc;
  ul {
    list-style: none;
    padding-left: 24px;
    li {
      padding-bottom: 10px;
      display: flex;
      .highlight-label {
        font-weight: 700;
        padding-right: 5px;
      }
    }
  }
}

// pharmacy card custom style ends here

//Provider Location Card style starts here

.fcr-inner-card {
  .fcr-inner-card-header {
    text-align: left;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
    h4 {
      font-family: Lato;
      font-weight: 700;
    }
    p {
      margin-bottom: 0;
      padding-top: 3px;
    }
  }
  .fcr-locations-content {
    padding: 0px;
    height: calc(100% - 100px);
    .locations-card {
      .locations-list {
        list-style: none;
        margin: 0;
        padding-left: 0;
        li {
          &.active {
            .location-top {
              .location-address {
                font-weight: 700;
              }
            }
          }
          display: flex;
          flex-direction: column;
          border-bottom: solid 1px #cccccc;
          padding-bottom: 0px;
          .location-top {
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 20px;
            .location-address {
              display: flex;
              width: 70%;
              margin-bottom: 7px;
            }
          }
          .location-down {
            display: flex;
            justify-content: space-between;
            .location-phone {
              color: #286ce2;
              padding-left: 24px;
            }
            .location-cost {
              padding-right: 24px;
              .cost-text {
                text-transform: capitalize;
              }
              .cost {
                font-size: 18px;
                color: #286ce2;
                font-weight: 700;
                padding-left: 10px;
              }
            }
          }
          &:last-child {
            padding-bottom: 0;
            border-bottom: none;
          }
          &:hover {
            background-color: #f7f7f7;
          }
          &.active {
            background-color: #f7f7f7;
          }
        }
      }
    }
  }
}

//Provider Location Card style ends here

.card-loader {
  .sk-spinner-wave.sk-spinner {
    height: 60px;
    font-size: 14px;
    div {
      background: #dddddd;
    }
  }
}

.provider-lang {
  display: inline-block;
  padding-left: 5px;
}

// FCR styles ends

// Provider Cards Print Style Starts Here
@media print {
  body {
    .fcr-providerprint-card-container,
    .fcr-pharmacy-card-container {
      visibility: hidden;
    }
  }
  .provider-card-print-content-container * {
    visibility: visible;
  }

  .fcr-page-wrapper {
    display: none !important;
  }

  .provider-card-print-content-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: white;

    .affiliation-list {
      padding-top: 15px;
      li {
        padding-top: 15px;
      }
    }
    .pc-card-section {
      h4 {
        font-size: 16px;
        font-weight: 700;
        &.affilation-title {
          padding-bottom: 15px;
        }
      }
      padding-bottom: 15px;
      padding-top: 15px;
      a {
        text-decoration: none;
        font-weight: 700;
        padding-left: 10px;
      }
    }

    .provider-card-section {
      .icon-space {
        padding-left: 10px;
        display: inline-block;
      }
      h4 {
        font-size: 16px;
        font-weight: 700;
      }
      padding-bottom: 15px;
      padding-top: 0;
      a {
        text-decoration: none;
        font-weight: 700;
        padding-left: 10px;
      }
    }

    .print-main-title {
      font-weight: 700;
    }
  }
}

.hide-preview {
  display: none;
}

// Provider Cards Print Style Ends Here

// provider cards css fixes starts here
.license-numbers {
  display: flex;
  .highlight-label {
    width: 115px !important;
  }
  .fcr-license-number-tooltip {
    width: 20px !important;
  }
  .view-all-wrapper {
    flex: 1 !important;
    justify-content: flex-start !important;
  }
}

.fcr-expertise {
  display: flex;
  .highlight-label {
    min-width: 75px;
  }
}

.fcr-nodata-available {
  padding-left: 20px;
}

.fcr-office-feature-name {
  text-transform: capitalize;
}

// temporarily surpressing assign PCP button as this feature is not there for december release
// delete this ID css to make the button enabled again
#fcr-assign-pcp-btn {
  display: none;
}

.view-all-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  padding-top: 0px;
}

// Provider Card Responsiveness Starts Here

@media all and (max-width: $breakpoint-sm) {
  .fcr-results-content {
    z-index: 110 !important; //TODO: review
    .fcr-results-main-content {
      .fcr-results-right-container {
        &.show-map-view {
          .fcr-provider-card {
            position: fixed;
            top: 42px;
            height: calc(100vh - 52px) !important;
            left: -10px;
            max-width: 100%;
            z-index: 2 !important;
          }
        }
        &.show-card-view {
          .fcr-provider-card {
            position: fixed;
            top: 55px;
            left: 0;
            height: calc(100vh - 55px) !important;
            max-width: 100%;
            z-index: 2 !important;
          }
        }
      }
    }
  }

  body:has(.fcr-provider-card) {
    overflow: hidden;
    .fcr-page-wrapper .mbr-top-of-page-wrapper {
      opacity: 0.35;
      pointer-events: none;
    }

    .fcr-page-wrapper .page-header-wrapper {
      display: none;
    }

    .fcr-inner-card .fcr-inner-card-content {
      height: calc(100% - 125px);
    }

    .fcr-page-wrapper .fcr-page-sticky-top-container {
      display: none !important;
    }

    .chatContainer {
      display: none !important;
    }
  }
}

@media only screen and (max-width: $breakpoint-xs) {
  .fcr-search-header-cmp .search-bar-container .fcr-dynamic-care-plan .fcr-mob-care-plan-wrapper .fcr-care-plan-box {
    width: 100%;
  }

  .fcr-results-content .fcr-results-main-content .fcr-results-right-container .fcr-provider-card {
    .fcr-provider-card-content .fcr-provider-card-content-down {
      flex-direction: column-reverse;
      height: auto;
      gap: 14px;
      padding-top: 15px;
      padding-bottom: 15px;
      .fcr-provider-appointment {
        width: 100%;
        padding-bottom: 10px;
        padding-top: 15px;
        border-top: solid 1px #ccc;
        .fcr-provider-btn {
          width: calc(100% - 20px);
          text-align: center;
          height: 42px;
        }
      }

      .fcr-provider-review-wrapper {
        flex: 0 0 100%;
        width: 100%;
      }
    }
    .fcr-morelinks {
      ul {
        li {
          &.fcr-languages-entry {
            display: flex;
            img {
              align-self: flex-start;
              padding-top: 5px;
            }
            .fcr-languages-span {
              display: flex;
            }
          }
        }
      }
    }
  }

  .fcr-filter-chips-container {
    .fcr-selected-filters {
      width: 100%;
    }
  }
}

.fcr-distance-filter-container {
  margin-left: 24px;
  display: flex;
  row-gap: 8px;
  flex-direction: column;

  .fcr-distance-filter {
    display: flex;
    padding: 0px 10px;
    margin-bottom: 12px;
    align-items: center;
  }

  .fcr-distance-filter-label {
    margin: 0px;
    font-weight: 400;
    padding-bottom: 2px;
  }

  input {
    margin: 0px 8px 0px 0px;
    width: 16px;
    height: 16px;
  }
}
// Provider Card Responsiveness Ends Here

// Heart Animation Loader Starts Here
@keyframes slideDownFCRLoader {
  0% {
    top: 0%;
    bottom: 100%;
    opacity: 0;
  }
  5% {
    top: 0%;
    bottom: 0%;
  }
  12% {
    opacity: 1;
  }
  50% {
    top: 0%;
    bottom: 0%;
    opacity: 1;
  }
  55% {
    top: 0%;
    bottom: 100%;
  }
  62% {
    opacity: 0;
  }
  100% {
    top: 0%;
    bottom: 100%;
    opacity: 0%;
  }
}
@keyframes slideUpFCRLoader {
  0% {
    top: 100%;
    bottom: 0%;
    opacity: 0;
  }
  5% {
    top: 0%;
    bottom: 0%;
  }
  12% {
    opacity: 1;
  }
  50% {
    top: 0%;
    bottom: 0%;
  }
  55% {
    top: 100%;
    bottom: 0%;
    opacity: 1;
  }
  62% {
    opacity: 0;
  }
  100% {
    top: 100%;
    bottom: 0%;
  }
}
.fcr-app-load {
  width: 100%;
  height: 100%;
  .ant-loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 100%;
    margin: 0 auto;
    .motif-spinner-heart {
      display: flex;
      padding: 10px;
      height: 120px;
      span {
        position: relative;
        background: #eee;
        width: 8px;
        display: flex;
        margin: 4px;
        border-radius: 4px;
        height: 80px;
        top: 25px;
        &:nth-child(even)::before {
          animation: slideUpFCRLoader 5s ease-out infinite;
        }
        &:nth-child(odd)::before {
          animation: slideDownFCRLoader 5s ease-out infinite;
        }
        &:nth-child(2)::before {
          animation-delay: 0.286s;
        }
        &:nth-child(3)::before {
          animation-delay: 0.572s;
        }
        &:nth-child(4)::before {
          animation-delay: 0.858s;
        }
        &:nth-child(5)::before {
          animation-delay: 1.144s;
        }
        &:nth-child(6)::before {
          animation-delay: 1.43s;
        }
        &:nth-child(7)::before {
          animation-delay: 1.716s;
        }
        &:nth-child(1),
        &:nth-child(7) {
          height: 30px;
          top: 20px;
        }
        &:nth-child(2),
        &:nth-child(6) {
          height: 60px;
          top: 10px;
        }

        &:nth-child(3),
        &:nth-child(5) {
          height: 80px;
          top: 10px;
        }
        &:nth-child(odd)::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          background: linear-gradient(0deg, rgb(42, 181, 202), rgb(0, 121, 194));
          border-radius: 4px;
          top: 0%;
        }
        &:nth-child(even)::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          background: linear-gradient(180deg, rgb(42, 181, 202), rgb(0, 121, 194));
          border-radius: 4px;
          bottom: 0%;
        }
      }
    }
  }
}

// Heart Animation Loader Ends Here

.pcp-search-container {
  padding-left: 9px;

  .pcp-search-link {
    cursor: pointer;
  }

  .pcp-description {
    color: #000;
  }
}

.pcp-search-container-text {
  padding-top: 10px;
}

.bh-non-crisis-padding {
  margin-right: 3px;
}

.bh-non-crisis-image-padding {
  margin-left: 3px;
}

.bh-non-crisis-padding {
  margin-right: 3px;
}
.intent-search-info-margin {
  margin-top: 16px;
  margin-left: 3px;
}
.intent-search-info-margin-bh {
  margin-left: 3px;
  word-break: break-word;
}
.bh-non-crisis-image {
  content: url(/assets/images/common/bh-non-crisis.svg);
}
.arrowExternal-image {
  content: url(/assets/images/common/arrowExternal.svg);
}
.intent-fcr .ant-alert {
  border: none;
  border-left: none;
}
.bh-non-crisis-btn {
  text-decoration: none !important;
}
.fcr-view-all-licenses-link {
  top: 0px !important;
  display: inline-block;
}

.fcr-view-all-licenses {
  display: block;
}

.fcr-license-number-text {
  display: inline-block;
}

.online-pharmacy {
  &.accordian-title {
    font-weight: 600;
    padding-left: 5px;
  }
}
.pharmacy-popicon {
  color: #286ce2;
}

.fcr-provider-network-status {
  span {
    text-transform: capitalize;
  }
}

.fcr-qlink-icon-section:focus,
.pf2-iconLinkRight:focus {
  outline: 1px solid #286ce2;
  outline-offset: 1px;
}
